// packages
import { useQuery } from '@apollo/client'
import { FC, useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
// gql
import { GET_DATA_QUERY } from 'gql'
import { useInterval } from '@mantine/hooks'

export type Stage = {
  type: string
  field: string
}
export type ChartDataLayerProps = {
  componentId: string
  Component: FC<any>
  dataId: string
  intervalPeriod: number
  height: number
  props: any
  transformer?: Stage[]
  params?: object
}

const treatData = (data: any) => {
  if (!data) return null
  return data.getData || null
}

export const ChartDataLayer: FC<ChartDataLayerProps> = ({
  componentId,
  Component,
  dataId,
  intervalPeriod,
  height,
  props,
  transformer,
  params,
}) => {
  const [data, setData] = useState(null)

  const stages = transformer ? transformer.map(({ type, field }) => ({ type, field })) : undefined

  const { loading, refetch } = useQuery(GET_DATA_QUERY, {
    variables: {
      _id: dataId,
      variables: params,
      transformer: stages,
    },
    onCompleted: (result) => {
      setData(result)
      if (isEmpty(params)) interval.start()
    },
  })

  useEffect(() => {
    return interval.stop
  }, [])

  const interval = useInterval(() => {
    refetch()
  }, intervalPeriod)

  return (
    <Component
      componentId={componentId}
      data={treatData(data)}
      loading={loading}
      props={props}
      height={height}
    />
  )
}
