// packages
import { gql } from '@apollo/client'

export const GET_BUTTON_MODAL_QUERY = gql`
  query GetButtonModal($_id: ObjectId!) {
    getButtonModal(_id: $_id) {
      _id
      label
      props
      modal {
        _id
      }
    }
  }
`
