// packages
import { FC } from 'react'
import Link from 'next/link'
// mantine
import { Anchor, Box, Breadcrumbs, Stack, Text } from '@mantine/core'
// Components
import { ElephantFeedPodsDeviceStatusCard } from '../Dashboard/DeviceStatusCard'
import { ElephantFeedPodsRecentFeedsTable } from './Table'
import { ElephantFeedPodsBreadcrumbDeviceName } from '../BreadcrumbDeviceName'

export type ElephantFeedPodsRecentFeedsContainerProps = {
  id: string
}

export const ElephantFeedPodsRecentFeedsContainer: FC<ElephantFeedPodsRecentFeedsContainerProps> =
  ({ id }) => {
    return (
      <Stack>
        <Box>
          <Breadcrumbs>
            <Anchor
              size="xs"
              color="dimmed"
              weight={600}
              transform="uppercase"
              underline={false}
              component={Link}
              href="/dashboard/elephant-feedpods"
            >
              Elephant Feedpods
            </Anchor>

            <ElephantFeedPodsBreadcrumbDeviceName id={id} type="Schedule" />
          </Breadcrumbs>
          <Text size="lg" weight={500} color="default">
            Recent Feeds
          </Text>
        </Box>
        <ElephantFeedPodsDeviceStatusCard id={id} />
        <ElephantFeedPodsRecentFeedsTable id={id} />
      </Stack>
    )
  }
