// packages
import { FC } from 'react'
// Containers
import { ForgetPasswordForm } from 'Containers'

type ForgetPasswordPageContainerProps = {}

export const ForgetPasswordPageContainer: FC<ForgetPasswordPageContainerProps> = () => {
  return (
    <div>
      <ForgetPasswordForm />
    </div>
  )
}
