// packages
import dayjs from 'dayjs'
import { FC, useState } from 'react'
// mantine
import { Flex, Popover, Text } from '@mantine/core'
import { DatePicker, DatePickerProps } from '@mantine/dates'
// icons
import { ChevronDown, ChevronUp } from 'tabler-icons-react'

export type DateRangePickerProps = {
  min: Date
  max: Date
  start: Date
  end: Date
  routerChange: any
} & DatePickerProps

export const DateRangePicker: FC<DateRangePickerProps> = ({
  min,
  max,
  start,
  end,
  routerChange = () => {},
  ...props
}) => {
  const [opened, setOpened] = useState(false)

  const setDates = async (dates: [Date, Date]) => {
    if (!dates[0] || !dates[1]) return

    // hard coded date timezone conversion
    const s = dayjs(dates[0]).add(8, 'h').toDate()
    const e = dayjs(dates[1]).add(8, 'h').toDate()
    await routerChange(s, e)

    setOpened(false)
  }

  return (
    <Popover
      withArrow
      withinPortal
      position="bottom"
      shadow="sm"
      opened={opened}
      onChange={setOpened}
    >
      <Popover.Target>
        <Flex
          align="center"
          gap={3}
          onClick={() => setOpened((o) => !o)}
          style={{ cursor: 'pointer' }}
        >
          <Text>
            {dayjs(start).add(-8, 'h').format('D MMM YYYY')} -{' '}
            {dayjs(end).add(-8, 'h').format('D MMM YYYY')}
          </Text>
          {opened ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
        </Flex>
      </Popover.Target>
      <Popover.Dropdown>
        <DatePicker
          minDate={min}
          maxDate={max}
          maxLevel="month"
          type="range"
          allowSingleDateInRange
          hideOutsideDates
          defaultDate={min}
          defaultValue={[
            dayjs(start).add(-8, 'h').startOf('d').toDate(),
            dayjs(end).add(-8, 'h').startOf('d').toDate(),
          ]}
          onChange={setDates}
          {...props}
        />
      </Popover.Dropdown>
    </Popover>
  )
}
