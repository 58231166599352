// packages
import { ReactElement } from 'react'
import { addApolloState, initializeApollo } from 'lib/apolloClient'
// mantine
import { Divider } from '@mantine/core'
// _app
import { NextPageWithLayout } from './_app'
// HOC
import { withAuthRequired } from 'HOC'
// Containers
import { AppLayout, CSBCard, CSBUpdates } from 'Containers'

/**
 * next page component for the default home/landing page
 *
 * @returns Home Page
 */
const HomePage: NextPageWithLayout = () => {
  return (
    <>
      <CSBCard />
      <Divider my="md" />
      <CSBUpdates />
    </>
  )
}

export const getStaticProps = async () => {
  const apolloClient = initializeApollo()

  return addApolloState(apolloClient, {
    props: {},
    revalidate: 1,
  })
}

HomePage.getLayout = function getLayout(page: ReactElement) {
  return <AppLayout>{page}</AppLayout>
}

export default withAuthRequired({ Component: HomePage })
