// packages
import { FC, ReactNode } from 'react'
// mantine
import { useMantineColorScheme, useMantineTheme, Text } from '@mantine/core'

export type ActivationsRecentBodyTextProps = {
  username: string
  requestBody: any
}

export const ActivationsRecentBodyText: FC<ActivationsRecentBodyTextProps> = ({
  username,
  requestBody,
}) => {
  const { params } = requestBody
  const { iottype, device_id } = params
  const theme = useMantineTheme()
  const { colorScheme } = useMantineColorScheme()

  const B = ({ children }: { children: ReactNode }) => (
    <Text
      component="span"
      weight={500}
      color={colorScheme === 'light' ? theme.colors.gray[9] : theme.colors.gray[2]}
    >
      {children}
    </Text>
  )

  switch (iottype) {
    case 'feed': {
      return (
        <>
          <B>{username}</B> activated the <B>feeder</B> for CSB <B>device {device_id}</B>.
        </>
      )
    }
    case 'light': {
      const { color, blink, duration } = params
      return (
        <>
          <B>{username}</B> turned on the <B>{color} light</B> on CSB <B>device {device_id}</B> for{' '}
          <B>{duration}s.</B> {blink ? 'Light is set to blink.' : ''}
        </>
      )
    }
    case 'sound': {
      const { trackname, volume, loop } = params
      return (
        <>
          <B>{username}</B> played the <B>{trackname}</B> at <B>volume {volume}</B> on CSB{' '}
          <B>device {device_id}</B>. Looped <B>{loop}</B> time(s).
        </>
      )
    }
    case 'scent': {
      const { duration } = params
      return (
        <>
          <B>{username}</B> activated the <B>scent module</B> on CSB <B>device {device_id}</B> for{' '}
          <B>{duration}s</B>.
        </>
      )
    }
    case 'emlock': {
      const { state } = params
      return (
        <>
          <B>{username}</B> {state == 0 ? 'unlocked' : 'locked'} the EM door for CSB{' '}
          <B>device {device_id}</B>.
        </>
      )
    }
    case 'resetfeed': {
      return (
        <>
          <B>{username}</B> did a feed reset for CSB <B>device {device_id}</B>.
        </>
      )
    }
    default: {
      return <></>
      {
        /* return <>{JSON.stringify(requestBody)}</> */
      }
    }
  }
}
