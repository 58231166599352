// packages
import { FC, useState } from 'react'
// mantine
import { Box, Button, Divider, Flex, List, Modal } from '@mantine/core'
// Components
import { ActivateActionForm } from './ActivateActionForm'

export type ActionsListItemProps = {
  _id: string
  name: string
  steps: any[]
}

export const ActionsListItem: FC<ActionsListItemProps> = ({ _id, name, steps }) => {
  return (
    <Box>
      <Box p="sm">
        <Flex justify="space-between" align="center">
          <b>Remote Feed</b>
          <List type="ordered" size="sm">
            {steps.map(({ _id, name }) => (
              <List.Item key={_id}>{name}</List.Item>
            ))}
          </List>
          <div>
            <ActionsListItemButton _id={_id} />
          </div>
        </Flex>
      </Box>
      <Divider />
    </Box>
  )
}

export const ActionsListItemButton = ({ _id }: { _id: string }) => {
  const [opened, setOpened] = useState(false)

  return (
    <>
      <Modal opened={opened} onClose={() => setOpened(false)} title="&nbsp;" centered>
        <ActivateActionForm _id={_id} setOpened={setOpened} />
      </Modal>
      <Box>
        <Button onClick={() => setOpened(true)}>Schedule</Button>
      </Box>
    </>
  )
}
