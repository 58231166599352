// packages
import { FC } from 'react'
//mantine
import { Loader, ThemeIcon } from '@mantine/core'
// Components
import { Icon, IconProps } from 'Components'

export type ActivationsBulletProps = {
  status: string
  iconName: IconProps['name']
  color: string
}

export const ActivationsBullet: FC<ActivationsBulletProps> = ({ status, iconName, color }) => {
  return (
    <ThemeIcon
      variant="light"
      radius="xl"
      size="xl"
      color={status === 'InProgress' ? 'gray' : color}
    >
      {status === 'InProgress' ? (
        <Loader size="sm" color="gray" />
      ) : (
        <Icon name={iconName} size={20} />
      )}
    </ThemeIcon>
  )
}
