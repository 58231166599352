// packages
import { Dispatch, FC, SetStateAction, useState } from 'react'
import dayjs from 'dayjs'
// mantine
import { Box, Divider, Flex, Popover, Text } from '@mantine/core'
import { DatePicker } from '@mantine/dates'
// hooks
import { useIsMobile } from 'hooks'
// icons
import { ChevronDown } from 'tabler-icons-react'

type ScheduleDatePickerProps = {
  date: Date
  setDate: Dispatch<SetStateAction<Date>>
}

export const ScheduleDatePicker: FC<ScheduleDatePickerProps> = ({ date, setDate }) => {
  const [opened, setOpened] = useState(false)
  const isMobile = useIsMobile()

  return !isMobile ? (
    <>
      <Text weight={500} size="md" mb="sm">
        {dayjs(date).format('DD/MM/YYYY')}
      </Text>
      <Divider mb="sm" />
    </>
  ) : (
    <Popover opened={opened} onChange={setOpened} withinPortal>
      <Popover.Target>
        <Flex onClick={() => setOpened((o) => !o)} style={{ cursor: 'pointer' }} align="center">
          <Box>
            <Text weight={500} size="md" mb="sm">
              {dayjs(date).format('DD/MM/YYYY')}
            </Text>
          </Box>
          <Box>
            <ChevronDown />
          </Box>
        </Flex>
      </Popover.Target>
      <Popover.Dropdown>
        <DatePicker
          value={date}
          onChange={(d) => {
            //@ts-expect-error: null for date
            setDate(d)
            setOpened(false)
          }}
          excludeDate={(d) => dayjs().add(-1, 'd').isAfter(d)}
        />
      </Popover.Dropdown>
    </Popover>
  )
}
