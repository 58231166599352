// packages
import { useQuery } from '@apollo/client'
import { FC } from 'react'
// mantine
import { Button, Center, Loader } from '@mantine/core'
// gql
import { GET_BUTTON_MODAL_QUERY } from 'gql'
// Content
import { ButtonModal } from 'Components'
import { PropsOf } from '@emotion/react'

export type ButtonModalContainerProps = {
  _id: string
  props?: PropsOf<typeof Button>
  height?: number
}

export const ButtonModalContainer: FC<ButtonModalContainerProps> = ({ _id, props, height }) => {
  const { data, error } = useQuery(GET_BUTTON_MODAL_QUERY, {
    variables: { _id },
  })

  if (data)
    if (data.getButtonModal) {
      const { label, modal, props: componentProps } = data.getButtonModal
      const { _id: modalId } = modal

      return <ButtonModal modalId={modalId} label={label} props={{ ...componentProps, ...props }} />
    }

  return <Button loading loaderPosition="center" style={{ width: 80 }} />
}
