// packages
import { FC } from 'react'
// mantine
import { Card, Flex, Stack, Text } from '@mantine/core'

type CSBFeedsAnalysisProps = {
  feeds: any[]
  numOfDays: number
}

export const CSBFeedsAnalysis: FC<CSBFeedsAnalysisProps> = ({ feeds, numOfDays }) => {
  return (
    <Card radius="md" withBorder>
      <Text weight={500} size="lg">
        Feeds Analysis
      </Text>
      <Flex gap="md">
        <Stack spacing={2}>
          <Text size="lg" weight={400}>
            Total
          </Text>
          <Text weight={500}>{feeds.length} feed(s)</Text>
        </Stack>
        <Stack spacing={2}>
          <Text size="lg" weight={400}>
            Average
          </Text>
          <Text weight={500}>{(feeds.length / numOfDays).toFixed(2)} feed(s)/day</Text>
        </Stack>
      </Flex>
    </Card>
  )
}
