// packages
import { FC } from 'react'
import { useQuery } from '@apollo/client'
// mantine
import { Anchor, Avatar, Group, Skeleton, Stack, Text } from '@mantine/core'
// gql
import { GET_TARGET_USERS_OF_PULSE_QUERY } from 'gql'
import { RemoveUserFromPulseTargetsAnchor } from './Remove'

export type PulseTargetUsersListProps = {
  _id: string
}

export const PulseTargetUsersList: FC<PulseTargetUsersListProps> = ({ _id }) => {
  const { data, loading, error } = useQuery(GET_TARGET_USERS_OF_PULSE_QUERY, { variables: { _id } })

  if (data)
    if (data.getTargetUsersOfPulse) {
      return data.getTargetUsersOfPulse.map((user: any) => {
        return (
          <Group key={user._id} mt={3}>
            <Avatar radius="xl">{user.name.charAt(0)}</Avatar>

            <div style={{ flex: 1 }}>
              <Text size="sm" weight={500}>
                {user.name}
              </Text>

              <Text color="dimmed" size="xs">
                Member
              </Text>
            </div>

            <RemoveUserFromPulseTargetsAnchor _id={_id} userId={user._id} />
          </Group>
        )
      })
    }

  return (
    <Stack spacing="sm">
      {Array(3)
        .fill(0)
        .map((_, i) => (
          <Group key={i}>
            <Skeleton h={38} w={38} radius="xl" />

            <div style={{ flex: 1 }}>
              <Skeleton h="0.5rem" w={90} mb="xs" />

              <Skeleton h="0.5rem" w={160} />
            </div>

            <Skeleton h="0.5rem" w={80} />
          </Group>
        ))}
    </Stack>
  )
}
