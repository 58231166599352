// mantine
import { MantineSize } from '@mantine/core'
// hooks
import { useBreakpoint } from 'hooks/breakpoints'
// utils
import { filterArrayValuesByLength } from '@upcode-munin/utils'

/**
 * possible values of a tick label
 *
 * @group Hooks
 * @category Hooks
 */
export type TickValue = string | number | Date

/**
 * options for {@link useResponsiveXAxisTickValues}
 *
 * @group Hooks
 * @category Hooks
 */
export type UseResponsiveXAxisTickValuesProps = {
  /** an array of original tick values to be used for filtering */
  tickValues: TickValue[]

  /**
   * an object with breakpoints and the corresponding largest limit of number of tickValues
   * @defaultValue
    ```
    {
      xs: 5,
      sm: 7,
      md: 8,
      lg: 10,
      xl: 12,
    }
    ```
   */
  maxBreakpointCount?: Record<MantineSize, number>
}

/**
 * Filters an array of tickValues for the x axis in chart, using the original array of tickValues. This method uses the width of the viewport to decide the maximum length of array.
 *
 * @param props - options for creating x axis tick values
 *
 * @returns an array of values to be used as the new tickValues for the x axis
 *
 * @group Hooks
 * @category Hooks
 */
export const useResponsiveXAxisTickValues = ({
  tickValues,
  maxBreakpointCount = {
    xs: 5,
    sm: 7,
    md: 8,
    lg: 10,
    xl: 12,
  },
}: UseResponsiveXAxisTickValuesProps): TickValue[] =>
  filterArrayValuesByLength(tickValues, maxBreakpointCount[useBreakpoint()])
