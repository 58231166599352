// packages
import { useEffect } from 'react'
import { useQuery } from '@apollo/client'
// mantine
import { Badge, Box, Group, Skeleton, Text } from '@mantine/core'
import { useInterval } from '@mantine/hooks'
// gql
import { GET_CSB_REMAINING_FEEDS_QUERY } from 'gql'

export const FeedsRemaining = () => {
  return (
    <Box>
      <Text size="sm" weight={500} tt="uppercase" color="default">
        Feeds Remaining
      </Text>
      <FeedsDisplay />
      <Text size="xs" color="dimmed">
        The CSB stores 2 feed trays.
      </Text>
    </Box>
  )
}

const FeedsDisplay = () => {
  const { data, refetch } = useQuery(GET_CSB_REMAINING_FEEDS_QUERY)

  const interval = useInterval(() => refetch(), 1000 * 60)
  useEffect(() => {
    interval.start()
    return interval.stop
  }, [interval])

  if (data) {
    const { getCSBRemainingFeeds: remaining } = data

    if (remaining !== undefined) {
      return (
        <Text size={32} weight={400} style={{ fontFamily: `'Rubik', sans-serif` }}>
          {remaining}/2&nbsp;
          <RemainingBadge remaining={remaining} />
        </Text>
      )
    }
  }

  return (
    <Group align="flex-end" spacing="sm" mt={5} mb={10}>
      <Skeleton h={34.59} w={60} />
      <Skeleton h={14} w={45} radius="md" />
    </Group>
  )
}

const RemainingBadge = ({ remaining }: { remaining: number }) => {
  if (remaining >= 2) return <Badge color="green">Full</Badge>
  if (remaining == 1) return <Badge color="yellow">1 feed remaining</Badge>

  return <Badge color="red">Please reset feed</Badge>
}
