// packages
import { FC, ReactNode } from 'react'
// Containers
import { AppLayoutHeader } from 'Containers'

export type LandingLayoutProps = { children: ReactNode }

export const LandingLayout: FC<LandingLayoutProps> = ({ children }) => {
  const headerHeight = 60
  const leftSideBarWidth = 240

  return (
    <div>
      <AppLayoutHeader height={headerHeight} leftSideBarWidth={leftSideBarWidth} />
      {children}
    </div>
  )
}
