// packages
import { FC } from 'react'
// mantine
import { Text, useMantineColorScheme, useMantineTheme } from '@mantine/core'
// Components
import { RelativeDuration } from 'Components'
import { ActivationsRecentBodyText } from './Text'

type ActivationsRecentBodyProps = {
  requestBody: any
  username: string
  scheduledAt: string
}

export const ActivationsRecentBody: FC<ActivationsRecentBodyProps> = ({
  requestBody,
  username,
  scheduledAt,
}) => {
  const theme = useMantineTheme()
  const { colorScheme } = useMantineColorScheme()

  return (
    <>
      <Text
        size={13}
        weight={400}
        color={colorScheme === 'light' ? theme.colors.gray[8] : theme.colors.gray[3]}
      >
        <ActivationsRecentBodyText requestBody={requestBody} username={username} />
      </Text>
      <Text size="xs" color="dimmed" mt={4}>
        <RelativeDuration timestamp={scheduledAt} />
      </Text>
    </>
  )
}
