// packages
import { FC } from 'react'
// mantine
import { Box } from '@mantine/core'
// styles
import useStyles from './useStyles'
// Components
import { PositionalGridItem, PositionalGridItemProps } from './Item'

/**
 * Props for PositionalGrid
 */
export type PositionalGridProps = {
  /** an array of grid items */
  grids: PositionalGridItemProps[]

  /**
   * Number of columns to set up in our grid structure.
   *
   * @defaultValue - 12
   */
  columns?: number
}

/**
 * Grid structure which uses the css display grid.
 *
 * @see [CSS Grid Intro](https://www.w3schools.com/css/css_grid.asp)
 *
 * @param props - options for PositionalGrid
 *
 * @returns Component - an array of grid items, wrapped within a div with display: grid
 */
export const PositionalGrid: FC<PositionalGridProps> = ({ grids, columns = 12 }) => {
  const { classes } = useStyles({ columns })

  return (
    <Box className={classes.grid}>
      {grids.map((grid, i) => (
        <PositionalGridItem key={i} {...grid} />
      ))}
    </Box>
  )
}
