// packages
import { FC, useState } from 'react'
import dayjs from 'dayjs'
// mantine
import { Box } from '@mantine/core'
// COmponents
import { CSBTigerRecognitionFootagesVideoPlayer } from '../../DayAnalysis/VideoPlayer'

export type CSBTigerRecognitionFootagesProps = {
  videos: any[]
}

export const CSBTigerRecognitionFootages: FC<CSBTigerRecognitionFootagesProps> = ({ videos }) => {
  const [id, setId] = useState('')

  return (
    <Box>
      Footages
      <Box>with Tigers</Box>
      {videos.length}
      {videos.map((video) => (
        <Box
          onClick={() => {
            setId(video._id)
          }}
          key={video._id}
        >
          {dayjs(video.recordedAt).format('h:mm a')}
        </Box>
      ))}
      {id != '' && <CSBTigerRecognitionFootagesVideoPlayer _id={id} />}
    </Box>
  )
}
