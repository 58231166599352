// packages
import { FC, useState } from 'react'
import { useRouter } from 'next/router'
import { useMutation } from '@apollo/client'
// lib
import { initializeApollo } from 'lib'
// mantine
import { Button, Card, Container, LoadingOverlay, PasswordInput, Title } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
// gql
import { UPDATE_PASSWORD_BY_RESET_TOKEN_MUTATION } from 'gql'
// localStorage
import { setAuthToken } from 'localStorage'
// hook
import { useResetPasswordForm } from './useResetPasswordForm'
// icons
import { Check } from 'tabler-icons-react'

type ResetPasswordFormProps = {
  token: string
}

export const ResetPasswordForm: FC<ResetPasswordFormProps> = ({ token }) => {
  const router = useRouter()
  const client = initializeApollo()
  const form = useResetPasswordForm(token)

  const [success, setSuccess] = useState(false)

  const [action, { loading }] = useMutation(UPDATE_PASSWORD_BY_RESET_TOKEN_MUTATION)

  const handleSubmit = async () => {
    const { token, password } = form.values
    const params = { token, password }

    const { data, errors } = await action({
      variables: { params },
    })

    // TODO: handle the modal notification for errors
    if (errors) {
      alert(JSON.stringify(errors, null, 2))
    } else {
      setSuccess(true)
      setAuthToken(data.updatePasswordByResetToken.token)
      showNotification({
        color: 'teal',
        icon: <Check size={18} />,
        autoClose: 4000,
        title: 'Success!',
        message: 'Password has been changed.',
      })

      await client.resetStore()
      await router.push('/')
    }
  }

  return (
    <Container size={420} my={40}>
      <Title align="center" sx={(_) => ({ fontWeight: 600 })}>
        Enter New Password
      </Title>

      <Card withBorder shadow="md" p={30} pt={10} mt={30} radius="md">
        <LoadingOverlay visible={loading || success} />
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <PasswordInput
            label="Password"
            placeholder="Your password"
            required
            mt="md"
            {...form.getInputProps('password')}
          />
          <PasswordInput
            label="Confirm Password"
            placeholder="Enter your password again"
            required
            mt="md"
            {...form.getInputProps('passwordConfirmation')}
          />

          <Button fullWidth mt="md" type="submit">
            Change Password
          </Button>
        </form>
      </Card>
    </Container>
  )
}
