// packages
import { gql } from '@apollo/client'

export const GET_TEXT_QUERY = gql`
  query getText($_id: ObjectId!) {
    getText(_id: $_id) {
      _id
      content
      props
    }
  }
`
