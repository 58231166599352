// packages
import dayjs from 'dayjs'
// mantine
import { Box, ScrollArea, SimpleGrid, Text } from '@mantine/core'

export const SelectVideoFilename = ({
  files,
  setId,
  height,
  props,
}: {
  files: any
  setId: any
  height: number
  props: any
}) => {
  const { valueField } = props

  return (
    <Box p="sm">
      <Box p={0}>
        <Text size="sm" weight="bold">
          Videos {files && files.length > 0 && <>- {files.length}</>}
        </Text>
      </Box>
      <ScrollArea sx={{ height: height - 22 }} scrollbarSize={5}>
        <SimpleGrid spacing="xs" pt={5} cols={2}>
          {!files || files.length == 0 ? (
            <Text size="sm">Select a date with videos.</Text>
          ) : (
            <>
              {files.map((d: any, i: number) => (
                <Text
                  key={i}
                  variant="link"
                  size="xs"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setId(d[valueField])}
                >
                  {dayjs(d.timestamp).add(-8, 'h').format('YYYY-MM-DD HH:mm')}
                </Text>
              ))}
            </>
          )}
        </SimpleGrid>
      </ScrollArea>
    </Box>
  )
}
