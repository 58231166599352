// packages
import { ActionIcon, Anchor, Avatar, Badge, Card, Group, Progress, Text } from '@mantine/core'
import NextLink from 'next/link'
// styles
import useStyles from './styles'
// icons
import { Link } from 'tabler-icons-react'

export const CSBCard = () => {
  const { classes } = useStyles()

  return (
    <Card withBorder radius="md">
      <Group position="apart">
        <Badge>CSB</Badge>
      </Group>

      <Anchor
        underline={false}
        href="/dashboard/carnivore-smart-box"
        component={NextLink}
        size="lg"
        weight={500}
        mt="md"
        color="default"
        className={classes.title}
      >
        Carnivore Smart Box
      </Anchor>
      <Text size="sm" color="dimmed" mt={5}>
        Smart feeder system for the tigers in Night Safari.
      </Text>

      <Text color="dimmed" size="sm" mt="md">
        Tasks completed:{' '}
        <Text
          span
          weight={500}
          sx={(theme) => ({ color: theme.colorScheme === 'dark' ? theme.white : theme.black })}
        >
          23/36
        </Text>
      </Text>

      <Progress value={(23 / 36) * 100} mt={5} />

      <Group position="apart" mt="md">
        <Avatar.Group spacing="sm">
          <Avatar radius="xl">ZP</Avatar>
          <Avatar radius="xl">ZP</Avatar>
          <Avatar radius="xl">ZP</Avatar>
          <Avatar radius="xl">+5</Avatar>
        </Avatar.Group>
        <ActionIcon variant="default" href="/dashboard/carnivore-smart-box" component={NextLink}>
          <Link size={18} />
        </ActionIcon>
      </Group>
    </Card>
  )
}
