// packages
import { FC } from 'react'
// mantine
import { Popover, Text, ThemeIcon } from '@mantine/core'
// icons
import { ActivityHeartbeat } from 'tabler-icons-react'
import { Icon, IconProps } from 'Components'
import { useDisclosure } from '@mantine/hooks'

export type PulsesListTableRowStatusProps = {
  status: string
}

const StatusIcon = ({
  color,
  icon,
  status,
}: {
  color: string
  icon: IconProps['name']
  status: string
}) => {
  const [opened, { close, open }] = useDisclosure(false)

  return (
    <Popover shadow="md" opened={opened} withArrow>
      <Popover.Target>
        <ThemeIcon
          variant="light"
          radius="xl"
          color={color}
          style={{ cursor: 'pointer' }}
          onMouseEnter={open}
          onMouseLeave={close}
        >
          <Icon name={icon} strokeWidth={2} />
        </ThemeIcon>
      </Popover.Target>
      <Popover.Dropdown sx={{ pointerEvents: 'none' }}>
        <Text size="sm">{status}</Text>
      </Popover.Dropdown>
    </Popover>
  )
}

export const PulsesListTableRowStatus: FC<PulsesListTableRowStatusProps> = ({ status }) => {
  switch (status) {
    case 'Active': {
      return <StatusIcon color="green" icon="ActivityHeartbeat" status={status} />
    }

    case 'Paused': {
      return <StatusIcon color="yellow" icon="PlayerPause" status={status} />
    }

    case 'Snoozed': {
      return <StatusIcon color="pink" icon="Zzz" status={status} />
    }

    default: {
      return <>unknown</>
    }
  }
}
