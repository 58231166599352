// packages
import { FC } from 'react'
import dayjs from 'dayjs'
// mantine
import { Box, Flex, Text, useMantineColorScheme, useMantineTheme } from '@mantine/core'
// Components
import { ActivationsUpcomingBodyText } from './Text'
import { CancelScheduledActivationActionIcon } from './CancelScheduledActivationActionIcon'

type ActivationsUpcomingBodyProps = {
  _id: string
  requestBody: any
  username: string
  scheduledAt: string
}

export const ActivationsUpcomingBody: FC<ActivationsUpcomingBodyProps> = ({
  _id,
  requestBody,
  username,
  scheduledAt,
}) => {
  const theme = useMantineTheme()
  const { colorScheme } = useMantineColorScheme()

  return (
    <Flex align="center" gap="lg">
      <Box>
        <Text
          size="sm"
          weight={400}
          color={colorScheme === 'light' ? theme.colors.gray[8] : theme.colors.gray[3]}
          mt={4}
        >
          {dayjs(scheduledAt).format('hh:mm:ss A')}
        </Text>
        <Text size={13}>
          <ActivationsUpcomingBodyText requestBody={requestBody} username={username} />
        </Text>
      </Box>

      <Box>
        <CancelScheduledActivationActionIcon _id={_id} scheduledAt={scheduledAt} />
      </Box>
    </Flex>
  )
}
