// packages
import { FC } from 'react'
// mantine
import { NumberInput, NumberInputProps } from '@mantine/core'
// props
import { BaseInputProps } from './baseInputProps'

/**
 * Props for NumberInputWrapper Component
 */
export type NumberInputWrapperProps = {
  props: NumberInputProps
} & BaseInputProps

/**
 * Wrapper for Mantine NumberInput Component.
 *
 * @see [NumberInput](https://mantine.dev/core/number-input/)
 */
export const NumberInputWrapper: FC<NumberInputWrapperProps> = ({ form, fieldName, props }) => {
  return <NumberInput {...props} {...form.getInputProps(fieldName)} />
}
