import useStyles from './styles'

/**
 * options for {@link SinkLayout}
 *
 * @category Layout Components
 */
export type SinkLayoutProps = {
  /** content to be placed inside the layout */
  children: React.ReactNode
}

/**
 * Default layout for a kitchen sink to see all components
 *
 * @param props - React Node content
 *
 * @returns a layout wrapper component
 *
 * @category Layout Components
 */
export const SinkLayout: React.FC<SinkLayoutProps> = ({ children }) => {
  const { classes } = useStyles()

  return <div className={classes.wrapper}>{children}</div>
}
