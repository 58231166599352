// Components
import { FeedsRemaining } from './FeedsRemaining'
import { Temperature } from './Temperature'

export const RemainingFeedAndTemperatureData = () => {
  return (
    <>
      <FeedsRemaining />
      <Temperature />
    </>
  )
}
