// packages
import { Dispatch, FC, SetStateAction } from 'react'
import { AreaSeries, Axis, Grid, Tooltip, XYChart } from '@visx/xychart'
import dayjs from 'dayjs'
// mantine
import { Box, Text, useMantineTheme } from '@mantine/core'
// defaultProps

type SparkChartProps = {
  height: number
  data: any
  current: any
  color: string
  props: any
  setIsCurrent: Dispatch<SetStateAction<boolean>>
  setValue: Dispatch<SetStateAction<string>>
  setDateStr: Dispatch<SetStateAction<string>>
}

export const SparkChart: FC<SparkChartProps> = ({
  height,
  data,
  current,
  color,
  props,
  setIsCurrent,
  setValue,
  setDateStr,
}) => {
  const theme = useMantineTheme()

  if (!current) return <></>

  const { verticalTicks = 3 } = props

  const max = Math.max(...data.map((d: any) => d.y))
  const min = Math.min(...data.map((d: any) => d.y))

  const accessors = {
    xAccessor: (d: any) => d.x.toISOString(),
    yAccessor: (d: any) => d.y,
  }

  return (
    <XYChart
      height={height}
      xScale={{ type: 'band' }}
      yScale={{
        type: 'linear',
        zero: false,
        domain: [max - 2 * (max - min), max],
        range: [height, 30],
      }}
      margin={{ top: 30, left: 0, bottom: 0, right: 0 }}
      onPointerMove={(data) => {
        setIsCurrent(false)
        // @ts-expect-error: typing for datum
        setValue(data.datum['y'])
        // @ts-expect-error: typing for datum
        setDateStr(data.datum['x'].toISOString())
      }}
      onPointerOut={() => setIsCurrent(true)}
    >
      <Axis
        orientation={'left'}
        numTicks={4}
        stroke={theme.colorScheme == 'light' ? theme.colors.gray[2] : theme.colors.dark[4]}
        hideAxisLine
        left={0}
        tickLineProps={{ dx: 120, strokeWidth: 0.75 }}
        tickFormat={(formattedValue) => {
          return `${formattedValue}${props.units}`
        }}
        tickLabelProps={() => ({
          dx: 13,
          stroke: theme.colorScheme === 'light' ? theme.colors.dark[3] : theme.colors.gray[6],
          textAnchor: 'start',
        })}
        hideZero
      />
      <Axis
        orientation="bottom"
        numTicks={verticalTicks}
        stroke={theme.colorScheme == 'light' ? theme.colors.gray[2] : theme.colors.dark[4]}
        hideAxisLine
        top={height - 23}
        tickLineProps={{ strokeWidth: 0 }}
        tickFormat={(formattedValue) => {
          return dayjs(formattedValue).add(-8, 'h').format('D MMM, h:mm:ssa')
        }}
        tickLabelProps={() => ({
          dx: 40,
          stroke: theme.colorScheme === 'light' ? theme.colors.dark[3] : theme.colors.gray[6],
          strokeWidth: 0.75,
        })}
      />
      <Grid
        columns
        rows={false}
        numTicks={verticalTicks}
        lineStyle={{
          strokeWidth: 0.75,
          stroke: theme.colorScheme == 'light' ? theme.colors.gray[2] : theme.colors.dark[4],
        }}
      />
      <Grid
        columns={false}
        rows
        numTicks={4}
        lineStyle={{
          strokeWidth: 0.75,
          stroke: theme.colorScheme == 'light' ? theme.colors.gray[2] : theme.colors.dark[4],
        }}
      />
      <AreaSeries
        dataKey="Temperature"
        data={data}
        fillOpacity={0.1}
        fill={color}
        lineProps={{
          stroke: color,
        }}
        {...accessors}
      />
      <Tooltip
        snapTooltipToDatumX
        snapTooltipToDatumY
        showVerticalCrosshair
        showHorizontalCrosshair
        showSeriesGlyphs
        renderTooltip={({ tooltipData, colorScale }) => (
          <Box>
            <Text mb="sm" weight={400}>
              {tooltipData!.nearestDatum!.key}
            </Text>
            <Text>
              {dayjs(accessors.xAccessor(tooltipData!.nearestDatum!.datum))
                .add(-8, 'h')
                .format('D MMM, h:mm:ssa')}
              {' - '}
              {accessors.yAccessor(tooltipData!.nearestDatum!.datum)}
              {props.units}
            </Text>
          </Box>
        )}
        horizontalCrosshairStyle={{
          strokeDasharray: '4,4', // Set the horizontal line to dashed
          stroke: theme.colorScheme === 'light' ? theme.colors.dark[2] : theme.colors.gray[4],
        }}
        verticalCrosshairStyle={{
          strokeDasharray: '4,4', // Set the vertical line to dashed
          stroke: theme.colorScheme === 'light' ? theme.colors.dark[2] : theme.colors.gray[4],
        }}
      />
    </XYChart>
  )
}
