// packages
import { FC } from 'react'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
// mantine
import { Box, Flex, Paper, Text } from '@mantine/core'
// tyes
import { CalendarProps } from '../types'

export type CalendarMonthDayGridProps = {
  inMonth: boolean
  gotoDate: () => void
} & Pick<CalendarProps, 'date' | 'events'>

export const CalendarMonthDayGrid: FC<CalendarMonthDayGridProps> = ({
  date,
  inMonth,
  events,
  gotoDate,
}) => {
  dayjs.extend(isToday)

  return (
    <Paper
      withBorder
      h={110}
      radius={0}
      sx={(theme) => ({
        cursor: 'pointer',
        backgroundColor: inMonth
          ? 'default'
          : theme.colorScheme === 'light'
          ? theme.colors.gray[1]
          : theme.colors.dark[6],
      })}
      onClick={gotoDate}
    >
      <Flex w="1.75rem" h="1.75rem" align="center" justify="center" m={1}>
        <Paper
          pt={3}
          radius="xs"
          w="1.75rem"
          h="1.75rem"
          sx={(theme) => {
            return {
              backgroundColor: date.isToday()
                ? theme.colorScheme === 'light'
                  ? theme.colors.blue[5]
                  : theme.colors.blue[9]
                : 'transparent',
            }
          }}
        >
          <Text
            size="sm"
            align="center"
            color={date.isToday() ? 'white' : inMonth ? 'default' : 'dimmed'}
          >
            {date.format('D')}
          </Text>
        </Paper>
      </Flex>
      <Box px="xs">
        {events.slice(0, 3).map((event, i) => (
          <Flex align="center" gap="xs" style={{ cursor: 'pointer' }} key={i}>
            <Paper
              radius="xl"
              h={5}
              w={5}
              sx={(theme) => ({ backgroundColor: theme.colors.blue[4] })}
            />
            <Text size="xs" truncate>
              {event.title}
            </Text>
          </Flex>
        ))}
        {events.slice(3).length != 0 && (
          <Text size="xs" truncate style={{ cursor: 'pointer' }}>
            {events.slice(3).length} more ...
          </Text>
        )}
      </Box>
    </Paper>
  )
}
