import { gql } from '@apollo/client'

export const LOGIN_MUTATION = gql`
  mutation Login($params: LoginInput!) {
    login(params: $params) {
      _id
      token
    }
  }
`
