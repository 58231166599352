// packages
import { FC } from 'react'
// mantine
import { Box, DEFAULT_THEME, Slider, SliderProps, Text } from '@mantine/core'
// props
import { BaseInputProps } from './baseInputProps'

export type SliderInputWrapperComponentProps = {
  label: string
} & SliderProps

/**
 * Props for SliderInput Component
 */
export type SliderInputWrapperProps = {
  props: SliderInputWrapperComponentProps
} & BaseInputProps

/**
 * Wrapper for Mantine Slider Component.
 *
 * @see [Slider](https://mantine.dev/core/slider/)
 *
 * TODO: move code away from DEFAULT_THEME to useStyle hook
 * @see [issue](https://linear.app/upcode-sg/issue/UPCDE-134/use-usestyles-hook-to-get-styles-instead-of-default-theme)
 */
export const SliderInputWrapper: FC<SliderInputWrapperProps> = ({ form, fieldName, props }) => {
  const { label } = props
  return (
    <Box>
      <Text weight={500} color={DEFAULT_THEME.colors.gray[9]} size="sm">
        {label}
      </Text>
      <Slider
        styles={{
          markLabel: { fontSize: DEFAULT_THEME.fontSizes.xs },
        }}
        {...props}
        {...form.getInputProps(fieldName)}
      />
    </Box>
  )
}
