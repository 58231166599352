// packages
import { useMutation } from '@apollo/client'
import Link from 'next/link'
import { useRouter } from 'next/router'
// mantine
import {
  Anchor,
  Button,
  Checkbox,
  Container,
  Group,
  LoadingOverlay,
  Paper,
  PasswordInput,
  Text,
  TextInput,
  Title,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
// gql
import { ACCEPT_INVITATION_MUTATION } from 'gql'
// lib
import { initializeApollo } from 'lib'
import { setAuthToken } from 'localStorage'
// icons
import { Check } from 'tabler-icons-react'

export const InvitationContainer = () => {
  const client = initializeApollo()
  const router = useRouter()

  const form = useForm({
    initialValues: {
      name: '',
      phone: '',
      email: '',
      password: '',
    },
  })

  const [action, { loading, error }] = useMutation(ACCEPT_INVITATION_MUTATION)

  const handleSubmit = async () => {
    const { name, phone, email, password } = form.values
    const params = { name, phone, email, password }

    const { data } = await action({ variables: { params } })

    if (error) {
      // TODO: add error message here for login failure
      // alert(JSON.stringify(error.message, null, 2))
      // setError(errors[0].message)
    } else {
      setAuthToken(data.acceptInvitation.token)
      showNotification({
        color: 'teal',
        icon: <Check size={18} />,
        autoClose: 4000,
        title: 'Welcome!',
        message: 'Account created successfully.',
      })

      await client.resetStore()
      await router.push('/')
    }
  }

  return (
    <Container size={420} my={40}>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <LoadingOverlay visible={loading} />

        <Title align="center" sx={(_) => ({ fontWeight: 600 })}>
          Sign Up
        </Title>
        <Text color="dimmed" size="sm" align="center" mt={5}>
          Already have an account?{' '}
          <Anchor href="/login" size="sm" underline={false} component={Link}>
            Login
          </Anchor>
        </Text>

        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          <TextInput
            label="Name"
            placeholder="Enter your name"
            required
            {...form.getInputProps('name')}
          />
          <TextInput
            label="Phone Number"
            placeholder="91111111"
            required
            mt="md"
            {...form.getInputProps('phone')}
          />
          <TextInput
            label="Email"
            placeholder="@mandai.com"
            required
            mt="md"
            {...form.getInputProps('email')}
          />
          <PasswordInput
            label="Password"
            placeholder="Your password"
            required
            mt="md"
            {...form.getInputProps('password')}
          />

          {error && (
            <Text color="red" size="sm" align="center" mt={5}>
              {JSON.stringify(error.graphQLErrors, null, 2)}
            </Text>
          )}

          <Button fullWidth mt="xl" type="submit">
            Create Account
          </Button>
        </Paper>
      </form>
    </Container>
  )
}
