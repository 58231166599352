// packages
import { useState } from 'react'
import { useMutation } from '@apollo/client'
// mantine
import { Box, Button, Group, Modal, Select } from '@mantine/core'
// gql
import { ACTIVATE_ELEPHANT_FEEDPODS_MUTATION } from 'gql'

export const ElephantFeedPodsActivationModal = ({
  id,
  opened,
  onClose,
}: {
  id: string
  opened: boolean
  onClose: any
}) => {
  const [activate, { loading }] = useMutation(ACTIVATE_ELEPHANT_FEEDPODS_MUTATION)

  const [operationType, setOperationType] = useState<string | null>(null)

  const submit = () => {
    activate({
      variables: { id, operationType },
    })
  }

  return (
    <Modal opened={opened} onClose={onClose} title="Activate Elephant Feed Pods" centered>
      <Box mb="sm">
        <Select
          withinPortal
          label="Select amount to feed"
          value={operationType}
          onChange={setOperationType}
          required
          placeholder="Feed type"
          data={[
            { value: 'Low', label: 'Low' },
            { value: 'Medium', label: 'Medium' },
            { value: 'High', label: 'High' },
          ]}
        />
      </Box>

      <Group position="right">
        <Button onClick={submit} loading={loading}>
          Submit
        </Button>
      </Group>
    </Modal>
  )
}
