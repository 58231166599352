// packages
// mantine
import { Box, Card, RingProgress, SimpleGrid, Stack, Text } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
// Components
import { ElephantMonitoringHeatMap } from './HeatMap'

export const ElephantMonitoringDashboardContainer = () => {
  const { ref, width } = useElementSize()

  return (
    <Stack>
      <Text size="xl" weight={400}>
        Elephant Monitoring
      </Text>

      <SimpleGrid cols={2}>
        <Box>
          <Text weight={500}>Standing</Text>
          <RingProgress
            label={
              <Text size="xs" align="center">
                11/12 hours
              </Text>
            }
            sections={[{ value: 87, color: 'green' }]}
          />
        </Box>
        <Box>
          <Text weight={500}>Lying down</Text>
          <RingProgress
            label={
              <Text size="xs" align="center">
                4/6 hours
              </Text>
            }
            sections={[{ value: 67, color: 'pink' }]}
          />
        </Box>
      </SimpleGrid>

      <Card withBorder radius="md" ref={ref}>
        <Text weight={500}>Lying down time series</Text>
        <ElephantMonitoringHeatMap width={width} height={200} />
      </Card>

      <Card withBorder radius="md">
        <Text weight={500}>Average lying down time series per day</Text>
      </Card>
    </Stack>
  )
}
