// packages
import { FC } from 'react'
import { useQuery } from '@apollo/client'
// mantine
import { Box, Card, Text } from '@mantine/core'
// gql
import { GET_ACTIONS_LIST_QUERY } from 'gql'
// Components
import { ActivateActionsList } from './List'

type ActionsListContainerProps = {
  _id: string
  height?: number
}

export const ActionsListContainer: FC<ActionsListContainerProps> = ({ _id, height }) => {
  const { data, loading, error } = useQuery(GET_ACTIONS_LIST_QUERY, {
    variables: { _id },
  })

  if (data)
    if (data.getActionsList) {
      const { actions } = data.getActionsList
      return (
        <>
          <Card withBorder p="sm" style={{ height }}>
            <Box p={0}>
              <Text size="sm" weight="bold">
                Training Program
              </Text>
            </Box>
            <Card.Section>
              <ActivateActionsList _ids={actions.map(({ _id }: { _id: string }) => _id)} />
            </Card.Section>
          </Card>
        </>
      )
    }

  return <></>
}
