// packages
import { gql } from '@apollo/client'

export const GET_UPCOMING_ACTIVATIONS_QUERY = gql`
  query GetUpcomingActivations($start: DateTime!, $end: DateTime!, $sourceId: ObjectId!) {
    getUpcomingActivations(start: $start, end: $end, sourceId: $sourceId) {
      _id
      status
      requestBody
      createdAt
      scheduledAt
      user {
        _id
        name
      }
    }
  }
`
