// packages
import { useQuery } from '@apollo/client'
import { NextPage } from 'next'
import { useRouter } from 'next/router'
// _app
import { NextPageWithLayout } from 'pages/_app'
// gql
import { GET_CURRENT_USER_QUERY } from 'gql'
// localStorage
import { getAuthToken } from 'localStorage'

export function withAuthRequired({
  Component,
  location = '/login',
}: {
  Component: NextPageWithLayout
  location?: string
}): NextPageWithLayout {
  const WithAuthRequiredWrapper: NextPageWithLayout = (props) => {
    const router = useRouter()
    const { data, loading, error } = useQuery(GET_CURRENT_USER_QUERY)

    if (loading || error) return <></>

    const isAuthenticated = !!getAuthToken() || (data && data.getCurrentUser)

    if (isAuthenticated) {
      return <Component {...props} />
    } else {
      router.push({ pathname: location, query: { redirect: router.asPath } })
      return <></>
    }
  }

  WithAuthRequiredWrapper.getLayout = Component.getLayout
  return WithAuthRequiredWrapper
}
