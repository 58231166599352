// packages
import { useQuery } from '@apollo/client'
import { FC } from 'react'
// mantine
import { Center, Loader, ScrollArea, Table } from '@mantine/core'
// gql
import { GET_PULSES_QUERY } from 'gql'
// Containers
import { PulsesListTableRow } from './Row'
// styles
import useStyles from './styles'

export type PulsesListTableProps = {
  _ids: string[]
  height?: number
}

export const PulsesListTable: FC<PulsesListTableProps> = ({ _ids, height }) => {
  const { data, loading, error } = useQuery(GET_PULSES_QUERY, {
    variables: { ids: _ids },
  })

  const { classes } = useStyles()

  if (data)
    if (data.getPulses) {
      return (
        <ScrollArea sx={{ height }} scrollbarSize={5}>
          <Table sx={{ minWidth: 300 }}>
            <thead className={classes.header}>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th style={{ textAlign: 'center' }}>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.getPulses.map(
                ({ _id, name, status }: { _id: string; name: string; status: string }) => (
                  <PulsesListTableRow _id={_id} name={name} status={status} key={_id} />
                ),
              )}
            </tbody>
          </Table>
        </ScrollArea>
      )
    }

  return (
    <Center style={{ height: '100%' }}>
      <Loader />
    </Center>
  )
}
