import React from 'react'
import { Group } from '@visx/group'
import genBins, { Bin, Bins } from '@visx/mock-data/lib/generators/genBins'
import { scaleLinear } from '@visx/scale'
import { HeatmapRect } from '@visx/heatmap'
import { getSeededRandom } from '@visx/mock-data'
import { Axis } from '@visx/axis'

// const hot1 = '#77312f'
// const hot2 = '#f33d15'
// const cool1 = '#122549'
// const cool2 = '#b4fbde'
const cool1 = '#9E2B20'
const cool2 = '#455999'
export const background = '#28272c'

const seededRandom = getSeededRandom(0.41)

const randBinData = genBins(
  /* length = */ 24 * 60,
  /* height = */ 7,
  /** binFunc */ (idx) => 150 * idx,
  /** countFunc */ (i, number) => 25 * (number - i) * seededRandom(),
)

function max<Datum>(data: Datum[], value: (d: Datum) => number): number {
  return Math.max(...data.map(value))
}

function min<Datum>(data: Datum[], value: (d: Datum) => number): number {
  return Math.min(...data.map(value))
}

// accessors
const bins = (d: Bins) => d.bins
const count = (d: Bin) => d.count

const colorMax = max(randBinData, (d) => max(bins(d), count))
const bucketSizeMax = max(randBinData, (d) => bins(d).length)

// scales
const xScale = scaleLinear<number>({
  domain: [0, randBinData.length],
})
const yScale = scaleLinear<number>({
  domain: [0, bucketSizeMax],
})
const rectColorScale = scaleLinear<string>({
  range: [cool1, cool2],
  domain: [0, colorMax],
})
const opacityScale = scaleLinear<number>({
  range: [1, 1],
  domain: [0, colorMax],
})

export type HeatmapProps = {
  width: number
  height: number
  margin?: { top: number; right: number; bottom: number; left: number }
  events?: boolean
}

const defaultMargin = { top: 0, left: 0, right: 0, bottom: 0 }

export const ElephantMonitoringHeatMap = ({
  width,
  height,
  margin = defaultMargin,
}: HeatmapProps) => {
  const binData = [
    ...randBinData.slice(0, 8.5 * 60),
    ...randBinData
      .slice(8.5 * 60, 13 * 60)
      .map((d) => ({ ...d, bins: d.bins.map((b) => ({ ...b, count: (b.count = 200) })) })),
    ...randBinData.slice(13 * 60),
  ]

  // bounds
  const xMax = width
  const yMax = height - margin.bottom - margin.top

  const binWidth = xMax / binData.length
  const binHeight = yMax / bucketSizeMax

  xScale.range([0, xMax])
  yScale.range([yMax, 0])

  return (
    <svg width={width} height={height + 90}>
      <Group top={0} left={45}>
        <HeatmapRect
          data={binData}
          xScale={(d) => xScale(d) ?? 0}
          yScale={(d) => yScale(d) ?? 0}
          colorScale={rectColorScale}
          opacityScale={opacityScale}
          binWidth={binWidth}
          binHeight={binHeight}
          gap={-1}
        >
          {(heatmap) =>
            heatmap.map((heatmapBins) =>
              heatmapBins.map((bin) => (
                <rect
                  key={`heatmap-rect-${bin.row}-${bin.column}`}
                  className="visx-heatmap-rect"
                  width={bin.width}
                  height={bin.height}
                  x={bin.x}
                  y={bin.y}
                  fill={bin.color}
                  fillOpacity={bin.opacity}
                  onClick={() => {
                    const { row, column } = bin
                    alert(JSON.stringify({ row, column, bin: bin.bin }))
                  }}
                />
              )),
            )
          }
        </HeatmapRect>
        <Axis
          orientation="left"
          scale={yScale}
          tickValues={Array(7)
            .fill(0)
            .map((_, i) => i + 1)}
          tickFormat={(v: any) => ['', 'Mon', 'Tues', 'Weds', 'Thurs', 'Fri', 'Sat', 'Sun'][v]}
          labelOffset={30}
          tickLabelProps={() => ({
            dx: -24,
            dy: 2,
            fontSize: 9,
          })}
          left={0}
          top={30}
          label="Day"
        />
        <Axis
          orientation="bottom"
          scale={xScale}
          tickValues={Array(24)
            .fill(0)
            .map((_, i) => 60 * i)}
          tickFormat={(v: any) => `${v / 60}`}
          top={height + 30}
          left={0}
          label="Hour"
        />
      </Group>
    </svg>
  )
}
