// packages
import { useEffect, useState } from 'react'
import { useApolloClient, useMutation } from '@apollo/client'
// mantine
import { Button } from '@mantine/core'
import { useDebouncedState } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'
// gql
import {
  ACTIVATE_SOURCE_MUTATION,
  GET_CSB_REMAINING_FEEDS_QUERY,
  GET_UPCOMING_ACTIVATIONS_QUERY,
} from 'gql'
// Components
import { SuggestedDatePicker } from './SuggestedDatePicker'
// icons
import { Check } from 'tabler-icons-react'

export const CSBScheduleFeedButton = () => {
  const client = useApolloClient()

  const [opened, setOpened] = useState(false)
  const [value, setValue] = useDebouncedState<number>(0, 2000)

  const [activate, { loading, error }] = useMutation(ACTIVATE_SOURCE_MUTATION, {
    onCompleted: () => {
      setValue(Math.random())
      notifications.show({
        title: 'Scheduling new activation',
        message: 'Please wait while we are adding this activation to our schedule.',
        loading: true,
        autoClose: 2000,
        onClose: () => {},
      })

      setTimeout(() => {
        notifications.show({
          title: 'Success',
          message: 'Added new feed to the CSB.',
          icon: <Check size="1.1rem" />,
          color: 'green',
        })
      }, 2500)
    },
    refetchQueries: [GET_CSB_REMAINING_FEEDS_QUERY],
  })

  useEffect(() => {
    if (value != 0) client.refetchQueries({ include: [GET_UPCOMING_ACTIVATIONS_QUERY] })
  }, [value, client])

  return (
    <>
      <SuggestedDatePicker
        activate={activate}
        opened={opened}
        setOpened={setOpened}
        loading={loading}
      />

      <Button onClick={() => setOpened(true)}>Schedule Feed</Button>
    </>
  )
}
