// packages
import { FC } from 'react'
import dayjs from 'dayjs'
// mantine
import { Popover, Text, useMantineTheme } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
// Components
import { Icon } from 'Components'
// interfaces
import { IElephantFeedPodsDevice } from '../elephantFeedPods.interface'

export type ElephantFeedPodsDeviceStatusBadgeProps = {
  status: IElephantFeedPodsDevice['status']
  updated: IElephantFeedPodsDevice['updated']
}

export const ElephantFeedPodsDeviceStatusBadge: FC<ElephantFeedPodsDeviceStatusBadgeProps> = ({
  status,
  updated,
}) => {
  const theme = useMantineTheme()
  const successColor = theme.colorScheme === 'light' ? theme.colors.green[7] : theme.colors.teal[7]
  const errorColor = theme.colorScheme === 'light' ? theme.colors.red[5] : theme.colors.pink[9]

  const color = status == 'Device is connected' ? successColor : errorColor

  const [opened, { close, open }] = useDisclosure(false)

  return (
    <Popover position="bottom" withArrow opened={opened}>
      <Popover.Target>
        <Icon
          name="DeviceHeartMonitor"
          size={23}
          strokeWidth={1.5}
          color={color}
          style={{ cursor: 'pointer' }}
          onMouseEnter={open}
          onMouseLeave={close}
        />
      </Popover.Target>
      <Popover.Dropdown sx={{ pointerEvents: 'none' }} style={{ marginTop: 50 }}>
        <Text size="sm" weight={500} color={color}>
          {status}
        </Text>
        <Text size="sm" color={color}>
          Last checked online: {dayjs(updated).format('D MMM, h:mm:ssa')}
        </Text>
      </Popover.Dropdown>
    </Popover>
  )
}
