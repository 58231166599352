// packages
import { Dispatch, FC, SetStateAction } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
// lib
import { initializeApollo } from 'lib'
// mantine
import { Avatar, Group, ActionIcon, Anchor, Flex } from '@mantine/core'
import { useLocalStorage } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
// localStorage
import { leftSideBarLocalStorageProps, removeAuthToken } from 'localStorage'
// Components
import { LeftSideBarContentName } from './Name'
import { LeftSideBarContentLinks } from './Links'
// icons
import { Bell, Check, Logout } from 'tabler-icons-react'

export type LeftSideBarContentProps = {
  setDrawerOpened: Dispatch<SetStateAction<boolean>>
}

export const LeftSideBarContent: FC<LeftSideBarContentProps> = ({ setDrawerOpened }) => {
  const [expanded] = useLocalStorage<boolean>(leftSideBarLocalStorageProps)
  const client = initializeApollo()
  const router = useRouter()

  const logout = async () => {
    removeAuthToken()
    showNotification({
      color: 'teal',
      icon: <Check size={18} />,
      autoClose: 4000,
      title: 'Bye Bye!',
      message: 'Logged out successfully.',
    })

    await client.resetStore()
    await router.push({ pathname: '/login', query: { redirect: router.asPath } })
  }

  return (
    <>
      <Flex justify="space-between" style={{ marginLeft: !expanded ? -5 : 0 }}>
        <Anchor href="/" underline={false} component={Link}>
          <Avatar color="cyan" radius="xl" />
        </Anchor>
        {expanded && (
          <Group position="center" spacing={0}>
            <ActionIcon variant="transparent">
              <Bell size={18} />
            </ActionIcon>
            <ActionIcon variant="transparent">
              <Logout size={18} onClick={() => logout()} />
            </ActionIcon>
          </Group>
        )}
      </Flex>

      <div style={{ marginTop: 5 }}>
        <LeftSideBarContentName />
      </div>

      <div style={{ marginTop: 15 }}>
        <LeftSideBarContentLinks setDrawerOpened={setDrawerOpened} />
      </div>
    </>
  )
}
