// packages
import { FC } from 'react'
// mantine
import { ActionIcon, Modal, Popover, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
// Components
import { PulsesUsersPermissions } from './PulsesUsersPermission'
// icons
import { User } from 'tabler-icons-react'

export type PulsesListTableRowTargetUsersActionIconProps = {
  _id: string
}

export const PulsesListTableRowTargetUsersActionIcon: FC<PulsesListTableRowTargetUsersActionIconProps> =
  ({ _id }) => {
    const [opened, { close, open }] = useDisclosure(false)
    const [modalOpened, { close: closeModal, open: openModal }] = useDisclosure(false)

    return (
      <>
        <Popover withArrow opened={opened} shadow="xs">
          <Popover.Target>
            <ActionIcon
              size="xs"
              style={{ cursor: 'pointer' }}
              onMouseEnter={open}
              onMouseLeave={close}
              onClick={openModal}
            >
              <User />
            </ActionIcon>
          </Popover.Target>
          <Popover.Dropdown sx={{ pointerEvents: 'none' }}>
            <Text size="xs">Target Users</Text>
          </Popover.Dropdown>
        </Popover>
        <Modal opened={modalOpened} onClose={closeModal} centered title="Users">
          <PulsesUsersPermissions _id={_id} />
        </Modal>
      </>
    )
  }
