// packages
import { FC, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
// mantine
import { useDisclosure, useInterval } from '@mantine/hooks'
import { Popover, Text } from '@mantine/core'

dayjs.extend(relativeTime)

export type RelativeDurationProps = {
  timestamp: string
  intervalDuration?: number
}

export const RelativeDuration: FC<RelativeDurationProps> = ({
  timestamp,
  intervalDuration = 1000,
}) => {
  const [opened, { close, open }] = useDisclosure(false)
  const [_, setCount] = useState(0)
  const interval = useInterval(() => setCount((c) => c + 1), intervalDuration)

  useEffect(() => {
    interval.start()
    return interval.stop
  }, [interval])

  return (
    <Popover position="right" withArrow withinPortal opened={opened}>
      <Popover.Target>
        <Text
          component="span"
          onMouseEnter={open}
          onMouseLeave={close}
          onClick={open}
          style={{ cursor: 'pointer' }}
        >
          {dayjs(timestamp).fromNow()}
        </Text>
      </Popover.Target>
      <Popover.Dropdown sx={{ pointerEvents: 'none' }}>
        <Text size="sm">{dayjs(timestamp).format('ddd h:mma')}</Text>
      </Popover.Dropdown>
    </Popover>
  )
}
