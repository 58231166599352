// packages
import { FC, useState } from 'react'
// mantine
import { Box, Card } from '@mantine/core'
// Components
import { SparkContentHeader } from './Header'
import { SparkContentBody } from './Body'

type SparkChartCardProps = {
  componentId: string
  height: number
  data: any
  loading: boolean
  props: any
}

export const SparkChartCard: FC<SparkChartCardProps> = ({
  componentId,
  height,
  data,
  loading,
  props,
}) => {
  const [isCurrent, setIsCurrent] = useState(true)
  const [value, setValue] = useState('')
  const [dateStr, setDateStr] = useState('')

  const headerHeight = 84
  const padding = 12
  const bodyHeight = height - headerHeight - padding

  return (
    <Card style={{ height }} withBorder p="sm">
      <Box p={0} style={{ height: headerHeight, overflow: 'hidden' }}>
        <SparkContentHeader
          componentId={componentId}
          props={props}
          data={data}
          loading={loading}
          isCurrent={isCurrent}
          value={value}
          dateStr={dateStr}
        />
      </Box>
      <Card.Section>
        <SparkContentBody
          data={data}
          loading={loading}
          height={bodyHeight}
          props={props}
          setIsCurrent={setIsCurrent}
          setValue={setValue}
          setDateStr={setDateStr}
        />
      </Card.Section>
    </Card>
  )
}
