// packages
import { UseForm, UseFormReturnType } from '@mantine/form/lib/types'
import { FC } from 'react'
// Inputs
import {
  ColorSwatchInputWrapper,
  MultiSelectInputWrapper,
  NumberInputWrapper,
  SelectInputWrapper,
  SliderInputWrapper,
  SwitchInputWrapper,
} from './Inputs'

/** A combination of all supported input Components */
export type InputMappingType =
  | 'ColorSwatch'
  | 'MultiSelect'
  | 'Number'
  | 'Select'
  | 'Slider'
  | 'Switch'

/** Props for InputWrapper Component */
export type InputWrapperProps = {
  /** type of input */
  type: InputMappingType

  form: UseFormReturnType<
    Record<string, unknown>,
    (values: Record<string, unknown>) => Record<string, unknown>
  >
  fieldName: string
  props: any
}

/** InputWrapper maps the type to the correct Input Component */
export const InputWrapper: FC<InputWrapperProps> = ({ type, form, fieldName, props }) => {
  const mapping: Record<InputMappingType, FC<Omit<InputWrapperProps, 'type'>>> = {
    ColorSwatch: ColorSwatchInputWrapper,
    MultiSelect: MultiSelectInputWrapper,
    Number: NumberInputWrapper,
    Select: SelectInputWrapper,
    Slider: SliderInputWrapper,
    Switch: SwitchInputWrapper,
  }
  const Component = mapping[type]

  if (!Component) {
    return <div>Unable to render input for {type}</div>
  }

  return <Component form={form} fieldName={fieldName} props={props} />
}
