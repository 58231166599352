// packages
import { FC, ReactNode } from 'react'
import { useQuery } from '@apollo/client'
// mantine
import { ActionIcon, Box, Card, Divider, Flex, Skeleton, Text } from '@mantine/core'
// gql
import { GET_PULSES_LIST_QUERY } from 'gql'
// Containers
import { PulsesListTable } from './Table'
import { DotsVertical, Refresh } from 'tabler-icons-react'

export type PulsesListContainerProps = {
  _id: string
  height?: number
}

export const PulsesListContainer: FC<PulsesListContainerProps> = ({ _id, height }) => {
  const { data, loading, error, refetch } = useQuery(GET_PULSES_LIST_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: { _id },
  })

  const headerHeight = 32
  const contentHeight = height ? height - headerHeight : undefined

  const Container = ({ height, children }: { height?: number; children: ReactNode }) => {
    return (
      <Card withBorder p={0} style={{ height }}>
        <Flex p="sm" pb={0} h={headerHeight} justify="space-between" align="center">
          <Text size="sm" weight="bold">
            Pulses
          </Text>

          <Flex>
            <ActionIcon variant="transparent" size="xs" onClick={() => refetch()}>
              <Refresh />
            </ActionIcon>
            <ActionIcon variant="transparent" size="xs">
              <DotsVertical />
            </ActionIcon>
          </Flex>
        </Flex>
        <Divider my="sm" />
        {children}
      </Card>
    )
  }

  if (loading)
    return (
      <Container height={height}>
        <Box p="sm">
          <Skeleton h="1rem" w={120} />
        </Box>
      </Container>
    )

  if (data)
    if (data.getPulsesList) {
      const { pulses } = data.getPulsesList
      return (
        <Container height={height}>
          <PulsesListTable
            _ids={pulses.map(({ _id }: { _id: string }) => _id)}
            height={contentHeight}
          />
        </Container>
      )
    }

  return <></>
}
