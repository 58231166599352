// packages
import { FC } from 'react'
import { useRouter } from 'next/router'
// mantine
import { Prism, PrismProps } from '@mantine/prism'

export type DebugPrinterProps = {
  value: unknown
  language: PrismProps['language']
}
export const DebugPrinter: FC<DebugPrinterProps> = ({ value, language }) => {
  const router = useRouter()
  if (process.env.NODE_ENV !== 'production') {
    const { debug } = router.query
    if (debug) return <Prism language={language}>{JSON.stringify(value, null, 4)}</Prism>
  }

  return <></>
}
