// mantine
import { createStyles } from '@mantine/core'
// shared styles
import { borderColorStyles } from 'shared'

export default createStyles((theme) => ({
  leftSideBar: {
    borderRight: `1px solid ${borderColorStyles(theme)}`,
  },
}))
