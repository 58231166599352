// packages
import { Dispatch, FC, SetStateAction } from 'react'
// mantine
import { Center, Skeleton } from '@mantine/core'
// Components
import { SparkChart } from 'Components'
import { isEmpty } from 'lodash'

type SparkContentBodyProps = {
  data: any
  loading: boolean
  height: number
  props: any
  setIsCurrent: Dispatch<SetStateAction<boolean>>
  setValue: Dispatch<SetStateAction<string>>
  setDateStr: Dispatch<SetStateAction<string>>
}

export const SparkContentBody: FC<SparkContentBodyProps> = ({
  data,
  loading,
  height,
  props,
  setIsCurrent,
  setValue,
  setDateStr,
}) => {
  const { title, xKey, yKey, color } = props
  if (loading) return <Skeleton height={height} />

  if (isEmpty(data)) return <Center>No Data Found</Center>

  if (data) {
    const current = data[data.length - 1]

    const chart = data.map((d: any) => ({
      x: new Date(d[xKey]),
      y: parseFloat(d[yKey]),
    }))

    return (
      <SparkChart
        data={chart}
        height={height}
        current={current}
        color={color}
        props={props}
        setIsCurrent={setIsCurrent}
        setValue={setValue}
        setDateStr={setDateStr}
      />
    )
  }

  return <Center>No Data Found</Center>
}
