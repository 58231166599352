// packages
import { FC } from 'react'
import { useQuery } from '@apollo/client'
// mantine
import { Center, Loader, Skeleton } from '@mantine/core'
// gql
import { GET_TEXT_QUERY } from 'gql'
// Components
import { DebugPrinter, TextComponent } from 'Components'

export type TextContainerProps = {
  _id: string
}

export const TextContainer: FC<TextContainerProps> = ({ _id }) => {
  const { data, loading, error } = useQuery(GET_TEXT_QUERY, {
    variables: { _id },
  })

  if (data)
    if (data.getText) {
      const { content, props } = data.getText
      const { color, ...rest } = props
      return (
        <>
          <TextComponent content={content} color={color} props={rest} />
          <DebugPrinter language="json" value={data.getText} />
        </>
      )
    }

  return <Loader variant="dots" size="xs" color="gray" />
}
