// packages
import { FC } from 'react'
import { useQuery } from '@apollo/client'
// mantine
// gql
import { GET_COMPONENT_QUERY } from 'gql'
// Containers
import { ActionsListContainer } from './ActionsList'
import { ButtonLinkContainer } from './ButtonLink'
import { ButtonModalContainer } from './ButtonModal'
import { ChartSparkContainer } from './Chart'
import { CSBAudioUploadContainer, VideoPlayerContainer } from './CSB'
import { ButtonWithMenuContainer } from './ButtonWithMenu'
import { MenuItemModalContainer } from './MenuItem'
import { PulsesListContainer } from './PulsesList'
import { RecentActivationsContainer } from './Activations'
import { TextContainer } from './Text'

type ComponentContainerProps = {
  _id: string
  height?: number
  params?: object
  Loader?: any
  additionalProps?: object
}

export const ComponentContainer: FC<ComponentContainerProps> = ({
  _id,
  height,
  params,
  Loader = () => <></>,
  additionalProps,
}) => {
  const { data, error } = useQuery(GET_COMPONENT_QUERY, { variables: { _id } })

  if (data)
    if (data.getComponent) {
      const { variant } = data.getComponent

      type VariantType =
        | 'ActionsList'
        | 'ButtonModal'
        | 'ButtonLink'
        | 'ButtonWithMenu'
        | 'ChartSpark'
        | 'CSBAudioUpload'
        | 'MenuItemModal'
        | 'PulsesList'
        | 'RecentActivations'
        | 'Text'
        | 'VideoPlayer'

      const mapping: Record<VariantType, React.FC<any>> = {
        ActionsList: ActionsListContainer,
        ButtonLink: ButtonLinkContainer,
        ButtonModal: ButtonModalContainer,
        ButtonWithMenu: ButtonWithMenuContainer,
        ChartSpark: ChartSparkContainer,
        CSBAudioUpload: CSBAudioUploadContainer,
        MenuItemModal: MenuItemModalContainer,
        PulsesList: PulsesListContainer,
        RecentActivations: RecentActivationsContainer,
        Text: TextContainer,
        VideoPlayer: VideoPlayerContainer,
      }

      const Component = mapping[variant as VariantType]
      if (!Component)
        throw new Error(`ComponentContainer is unable to render Component with variant: ${variant}`)

      return <Component _id={_id} height={height} params={params} {...additionalProps} />
    }

  return Loader
}
