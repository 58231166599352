// packages
import { FC } from 'react'
import dayjs from 'dayjs'
import { useMutation } from '@apollo/client'
// mantine
import { ActionIcon, Text } from '@mantine/core'
import { modals } from '@mantine/modals'
import { notifications } from '@mantine/notifications'
// gql
import { CANCEL_SCHEDULED_ACTIVATION_MUTATION, GET_UPCOMING_ACTIVATIONS_QUERY } from 'gql'
// icons
import { Ban, Check } from 'tabler-icons-react'

type CancelScheduledActivationActionIconProps = {
  _id: string
  scheduledAt: string
}

export const CancelScheduledActivationActionIcon: FC<CancelScheduledActivationActionIconProps> = ({
  _id,
  scheduledAt,
}) => {
  const [cancel, { loading, error }] = useMutation(CANCEL_SCHEDULED_ACTIVATION_MUTATION)
  const onCancel = async () => {
    await cancel({
      variables: {
        id: _id,
      },
      refetchQueries: [GET_UPCOMING_ACTIVATIONS_QUERY],
      onCompleted: () => {
        notifications.show({
          title: 'Success',
          message: 'Removed scheduled feed from the CSB.',
          icon: <Check size="1.1rem" />,
          color: 'green',
        })
      },
    })
  }

  const openDeleteModal = () =>
    modals.openConfirmModal({
      title: 'Cancel activation',
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to cancel the activation scheduled for{' '}
          {dayjs(scheduledAt).format('hh:mm A')}? This action is irreversible.
        </Text>
      ),
      labels: { confirm: 'Yes', cancel: "No don't cancel it" },
      confirmProps: { color: 'red', loading },
      onConfirm: onCancel,
    })

  return (
    <>
      <ActionIcon color="red" size="sm" radius="xl" variant="subtle" onClick={openDeleteModal}>
        <Ban />
      </ActionIcon>
    </>
  )
}
