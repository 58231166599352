// packages
import { gql } from '@apollo/client'

export const GET_TARGET_USERS_OF_PULSE_QUERY = gql`
  query GetTargetUsersOfPulse($_id: ObjectId!) {
    getTargetUsersOfPulse(_id: $_id) {
      _id
      name
    }
  }
`
