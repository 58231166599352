// mantine
import { createStyles } from '@mantine/core'

export default createStyles((theme) => ({
  body: {
    display: 'flex',
  },
  wrapper: {
    [`@media (max-width: ${theme.breakpoints.md})`]: {
      padding: `${theme.spacing.lg} 15px`,
    },

    padding: `${theme.spacing.lg} 50px`,
    display: 'relative',
  },
}))
