// package
import { gql } from '@apollo/client'

export const GET_CHART_SPARK_QUERY = gql`
  query getChartSpark($_id: ObjectId!) {
    getChartSpark(_id: $_id) {
      _id
      props
      dataProps
      data {
        _id
      }
      transformer {
        field
        type
      }
    }
  }
`
