// packages
import { getRandomNormal } from '@visx/mock-data'
// mantine
import { Anchor, Box, Card, Flex, Grid, Group, Stack, Text } from '@mantine/core'
// Components
import { ComponentContainer } from 'Containers'
import { RingTarget } from './RingTarget'
import { DataSparkLine } from './DataSparkLine'
import { HeartRateLineChart } from './HeartRateLineChart'
import { TigerAnimalWelfareActions } from './Actions'
import { AnimalWelfareTigerTrainingProgram } from './TrainingProgram'
// icons
import { ArrowDown, ArrowUp } from 'tabler-icons-react'
import { AnticipatoryPacingChart } from './AnticipatoryPacingChart'

export const TigerAnimalWelfareContainer = () => {
  return (
    <Grid gutter="sm">
      <Grid.Col span={12}>
        <Flex justify="space-between">
          <Text weight={500} size="xl">
            Tiger Dashboard
          </Text>
          <TigerAnimalWelfareActions />
        </Flex>
      </Grid.Col>
      <Grid.Col span={3}>
        <Card withBorder radius="sm">
          <Text transform="uppercase" size={11} weight={600} color="dimmed">
            Weight
          </Text>
          <Group mb="xs" spacing="0.25em" align="flex-end">
            <Text size="xl" weight={500}>
              281 kg
            </Text>
            <Group spacing="0.125rem" align="center">
              <Text size="xs" weight={500} color="green">
                1.2%
              </Text>
              <ArrowUp size="0.5em" color="green" />
            </Group>
          </Group>

          <DataSparkLine
            height={20}
            values={new Array(100).fill(1).map((_) => getRandomNormal(280, 10)())}
          />
        </Card>
      </Grid.Col>

      <Grid.Col span={3}>
        <Card withBorder radius="sm">
          <Text transform="uppercase" size={11} weight={600} color="dimmed">
            Height
          </Text>
          <Group mb="xs" spacing="0.25em" align="flex-end">
            <Text size="xl" weight={500}>
              102 cm
            </Text>
            <Group spacing="0.125rem" align="center">
              <Text size="xs" weight={500} color="green">
                0.6%
              </Text>
              <ArrowUp size="0.5em" color="green" />
            </Group>
          </Group>

          <DataSparkLine
            height={20}
            values={[
              ...new Array(70).fill(100),
              ...new Array(20).fill(101),
              ...new Array(10).fill(102),
            ]}
          />
        </Card>
      </Grid.Col>

      <Grid.Col span={3}>
        <Card withBorder radius="sm">
          <Text transform="uppercase" size={11} weight={600} color="dimmed">
            Heart Rate
          </Text>
          <Group mb="xs" spacing="0.25em" align="flex-end">
            <Text size="xl" weight={500}>
              75 bpm
            </Text>
            <Group spacing="0.125rem" align="center">
              <Text size="xs" weight={500} color="red">
                0.5%
              </Text>
              <ArrowDown size="0.5em" color="red" />
            </Group>
          </Group>

          <DataSparkLine
            height={20}
            values={new Array(100).fill(1).map((_) => getRandomNormal(75, 10)())}
          />
        </Card>
      </Grid.Col>

      <Grid.Col span={3}>
        <Card withBorder radius="sm">
          <Text transform="uppercase" size={11} weight={600} color="dimmed">
            Temperature
          </Text>
          <Group mb="xs" spacing="0.25em" align="flex-end">
            <Text size="xl" weight={500}>
              38.7 °C
            </Text>
            <Group spacing="0.125rem" align="center">
              <Text size="xs" weight={500} color="green">
                0.9%
              </Text>
              <ArrowUp size="0.5em" color="green" />
            </Group>
          </Group>

          <DataSparkLine
            height={20}
            values={new Array(100).fill(1).map((_) => getRandomNormal(38.5, 0.5)())}
          />
        </Card>
      </Grid.Col>

      <Grid.Col span={8}>
        <Card withBorder radius="sm" pb={0}>
          <Box pb="sm">
            <Text size="xs" weight={600} transform="uppercase">
              Average Heart Rate Per Day
            </Text>
          </Box>
          <Card.Section>
            <HeartRateLineChart height={320} />
          </Card.Section>
        </Card>
      </Grid.Col>

      <Grid.Col span={4}>
        <Stack spacing="sm">
          <Card withBorder radius="sm">
            <RingTarget
              percent={86}
              label="Walking"
              color="#65e0e0"
              rightSection={
                <>
                  <Box>
                    <Text size="sm" weight={500}>
                      Average Walking Time
                    </Text>
                    <Text size={12} color="dimmed">
                      The average time a tiger spends walking in the habitat a day
                    </Text>
                  </Box>
                  <Box>
                    <Text size="xl" weight={500}>
                      7m:32s&nbsp;
                      <Text size="sm" color="dimmed" component="span">
                        / Goal: 8m:00s
                      </Text>
                    </Text>
                  </Box>
                </>
              }
            />
          </Card>

          <Card withBorder radius="sm">
            <RingTarget
              percent={69}
              label="Sleeping"
              color="#69b2f8"
              rightSection={
                <>
                  <Box>
                    <Text size="sm" weight={500}>
                      Average Walking Time
                    </Text>
                    <Text size={12} color="dimmed">
                      The average time a tiger spends walking in the habitat a day
                    </Text>
                  </Box>
                  <Box>
                    <Text size="xl" weight={500}>
                      7m:32s&nbsp;
                      <Text size="sm" color="dimmed" component="span">
                        / Goal: 8m:00s
                      </Text>
                    </Text>
                  </Box>
                </>
              }
            />
          </Card>
        </Stack>
      </Grid.Col>

      <Grid.Col span={12}>
        <Card withBorder radius="sm" pb={0}>
          <Flex justify="space-between" pb="sm">
            <Box>
              <Text size="xs" weight={600} transform="uppercase">
                Anticipatory Pacing
              </Text>
              <Text size="xl">7 times</Text>
              <Text color="dimmed" weight={500}>
                This week
              </Text>
            </Box>
            <Box>
              <Anchor>Add data</Anchor>
            </Box>
          </Flex>
          <Box>
            <AnticipatoryPacingChart height={280} />
          </Box>
        </Card>
      </Grid.Col>

      <Grid.Col span={12}>
        <AnimalWelfareTigerTrainingProgram />
      </Grid.Col>

      <Grid.Col span={12}>
        <ComponentContainer
          _id={
            process.env.NODE_ENV === 'production'
              ? '63fc4c5acf7bd8b83e7cbfeb'
              : '63fb901ef0660328536f0523'
          }
        />
      </Grid.Col>
    </Grid>
  )
}
