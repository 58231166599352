// packages
import { FC } from 'react'
// mantine
import { Text } from '@mantine/core'
// Components
import { PulsesListTableRowStatus } from './Status'
import { PulsesListTableRowActions } from './Actions'

export type PulsesListTableRowProps = {
  _id: string
  name: string
  status: string
}

export const PulsesListTableRow: FC<PulsesListTableRowProps> = ({ _id, name, status }) => {
  return (
    <tr>
      <td>
        <Text size="sm">{name}</Text>
      </td>
      <td>SMS</td>
      <td style={{ textAlign: 'center' }}>
        <PulsesListTableRowStatus status={status} />
      </td>
      <td>
        <PulsesListTableRowActions _id={_id} />
      </td>
    </tr>
  )
}
