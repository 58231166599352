// packages
import { FC, useState } from 'react'
// mantine
import { Button } from '@mantine/core'
// Containers
import { ModalContainer } from 'Containers'

export type ButtonModalProps = {
  modalId: string
  label: string
  props: any
}

export const ButtonModal: FC<ButtonModalProps> = ({ modalId, label, props }) => {
  const [opened, setOpened] = useState(false)
  return (
    <>
      <ModalContainer _id={modalId} opened={opened} onClose={() => setOpened(false)} />
      <Button {...props} onClick={() => setOpened(true)}>
        {label}
      </Button>
    </>
  )
}
