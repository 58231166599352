// packages
import { FC } from 'react'
import * as IconSet from 'tabler-icons-react'

/**
 * Props for Icon Component
 */
export type IconProps = {
  /**
   * Name refers to the icons available for use in the tabler-icons-react package.
   *
   * @see [Icons List](https://tabler-icons-react.vercel.app/)
   */
  name: keyof typeof IconSet

  /**
   * Size of Icon
   *
   * @defaultValue 16
   */
  size?: number

  /**
   * Stroke Width of Icon
   *
   * @defaultValue 2
   */
  strokeWidth?: number

  /**
   * Color of Icon
   */
  color?: string
} & IconSet.IconProps

/**
 * A Component that maps icons to the tabler icon react Component.
 *
 * @see [Icons List](https://tabler-icons-react.vercel.app/)
 */
export const Icon: FC<IconProps> = ({ name, size = 16, strokeWidth = 2, color, ...props }) => {
  const combinedProps = { size, strokeWidth, color, ...props }

  try {
    return IconSet[name](combinedProps)
  } catch {
    return IconSet['Atom'](combinedProps)
  }
}
