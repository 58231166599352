// packages
import { gql } from '@apollo/client'

export const GET_BUTTON_LINK_QUERY = gql`
  query GetButtonLink($_id: ObjectId!) {
    getButtonLink(_id: $_id) {
      _id
      label
      props
      url
      newTab
    }
  }
`
