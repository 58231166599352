// packages
import { FC, useState } from 'react'
import { useQuery } from '@apollo/client'
// mantine
import { Box, Card, Flex, Skeleton, Text } from '@mantine/core'
// gql
import { GET_DATA_QUERY } from 'gql'

type CSBTemperatureOverviewProps = {
  start: Date
  end: Date
}

export const CSBTemperatureOverview: FC<CSBTemperatureOverviewProps> = ({ start, end }) => {
  const [average, setAverage] = useState<number | null>(null)
  const [highest, setHighest] = useState<number | null>(null)
  const [lowest, setLowest] = useState<number | null>(null)

  useQuery(GET_DATA_QUERY, {
    variables: {
      _id:
        process.env.NODE_ENV === 'production'
          ? '6411770e445eacb9cfa7db0a'
          : '63fb901ef0660328536f04fc',
      variables: {
        start,
        end,
      },
    },
    onCompleted: (result) => {
      var lowest = parseFloat(result.getData[0].chl)
      var highest = parseFloat(result.getData[0].chl)
      var total = 0
      result.getData.forEach((d: any) => {
        const chl = parseFloat(d.chl)
        if (lowest > chl) lowest = chl
        if (highest < chl) highest = chl
        total += chl
      })

      setLowest(lowest)
      setHighest(highest)
      setAverage(total / result.getData.length)
    },
  })

  return (
    <Card withBorder radius="md">
      <Text size="sm" weight="bold" mb="sm">
        Overview
      </Text>

      <Flex gap="md">
        <Box>
          <Text>Average</Text>
          {average ? (
            <Text weight={500} size="lg">
              {average && average.toFixed(2)}°C
            </Text>
          ) : (
            <Skeleton h={27.89} />
          )}
        </Box>
        <Box>
          <Text>Highest</Text>
          {highest ? (
            <Text weight={500} size="lg">
              {highest}°C
            </Text>
          ) : (
            <Skeleton h={27.89} />
          )}
        </Box>

        <Box>
          <Text>Lowest</Text>
          {lowest ? (
            <Text weight={500} size="lg">
              {lowest}°C
            </Text>
          ) : (
            <Skeleton h={27.89} />
          )}
        </Box>
      </Flex>
    </Card>
  )
}
