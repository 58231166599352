// packages
import { FC } from 'react'
// mantine
import { Avatar, Group, ScrollArea, Stack, Text, UnstyledButton } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
// Components
import { PulsesAddUserModal } from './AddUserModal'
import { PulseTargetUsersList } from './List'
// icons
import { UserPlus } from 'tabler-icons-react'

export type PulsesUsersPermissions = {
  _id: string
}

export const PulsesUsersPermissions: FC<PulsesUsersPermissions> = ({ _id }) => {
  const [opened, { open, close }] = useDisclosure(false)

  const onClick = () => {
    open()
  }

  return (
    <>
      {opened ? (
        <PulsesAddUserModal _id={_id} back={close} />
      ) : (
        <Stack pt="xs">
          <UnstyledButton onClick={onClick}>
            <Group>
              <Avatar color="blue">
                <UserPlus />
              </Avatar>
              <Text size="sm" weight={500}>
                Add people
              </Text>
            </Group>
          </UnstyledButton>

          <ScrollArea h={180} offsetScrollbars scrollbarSize={4}>
            <PulseTargetUsersList _id={_id} />
          </ScrollArea>
        </Stack>
      )}
    </>
  )
}
