// mantine
import { createStyles } from '@mantine/core'
// shared styles
import { borderColorStyles } from 'shared'

export default createStyles((theme) => ({
  section: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRight: `1px solid ${borderColorStyles(theme)}`,
    padding: `0 ${theme.spacing.md}`,
  },
  logo: {
    fontSize: '24px',
    fontWeight: 'bold',
    // color: '#031d6b',
    color: theme.colorScheme === 'light' ? theme.colors.dark[7] : theme.colors.blue[4],
    letterSpacing: '-1px',
    fontFamily: `"IBM Plex Sans", sans-serif`,
  },
  main: {
    textTransform: 'uppercase',
  },
  aside: {
    color: '#0168fa',
  },
}))
