import { createStyles } from '@mantine/core'

const useStyles = createStyles((theme) => ({
  wrapper: {
    margin: '50px',

    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      margin: '15px',
    },
  },
}))

export default useStyles
