// packages
import { Dispatch, FC, SetStateAction } from 'react'
import NextLink from 'next/link'
// mantine
import { ActionIcon, Anchor, Group } from '@mantine/core'
import { useLocalStorage } from '@mantine/hooks'
// Components
import { Icon, IconProps } from 'Components'
// localStorage
import { leftSideBarLocalStorageProps } from 'localStorage'
// styles
import useStyles from './styles'

type LinkProps = {
  slug: string
  icon: IconProps['name']
  name: string
  setDrawerOpened: Dispatch<SetStateAction<boolean>>
}

export type LeftSideBarContentDemoLinksProps = {
  links: Omit<LinkProps, 'setDrawerOpened'>[]
  setDrawerOpened: Dispatch<SetStateAction<boolean>>
}

export const LeftSideBarContentDemoLinks: FC<LeftSideBarContentDemoLinksProps> = ({
  links,
  setDrawerOpened,
}) => {
  const { classes } = useStyles()
  const [expanded] = useLocalStorage<boolean>(leftSideBarLocalStorageProps)

  return (
    <>
      {links.map(({ slug, icon, name }, i) => (
        <Anchor
          key={i}
          className={classes.anchor}
          underline={false}
          component={NextLink}
          onClick={() => setDrawerOpened(false)}
          href={slug}
        >
          <Group spacing={4} className={classes.link}>
            <ActionIcon variant="transparent">
              <Icon name={icon} />
            </ActionIcon>

            {expanded && name}
          </Group>
        </Anchor>
      ))}
    </>
  )
}
