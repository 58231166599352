// packages
import { FC } from 'react'
// mantine
import { MultiSelect, MultiSelectProps } from '@mantine/core'
// props
import { BaseInputProps } from './baseInputProps'

/**
 * Props for MultiSelectInput Component
 */
export type MultiSelectInputWrapperProps = { props: MultiSelectProps } & BaseInputProps

/**
 * Wrapper for MultiSelect Component
 *
 * @see [Mantine MultiSelect](https://mantine.dev/core/multi-select/)
 */
export const MultiSelectInputWrapper: FC<MultiSelectInputWrapperProps> = ({
  form,
  fieldName,
  props,
}) => {
  return (
    <MultiSelect
      styles={{
        label: { marginBottom: 5 },
      }}
      {...props}
      {...form.getInputProps(fieldName)}
    />
  )
}
