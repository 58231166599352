// mantine
import { Box, Card, Flex, Group, Skeleton, Stack } from '@mantine/core'

export const ElephantFeedPodsDeviceStatusCardSkeleton = () => {
  return (
    <Card withBorder radius="md" h={141}>
      <Flex justify="space-between">
        <Flex direction="column" gap="md">
          <Box>
            <Group spacing={8}>
              <Skeleton h="1rem" w={20} />
              <Skeleton h="1rem" w={60} />
            </Group>

            <Box h="2rem" mt="md">
              <Skeleton h="1rem" w={80} />
            </Box>
          </Box>

          <Flex gap={5}>
            <Skeleton h="1.25rem" w={20} />
            <Skeleton h="1.25rem" w={20} />
          </Flex>
        </Flex>
        <Stack spacing="sm">
          <Skeleton h="1.75rem" w={150} />

          <Skeleton h="1.75rem" w={150} />
        </Stack>
      </Flex>
    </Card>
  )
}
