// packages
import { FC } from 'react'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
// mantine
import { Button } from '@mantine/core'
import { useHotkeys } from '@mantine/hooks'
// types
import { CalendarProps } from '../types'
// icons
import { ChevronLeft, ChevronRight } from 'tabler-icons-react'

export type CalendarDayControlsProps = Pick<CalendarProps, 'date' | 'setDate'>

export const CalendarDayControls: FC<CalendarDayControlsProps> = ({ date, setDate }) => {
  dayjs.extend(isToday)

  const prevDay = () => setDate((date) => date.add(-1, 'd'))
  const nextDay = () => setDate((date) => date.add(1, 'd'))

  useHotkeys([
    ['ArrowLeft', prevDay],
    ['ArrowRight', nextDay],
  ])
  return (
    <Button.Group>
      <Button variant="default" size="xs" onClick={prevDay}>
        <ChevronLeft size={16} />
      </Button>
      <Button
        variant="default"
        size="xs"
        onClick={() => setDate(dayjs())}
        disabled={date.isToday()}
      >
        Today
      </Button>
      <Button variant="default" size="xs" onClick={nextDay}>
        <ChevronRight size={16} />
      </Button>
    </Button.Group>
  )
}
