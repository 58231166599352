// packages
import { gql } from '@apollo/client'

export const GET_BUTTON_WITH_MENU_QUERY = gql`
  query GetButtonWithMenu($_id: ObjectId!) {
    getButtonWithMenu(_id: $_id) {
      _id
      props
      main {
        _id
      }
      menu {
        _id
      }
    }
  }
`
