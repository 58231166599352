// mantine
import { Grid, Stack } from '@mantine/core'
// Containers
import { ComponentContainer } from 'Containers'
import { CSBDeviceCard } from './DeviceCard'
import { CSBUpcomingSchedule } from './UpcomingSchedule'
import { CSBFootageCarousel } from './FootageCarousel'

export const CSBContainer = () => {
  return (
    <Stack>
      <CSBDeviceCard />
      <CSBUpcomingSchedule />
      <Grid>
        <Grid.Col sm={12} md={6}>
          <ComponentContainer
            _id={
              process.env.NODE_ENV === 'production'
                ? '6411770e445eacb9cfa7db11'
                : '63fb901ef0660328536f0502'
            }
            height={300}
          />
        </Grid.Col>

        <Grid.Col sm={12} md={6}>
          <ComponentContainer
            _id={
              process.env.NODE_ENV === 'production'
                ? '63fc4c59cf7bd8b83e7cbf9c'
                : '63fb901ef0660328536f04d4'
            }
            height={300}
            params={{ limit: 10 }}
          />
        </Grid.Col>
      </Grid>
      <CSBFootageCarousel />

      <ComponentContainer
        _id={
          process.env.NODE_ENV === 'production'
            ? '63fc4c5ccf7bd8b83e7cc014'
            : '63fb901ff0660328536f054c'
        }
        height={300}
      />
    </Stack>
  )
}
