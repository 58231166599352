import { MantineTheme } from '@mantine/core'

/**
 * Color for border for different color scheme modes
 *
 * @param theme - mantine theme
 *
 * @returns return color of border for different color scheme modes
 */
export const borderColorStyles = (theme: MantineTheme): string =>
  theme.colorScheme === 'light' ? theme.colors.gray[3] : theme.colors.dark[6]
