// packages
import { extent, max, min } from 'd3-array'
import * as allCurves from '@visx/curve'
import { Group } from '@visx/group'
import { LinePath } from '@visx/shape'
import { scaleTime, scaleLinear } from '@visx/scale'
import { DateValue } from '@visx/mock-data/lib/generators/genDateValue'
// mantine
import { useElementSize } from '@mantine/hooks'

export type CurveProps = { height: number; values: number[] }

export const DataSparkLine = ({ height, values }: CurveProps) => {
  const { ref, width: innerWidth } = useElementSize()

  const startDateMs = Date.now()
  const data = new Array(values.length).fill(1).map((_, idx: number) => ({
    date: new Date(startDateMs - idx * 3600000),
    value: values[idx],
  }))

  // data accessors
  const getX = (d: DateValue) => d.date
  const getY = (d: DateValue) => d.value

  // scales
  const xScale = scaleTime<number>({
    domain: extent(data, getX) as [Date, Date],
  })
  const yScale = scaleLinear<number>({
    zero: false,
    domain: [max(data, getY) as number, min(data, getY) as number],
  })

  xScale.range([innerWidth, 0])
  yScale.range([10, height - 2])

  return (
    <>
      <svg width="100%" height={height} ref={ref}>
        <Group>
          <LinePath<DateValue>
            curve={allCurves['curveLinear']}
            data={data}
            x={(d) => xScale(getX(d)) ?? 0}
            y={(d) => yScale(getY(d)) ?? 0}
            stroke="#9db2c6"
            shapeRendering="geometricPrecision"
            strokeWidth={2}
          />
        </Group>
      </svg>
    </>
  )
}
