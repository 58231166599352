// packages
import { FC } from 'react'
import { useQuery } from '@apollo/client'
// mantine
import { Modal } from '@mantine/core'
// gql
import { GET_FORM_MODAL_QUERY } from 'gql'
// Components
import { FormContainer } from 'Containers'

type ModalFormContainerProps = {
  _id: string
  opened: boolean
  onClose: () => void
  centered?: boolean
  fullScreen: boolean
}

export const ModalFormContainer: FC<ModalFormContainerProps> = ({
  _id,
  opened,
  onClose,
  centered = true,
  fullScreen,
}) => {
  const { data, loading, error } = useQuery(GET_FORM_MODAL_QUERY, {
    variables: { _id },
  })

  const onSubmitCallback = () => {
    onClose()
  }

  if (data) {
    const { getFormModal } = data

    if (getFormModal) {
      const {
        props,
        form: { _id: formId, title },
        ...rest
      } = getFormModal

      return (
        <Modal opened={opened} onClose={onClose} title={title} centered={centered} {...props}>
          <FormContainer _id={formId} onSubmitCallback={onSubmitCallback} isModal />
        </Modal>
      )
    }
  }

  return <></>
}
