// packages
import React, { ReactElement, ReactNode } from 'react'
import { NextPage } from 'next'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { DefaultSeo } from 'next-seo'
import { ApolloProvider } from '@apollo/client'
// lib
import { useApollo } from 'lib/apolloClient'
// mantine
import { MantineProvider, ColorSchemeProvider, ColorScheme } from '@mantine/core'
import { useHotkeys, useLocalStorage } from '@mantine/hooks'
import { Notifications } from '@mantine/notifications'
import { ModalsProvider } from '@mantine/modals'
import { SpotlightProvider } from '@mantine/spotlight'
// constants
import { getSpotlightActions } from '../constants'
// seo
import SEO from 'next-seo.config'
// styles
import { AppStyles, theme } from 'theme'
// icons
import { Search } from 'tabler-icons-react'

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

export default function App(props: AppPropsWithLayout) {
  const apolloClient = useApollo(props)
  const { Component, pageProps } = props

  const router = useRouter()

  const getLayout = Component.getLayout ?? ((page) => page)

  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'mantine-color-scheme',
    defaultValue: 'light',
    getInitialValueInEffect: true,
  })

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'))

  useHotkeys([['mod+/', () => toggleColorScheme()]])

  const spotlightActions = getSpotlightActions(router)

  return (
    <>
      <DefaultSeo {...SEO} />
      <Head>
        <meta name="author" content="ZP" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />
      </Head>

      <ApolloProvider client={apolloClient}>
        <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
          <MantineProvider theme={{ ...theme, colorScheme }} withGlobalStyles withNormalizeCSS>
            <ModalsProvider>
              <SpotlightProvider
                actions={spotlightActions}
                searchIcon={<Search size="1.2rem" />}
                searchPlaceholder="Search..."
                shortcut="mod + k"
                nothingFoundMessage="Nothing found..."
              >
                <Notifications />
                <AppStyles />
                {getLayout(<Component {...pageProps} />)}
              </SpotlightProvider>
            </ModalsProvider>
          </MantineProvider>
        </ColorSchemeProvider>
      </ApolloProvider>
    </>
  )
}
