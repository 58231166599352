// packages
import { gql } from '@apollo/client'

export const GET_CURRENT_USER_QUERY = gql`
  query GetCurrentUser {
    getCurrentUser {
      _id
      name
      email
      token
    }
  }
`
