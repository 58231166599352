// mantine
import { createStyles } from '@mantine/core'
import { borderColorStyles } from 'shared'

export default createStyles((theme) => ({
  header: {
    backgroundColor: theme.colorScheme === 'light' ? theme.white : theme.colors.dark[7],
    borderBottom: `1px solid ${borderColorStyles(theme)}`,
    position: 'sticky',
    display: 'flex',
    top: 0,
    left: 0,
    zIndex: 9,
  },
}))
