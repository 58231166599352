// packages
import { FC } from 'react'
// mantine
import { Box, Card, Center, Flex, Loader, ScrollArea, Text } from '@mantine/core'
// Component
import { Activations, ActivationsProps } from 'Components'
import { ActivationsCardLastActivityIndicator } from './LastActivityIndicator'

export type ActivationsCardProps = {
  activations: ActivationsProps['activations']
  title: string
  height?: number
  params?: object
  props?: any
}

export const ActivationsCard: FC<ActivationsCardProps> = ({
  activations,
  title,
  height,
  params: _params,
  props,
}) => {
  const headerHeight = 38
  // TODO: override title from props here
  // const { title } = props
  const scrollAreaHeight = height ? height - headerHeight : 400

  const recentActivation = activations ? activations[0] : undefined

  return (
    <Card style={{ height }} withBorder p={0}>
      <Box p="sm" style={{ height: headerHeight, overflow: 'hidden' }}>
        <Flex justify="space-between" align="center">
          <Text size="sm" weight="bold">
            {title}
          </Text>

          <ActivationsCardLastActivityIndicator recentActivation={recentActivation} />
        </Flex>
      </Box>
      {activations ? (
        <ScrollArea scrollbarSize={5} style={{ height: scrollAreaHeight }}>
          <Box p="sm">
            <Activations activations={activations} props={props} />
          </Box>
        </ScrollArea>
      ) : (
        <Center style={{ height }}>
          <Loader />
        </Center>
      )}
    </Card>
  )
}
