// packages
import { FC, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
// Components
import { Calendar, CalendarEvent, CalendarProps } from 'Components'

export type CalendarContainerProps = {
  date?: string
  view?: CalendarProps['view']
}

export const CalendarContainer: FC<CalendarContainerProps> = ({
  date,
  view: defaultView = 'Month',
}) => {
  // TODO: useQuery here with variables for range type and date
  const [now, setNow] = useState<Dayjs>(date ? dayjs(date) : dayjs())
  const [view, setView] = useState<CalendarProps['view']>(defaultView)

  const events: CalendarEvent[] = [
    {
      start: '2023-06-02T09:31:41.922+00:00',
      end: '2023-06-02T12:31:41.922+00:00',
      title: 'CSB Feed 1',
      type: 'Event',
    },
    {
      start: '2023-06-02T09:31:41.922+00:00',
      end: '2023-06-02T12:31:41.922+00:00',
      title: 'CSB Feed 1',
      type: 'Event',
    },
    {
      start: '2023-06-02T09:31:41.922+00:00',
      end: '2023-06-02T12:31:41.922+00:00',
      title: 'CSB Feed 1',
      type: 'Event',
    },
    {
      start: '2023-06-02T09:31:41.922+00:00',
      end: '2023-06-02T12:31:41.922+00:00',
      title: 'CSB Feed 1',
      type: 'Event',
    },
    {
      start: '2023-06-02T09:31:41.922+00:00',
      end: '2023-06-02T12:31:41.922+00:00',
      title: 'CSB Feed 1',
      type: 'Event',
    },
    {
      start: '2023-06-02T09:31:41.922+00:00',
      end: '2023-06-02T12:31:41.922+00:00',
      title: 'CSB Feed 1',
      type: 'Event',
    },
    {
      start: '2023-06-02T09:31:41.922+00:00',
      end: '2023-06-02T12:31:41.922+00:00',
      title: 'CSB Feed 1',
      type: 'Event',
    },
    {
      start: '2023-06-02T09:31:41.922+00:00',
      end: '2023-06-02T12:31:41.922+00:00',
      title: 'CSB Feed 1',
      type: 'Event',
    },
    {
      start: '2023-06-02T09:31:41.922+00:00',
      end: '2023-06-02T12:31:41.922+00:00',
      title: 'CSB Feed 1',
      type: 'Event',
    },
    {
      start: '2023-06-02T09:31:41.922+00:00',
      end: '2023-06-02T12:31:41.922+00:00',
      title: 'CSB Feed 1',
      type: 'Event',
    },
    {
      start: '2023-06-03T09:31:41.922+00:00',
      end: '2023-06-03T12:31:41.922+00:00',
      title: 'CSB Feed 1',
      type: 'Event',
    },
  ]

  return <Calendar date={now} setDate={setNow} events={events} view={view} setView={setView} />
}
