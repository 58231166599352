// packages
import { useMemo } from 'react'
import merge from 'deepmerge'
import { isEqual } from 'lodash'
// apollo
import { ApolloClient, HttpLink, InMemoryCache, from, NormalizedCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
// import { concatPagination } from '@apollo/client/utilities'
// localStorage
import { getAuthToken } from 'localStorage'
// shared
import { isNotBrowser } from 'shared'

// TODO: refactor this file into smaller parts

const NEXT_PUBLIC_GRAPHQL_URL = process.env.NEXT_PUBLIC_GRAPHQL_URL
if (!NEXT_PUBLIC_GRAPHQL_URL) throw new Error('NEXT_PUBLIC_GRAPHQL_URL is undefined.')

/**
 * default constant to fix apollo's state name
 * @ignore
 */
export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

let apolloClient: ApolloClient<NormalizedCache>

// handle gql errors
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
    )
  if (networkError) console.log(`[Network error]: ${networkError}`)
})

// get auth token
const getAuthorizationHeader = () => (isNotBrowser() ? '' : `Bearer user-${getAuthToken()}`)

// httpLink for gql config
const httpLink = new HttpLink({
  uri: NEXT_PUBLIC_GRAPHQL_URL, // Server URL (must be absolute)
})

const authLink = setContext((_, { headers }) => {
  return {
    headers: { ...headers, authorization: getAuthorizationHeader() },
    credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
  }
})

// function to return an apolloClient with configurations
const createApolloClient = () => {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: from([errorLink, authLink, httpLink]),
    defaultOptions: {
      mutate: {
        errorPolicy: 'all',
      },
    },
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {},
        },
      },
    }),
  })
}

/**
 * function to init apollo client for use in the application
 *
 * @param initialState -  initial value of apollo's internal state
 *
 * @returns an apollo client
 *
 * @category Apollo
 */
export const initializeApollo = (initialState = null) => {
  const _apolloClient = apolloClient ?? createApolloClient()

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d: Record<any, any>) =>
          sourceArray.every((s: Record<any, any>) => !isEqual(d, s)),
        ),
      ],
    })

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

/**
 * PageProps is type of value prop passed in from different `NextJS` pages
 *
 * @category Apollo
 */
export type PageProps = Record<any, any>

/**
 * add apollo cache into the `pageProps` param if the props `attribute` exists in `pageProps`
 *
 * @param client - an apollo client
 * @param pageProps - props passed in from `NextJS` page
 *
 * @returns the same `pageProps` param, with a new the apollo cache added to `props[APOLLO_STATE_PROP_NAME]`
 *
 * @category Apollo
 */
export const addApolloState = (client: ApolloClient<NormalizedCache>, pageProps: PageProps) => {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return pageProps
}

/**
 * hook to get the apollo client
 *
 * @param pageProps - props passed in from `NextJS` page
 *
 * @returns a hook to get apollo client
 *
 * @category Apollo
 */
export const useApollo = (pageProps: PageProps) => {
  const state = pageProps[APOLLO_STATE_PROP_NAME]
  const store = useMemo(() => initializeApollo(state), [state])
  return store
}
