// mantine
import { createStyles } from '@mantine/core'

export default createStyles((theme) => ({
  link: {
    margin: 0,
  },
  anchor: {
    color: theme.colorScheme === 'light' ? '#1b2e4b' : theme.colors.gray[2],
    transition: 'all 0.25s',
    fontSize: 13,
    fontWeight: 400,
    fontFamily: `"IBM Plex Sans", sans-serif`,
    '&:hover': {
      textDecoration: 'none',
    },
  },
}))
