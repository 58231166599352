// packages
import { FC } from 'react'
// mantine
import { Box, ScrollArea } from '@mantine/core'
import { useLocalStorage } from '@mantine/hooks'
// hooks
import { useIsMobile } from 'hooks'
// Components
import { LeftSideBarContent } from './Content'
// localStorage
import { leftSideBarLocalStorageProps } from 'localStorage'
// styles
import useStyles from './styles'

type AppLeftSideBarProps = {
  width: number
  height: string | number
}

export const AppLeftSideBar: FC<AppLeftSideBarProps> = ({ width, height }) => {
  const { classes } = useStyles()
  const mobile = useIsMobile()
  const [expanded] = useLocalStorage<boolean>(leftSideBarLocalStorageProps)

  if (mobile) return <></>

  return (
    <div className={classes.leftSideBar}>
      <ScrollArea
        type="scroll"
        scrollHideDelay={200}
        scrollbarSize={3}
        offsetScrollbars
        style={{ height }}
      >
        <Box py="xl" pl={expanded ? 'xl' : 'md'} pr="xs" style={{ width: width - 3 - 1 }}>
          <LeftSideBarContent setDrawerOpened={() => {}} />
        </Box>
      </ScrollArea>
    </div>
  )
}
