// packages
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
// mantine
import { Box, Card, Flex, ScrollArea, Text } from '@mantine/core'
import { DatePicker } from '@mantine/dates'
// Components
import { ScheduleDatePicker } from 'Components'
import { CSBUpcomingActivationsByDate } from './Activations'
// hooks
import { useIsMobile } from 'hooks'

export const CSBUpcomingSchedule = () => {
  const [start, setStart] = useState<Date>(new Date())
  const [end, setEnd] = useState<Date>(dayjs(start).endOf('d').toDate())
  const isMobile = useIsMobile()

  const sourceId =
    process.env.NODE_ENV == 'production' ? '63fc4c59cf7bd8b83e7cbf49' : '63fb901ef0660328536f0481'

  // TODO: debounced is needed here because the update is too fast for start & end and is sending unnecessary gql query
  useEffect(() => {
    setEnd(dayjs(start).endOf('d').toDate())
  }, [start])

  return (
    <>
      <Card withBorder radius="md">
        <Text size="lg" weight={500}>
          Schedule
        </Text>
        <Flex direction={{ base: 'column', md: 'row' }}>
          {!isMobile && (
            <Box w={300}>
              <DatePicker
                value={start}
                //@ts-expect-error: null for date
                onChange={setStart}
                excludeDate={(date) => dayjs().add(-1, 'd').isAfter(date)}
              />
            </Box>
          )}
          <Box style={{ flex: 1 }}>
            <ScheduleDatePicker date={start} setDate={setStart} />
            <ScrollArea
              type="auto"
              scrollHideDelay={200}
              scrollbarSize={3}
              offsetScrollbars
              h={240}
            >
              <CSBUpcomingActivationsByDate
                start={dayjs().isSame(start, 'd') ? new Date() : start}
                end={end}
                sourceId={sourceId}
              />
            </ScrollArea>
          </Box>
        </Flex>
      </Card>
    </>
  )
}
