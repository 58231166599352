// packages
import { FC } from 'react'
// Containers
import { ResetPasswordForm } from 'Containers'

type ResetPasswordPageContainerProps = { token: string }

export const ResetPasswordPageContainer: FC<ResetPasswordPageContainerProps> = ({ token }) => {
  return (
    <div>
      <ResetPasswordForm token={token} />
    </div>
  )
}
