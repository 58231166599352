// packages
import dayjs from 'dayjs'
// mantine
import { Flex, Stack, Text } from '@mantine/core'
// styles
import useStyles from '../styles'

export const CSBDateStack = ({ activate, setOpened }: { activate: any; setOpened: any }) => {
  const { classes, cx } = useStyles()
  const onClick = async (at: string) => {
    const sourceId =
      process.env.NODE_ENV == 'production' ? '63fc4c59cf7bd8b83e7cbf4d' : '63fb901ef0660328536f0485'

    const body = {
      topic:
        process.env.NODE_ENV === 'production' ? 'cmd/activate/zoo/malayantiger/csb' : 'zp_test',
      params: { devices: ['1'], iottype: 'feed', auth: 'test_token', at },
    }

    const { data } = await activate({
      variables: {
        sourceId,
        data: body,
      },
    })

    if (data.activateSource) setOpened(false)
  }

  // generate dates
  const genOptions = () => {
    const date = dayjs() // get current time
    const hour = date.get('h')

    if (hour < 8) {
      // 0 to 8am
      return [
        {
          label: 'This morning',
          date: dayjs().startOf('d').set('h', 8),
        },
        {
          label: 'This noon',
          date: dayjs().startOf('d').set('h', 13),
        },
        {
          label: 'This evening',
          date: dayjs().startOf('d').set('h', 19),
        },
      ]
    } else if (hour < 13) {
      // 8am to 1pm
      return [
        {
          label: 'This noon',
          date: dayjs().startOf('d').set('h', 13),
        },
        {
          label: 'This evening',
          date: dayjs().startOf('d').set('h', 19),
        },
        {
          label: 'Tomorrow morning',
          date: dayjs().startOf('d').add(1, 'd').set('h', 8),
        },
      ]
    } else if (hour < 19) {
      // 1pm to 7pm
      return [
        {
          label: 'This evening',
          date: dayjs().startOf('d').set('h', 19),
        },
        {
          label: 'Tomorrow morning',
          date: dayjs().startOf('d').add(1, 'd').set('h', 8),
        },
        {
          label: 'Tomorrow noon',
          date: dayjs().startOf('d').add(1, 'd').set('h', 13),
        },
      ]
    } else {
      // after 7pm
      return [
        {
          label: 'Tomorrow morning',
          date: dayjs().startOf('d').add(1, 'd').set('h', 8),
        },
        {
          label: 'Tomorrow noon',
          date: dayjs().startOf('d').add(1, 'd').set('h', 13),
        },
        {
          label: 'Tomorrow evening',
          date: dayjs().startOf('d').add(1, 'd').set('h', 19),
        },
      ]
    }
  }

  return (
    <Stack spacing={0}>
      {genOptions().map(({ label, date }, i) => (
        <Flex
          justify="space-between"
          px="lg"
          py="sm"
          onClick={() => onClick(date.toISOString())}
          className={cx(classes.dateTimeOption, { [classes.hintDateTimeOption]: i == 0 })}
          key={i}
        >
          <Text>{label}</Text>
          <Text>{date.format('MMM D, h:mm A')}</Text>
        </Flex>
      ))}
      <Flex
        justify="space-between"
        px="lg"
        py="sm"
        onClick={() => onClick(dayjs().toISOString())}
        className={classes.dateTimeOption}
      >
        <Text>Now</Text>
        <Text>{dayjs().format('MMM D, h:mm A')}</Text>
      </Flex>
    </Stack>
  )
}
