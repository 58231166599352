import { gql } from '@apollo/client'

export const SET_CSB_TIGER_RECOGNITION_VIDEO_MUTATION = gql`
  mutation SetCSBTigerRecognitionVideo($_id: ObjectId!, $hasTiger: Boolean!) {
    setCSBTigerRecognitionVideo(_id: $_id, hasTiger: $hasTiger) {
      _id
      hasTiger
    }
  }
`
