import { gql } from '@apollo/client'

export const GET_SOURCE_QUERY = gql`
  query GetSource($_id: ObjectId!) {
    getSource(_id: $_id) {
      _id
      name
    }
  }
`
