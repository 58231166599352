// packages
import { FC } from 'react'
import Link from 'next/link'
// mantine
import { Button, ButtonProps } from '@mantine/core'

type ButtonLinkProps = {
  label: string
  url: string
  newTab: boolean
  props: ButtonProps
}

export const ButtonLink: FC<ButtonLinkProps> = ({ label, url, newTab, props }) => {
  return (
    <Button href={url} component={Link} target={newTab ? '' : '_blank'} {...props}>
      {label}
    </Button>
  )
}
