import { gql } from '@apollo/client'

export const GET_CSB_FEEDS_QUERY = gql`
  query GetCSBFeeds($start: DateTime!, $end: DateTime!) {
    getCSBFeeds(start: $start, end: $end) {
      created
      location
    }
  }
`
