// packages
import { useForm } from '@mantine/form'

export const useResetPasswordForm = (token: string) => {
  const form = useForm({
    initialValues: {
      token,
      password: '',
      passwordConfirmation: '',
    },

    validate: {
      passwordConfirmation: (value, values) =>
        value != values.password ? 'Passwords do not match' : null,
    },
  })

  return form
}
