// packages
import { FC } from 'react'
// mantine
import { Box, Button, Card, Flex, Group, Stack, Text } from '@mantine/core'
// Components
import { Icon, ResponsiveDrawerMenu } from 'Components'
import { DeviceStatusBadge } from './StatusBadge'
import { RemainingFeedAndTemperatureData } from './RemainingFeedAndTemperatureData'
import { CSBLast24Hours } from './Last24Hours'
import { CSBScheduleFeedButton } from './ScheduleFeedButton'
// hooks
import { useIsMobile } from 'hooks'
// icons
import { ChevronDown } from 'tabler-icons-react'

export type CSBDeviceCardProps = {}

export const CSBDeviceCard: FC<CSBDeviceCardProps> = ({}) => {
  const isMobile = useIsMobile()

  return (
    <Card withBorder radius="md">
      <Flex
        align="flex-start"
        direction={{ base: 'column', md: 'row' }}
        justify="space-between"
        gap="lg"
      >
        <Flex direction={{ base: 'column', md: 'row' }}>
          <Box>
            <Group>
              <Text size="xl" weight={500} color="default">
                Carnivore Smart Box
              </Text>

              <Icon name="DeviceHeartMonitor" size={24} strokeWidth={1} />

              <DeviceStatusBadge />
            </Group>

            <Text size="sm" color="dimmed" mt={5}>
              Trial smart feeder system for the tigers in Night Safari.
            </Text>

            <CSBLast24Hours />
          </Box>
        </Flex>

        <Group>
          <RemainingFeedAndTemperatureData />
        </Group>

        <Stack w={isMobile ? '100%' : 'inherit'} mt={isMobile ? 'md' : ''}>
          <CSBScheduleFeedButton />
          <ResponsiveDrawerMenu
            drawerTitle="CSB Menu"
            TargetComponent={
              <Button variant="subtle" rightIcon={<ChevronDown />}>
                More Actions
              </Button>
            }
            links={[
              { label: 'Activate', type: 'title' },
              {
                label: 'Light',
                iconName: 'Bulb',
                modalId: '63fc4c59cf7bd8b83e7cbf66',
                redirectType: 'modal',
              },
              {
                label: 'Sound',
                iconName: 'Speakerphone',
                modalId: '63fc4c59cf7bd8b83e7cbf82',
                redirectType: 'modal',
              },
              {
                label: 'Scent',
                iconName: 'Wifi',
                modalId: '63fc4c59cf7bd8b83e7cbf72',
                redirectType: 'modal',
              },
              { label: 'Settings', type: 'title' },
              {
                label: 'Lock/Unlock',
                iconName: 'LockAccess',
                modalId: '63fc4c59cf7bd8b83e7cbf8e',
                redirectType: 'modal',
              },
              {
                label: 'Reset Feed',
                iconName: 'Refresh',
                modalId: '63fd60d01c2688d4e1898f25',
                redirectType: 'modal',
              },
              { label: 'View', type: 'title' },
              {
                label: 'Overview',
                iconName: 'LayoutDashboard',
                redirectType: 'href',
                href: '/dashboard/carnivore-smart-box',
              },
              {
                label: 'Chiller Analysis',
                iconName: 'Temperature',
                redirectType: 'href',
                href: '/dashboard/carnivore-smart-box/chiller',
              },
            ]}
          />
        </Stack>
      </Flex>
    </Card>
  )
}
