// packages
import { useState } from 'react'
import Link from 'next/link'
// mantine
import { ActionIcon, Anchor, Box, Drawer, Group } from '@mantine/core'
import { useLocalStorage } from '@mantine/hooks'
// localStorage
import { leftSideBarLocalStorageProps } from 'localStorage'
// styles
import useStyles from './styles'
// hooks
import { useIsMobile } from 'hooks'
// Components
import { LeftSideBarContent } from '../../LeftSideBar/Content'
// icons
import { Menu2 } from 'tabler-icons-react'

export const HeaderTitle = () => {
  const [expanded, setExpanded] = useLocalStorage<boolean>(leftSideBarLocalStorageProps)
  const [opened, setOpened] = useState(false)

  const { classes } = useStyles()

  const mobile = useIsMobile()
  // const onClick = () => setExpanded(!expanded)
  const onClick = () => {
    mobile ? setExpanded(true) : setExpanded(!expanded)
    opened ? setOpened(false) : mobile && setOpened(true)
  }

  return (
    <div className={classes.section}>
      {(expanded || mobile) && (
        <Anchor href="/" underline={false} component={Link}>
          <Group spacing={3} className={classes.logo}>
            <div className={classes.main}>Munin</div>
            <span className={classes.aside}>beta</span>
          </Group>
        </Anchor>
      )}

      <ActionIcon variant="transparent" onClick={onClick}>
        <Menu2 />
      </ActionIcon>

      <Drawer opened={opened} onClose={() => setOpened(false)} title="" size="md" padding="md">
        <Box pt={10}>
          <LeftSideBarContent setDrawerOpened={setOpened} />
        </Box>
      </Drawer>
    </div>
  )
}
