// packages
import { gql } from '@apollo/client'

export const GET_ACTIVATIONS_BY_DATE_QUERY = gql`
  query GetActivationsByDate($date: String!, $sourceId: ObjectId!) {
    getActivationsByDate(date: $date, sourceId: $sourceId) {
      _id
      status
      requestBody
      createdAt
      scheduledAt
      user {
        _id
        name
      }
    }
  }
`
