// mantine
import { Text, SimpleGrid, Stack } from '@mantine/core'
// Components
import { ElephantFeedPodsDeviceStatusCard } from './DeviceStatusCard'

export const ElephantFeedPodsDashboardContainer = () => {
  return (
    <Stack>
      <Text size="lg" weight={500}>
        Elephant Feed Pods (Beta)
      </Text>

      <SimpleGrid cols={2} breakpoints={[{ maxWidth: '48rem', cols: 1, spacing: 'sm' }]}>
        {[
          '351580050933932',
          '351580050932728',
          '351580050927124',
          '351580050916952',
          '351580050907027',
          '351580050927736',
        ].map((id) => (
          <ElephantFeedPodsDeviceStatusCard id={id} key={id} />
        ))}
      </SimpleGrid>
    </Stack>
  )
}
