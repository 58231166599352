// packages
import { FC, ReactNode } from 'react'
// mantine
import { Text, TextProps } from '@mantine/core'
// styles
import useStyles from './useStyles'

/**
 * Props for {@link Title} Component
 */
export type TitleProps = { children: ReactNode } & TextProps

/**
 * Title Component
 *
 * A Header like section with default heading styling.
 */
export const Title: FC<TitleProps> = ({ children, ...props }) => {
  const { classes } = useStyles()

  return (
    <Text size={21} weight={500} className={classes.title} {...props}>
      {children}
    </Text>
  )
}
