// packages
import { FC, useState } from 'react'
import { useQuery } from '@apollo/client'
import dayjs from 'dayjs'
// mantine
import { Box, Card, Center, Grid, Loader, Text } from '@mantine/core'
// gql
import { GET_CSB_TIGER_RECOGNITION_VIDEO_URL, GET_VIDEO_PLAYER_COMPONENT_QUERY } from 'gql'
// Containers
import { TimeRangeDisplay } from './TimeRangeDisplay'
import { SelectVideoFilename } from './SelectVideoFilename'
import { VideoPlayerDataLayer } from './DataLayer'

export type VideoPlayerContainerProps = {
  _id: string
  height?: number
  params?: object
}

export const VideoPlayer = ({ _id, height }: { _id: string; height?: number }) => {
  const { data, loading, error } = useQuery(GET_CSB_TIGER_RECOGNITION_VIDEO_URL, {
    variables: { _id },
  })
  /* const { data, loading, error } = useQuery(GET_CSB_BUCKET_QUERY, {
    variables: {
      filename,
    },
  }) */

  if (data)
    if (data.getCSBTigerRecognitionVideoURL) {
      return (
        <video src={data.getCSBTigerRecognitionVideoURL} controls height={height} width="100%" />
      )
      {
        /* poster="https://via.placeholder.com/280x150?text=video" */
      }
    }

  return (
    <Center style={{ height }}>
      <Loader />
    </Center>
  )
}

export const VideoPlayerCard = ({
  data,
  loading,
  height,
  props,
}: {
  data: any[]
  loading: boolean
  height?: number
  props?: any
}) => {
  const [files, setFiles] = useState([])
  const [_id, setId] = useState<string | null>(null)

  let parsedData: any[] = []
  const { dateField, valueField } = props

  const dateObjs: Record<string, any[]> = {}
  if (data) {
    data.forEach((d) => {
      const date = dayjs(d[dateField]).add(-8, 'h').format('YYYY-MM-DD')
      dateObjs[date] = dateObjs[date]
        ? [...dateObjs[date], { timestamp: d[dateField], [valueField]: d[valueField] }]
        : [{ timestamp: d[dateField], [valueField]: d[valueField] }]
    })

    parsedData = Object.keys(dateObjs).map((key) => ({
      day: key,
      value: dateObjs[key].length,
      data: dateObjs[key],
    }))
  }

  return (
    <Card withBorder p="sm" style={{ height }}>
      <Box p={0}>
        <Text size="sm" weight="bold">
          Motion Sensor Camera
        </Text>
      </Box>
      <Grid style={{ height: 450 }}>
        <Grid.Col md={12} lg={4}>
          <TimeRangeDisplay parsedData={parsedData} height={200} setFiles={setFiles} />
          <SelectVideoFilename files={files} setId={setId} height={250} props={props} />
        </Grid.Col>
        <Grid.Col md={12} lg={8}>
          {_id ? (
            <VideoPlayer height={450} _id={_id} />
          ) : (
            <VideoPlayer
              height={450}
              _id={
                process.env.NODE_ENV === 'production'
                  ? '644e2b97932faae8810d5c31'
                  : '644e2b97932faae8810d5c31'
              }
            />
          )}
        </Grid.Col>
      </Grid>
    </Card>
  )
}

export const VideoPlayerContainer: FC<VideoPlayerContainerProps> = ({ _id, height, params }) => {
  const { data, loading, error } = useQuery(GET_VIDEO_PLAYER_COMPONENT_QUERY, {
    variables: { _id },
  })

  if (data)
    if (data.getVideoPlayerComponent) {
      const {
        _id,
        data: { _id: dataId },
        props,
      } = data.getVideoPlayerComponent

      return (
        <VideoPlayerDataLayer
          Component={VideoPlayerCard}
          dataId={dataId}
          intervalPeriod={15000}
          height={height}
          props={{ ...props, dateField: 'recordedAt', valueField: '_id' }}
          params={params}
        />
      )
    }

  return <>Loading...</>
}
