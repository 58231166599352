import { ActivationType } from 'Components'

export const cleanActivations = (result: any[]) => {
  // example props structure
  const props = {
    icon: {
      key: 'iottype',
      value: {
        feed: 'Meat',
        light: 'BuildingLighthouse',
        sound: 'DeviceSpeaker',
        scent: 'AccessPoint',
        emlock: 'Lock',
        resetfeed: 'CircleDashed',
        default: 'Atom',
      },
    },
    color: {
      key: 'iottype',
      value: {
        feed: 'orange',
        light: 'green',
        sound: 'pink',
        scent: 'blue',
        emlock: 'teal',
        resetfeed: 'red',
        default: 'blue',
      },
    },
  }

  return result.map((activation: any) => {
    // construct data here
    const {
      _id,
      createdAt,
      scheduledAt,
      status,
      requestBody,
      user: { name: username },
    } = activation

    // const activity = constructActivationMessage(username, activation.requestBody)

    const iconName =
      props.icon.value[requestBody.params[props.icon.key] as keyof typeof props.icon.value] ||
      props.icon.value.default

    const color =
      props.color.value[requestBody.params[props.color.key] as keyof typeof props.color.value] ||
      props.color.value.default

    return {
      _id,
      iconName,
      color,
      createdAt,
      scheduledAt,
      status,
      requestBody,
      username,
    } as ActivationType
  })
}
