// packages
import { FC } from 'react'
import Link from 'next/link'
import { useMutation } from '@apollo/client'
// mantine
import {
  Anchor,
  Button,
  Card,
  Container,
  Group,
  LoadingOverlay,
  Text,
  TextInput,
  Title,
} from '@mantine/core'
// hooks
import { useForgetPasswordForm } from './useForgetPasswordForm'
// gql
import { REQUEST_RESET_PASSWORD_EMAIL_MUTATION } from 'gql'

type ForgetPasswordFormProps = {}

export const ForgetPasswordForm: FC<ForgetPasswordFormProps> = () => {
  const form = useForgetPasswordForm()

  const [action, { loading }] = useMutation(REQUEST_RESET_PASSWORD_EMAIL_MUTATION)

  const handleSubmit = async () => {
    const { email } = form.values

    await action({ variables: { email } })

    // TODO: modal/alert for success
    alert(`sent email to ${email}`)
  }

  return (
    <Container size={420} my={40}>
      <Title align="center" sx={(_) => ({ fontWeight: 600 })}>
        Forgot your password?
      </Title>

      <Text color="dimmed" size="sm" align="center" mt={5}>
        Do not have an account yet?{' '}
        <Anchor<'a'>
          href="#"
          size="sm"
          onClick={(event) => event.preventDefault()}
          underline={false}
        >
          Create account
        </Anchor>
      </Text>

      <Card withBorder shadow="md" p={30} mt={30} radius="md">
        <LoadingOverlay visible={loading} />

        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            label="Email"
            placeholder="@mandai.com"
            required
            {...form.getInputProps('email')}
          />

          <Group position="apart" mt="sm">
            <Text color="dimmed" size="sm">
              Remember your password?
            </Text>
            <Anchor size="sm" href="/login" underline={false} component={Link}>
              Login
            </Anchor>
          </Group>

          <Button fullWidth mt="md" type="submit">
            Reset Password
          </Button>
        </form>
      </Card>
    </Container>
  )
}
