// packages
import { FC } from 'react'
import { useQuery } from '@apollo/client'
// mantine
import { Box, Button, Card, Flex, Group, Stack, Text } from '@mantine/core'
// gql
import { GET_ELEPHANT_FEED_PODS_STATUS_BY_DEVICE_ID_QUERY } from 'gql'
// interface
import { IElephantFeedPodsDevice } from '../../elephantFeedPods.interface'
// Components
import { ResponsiveDrawerMenu } from 'Components'
import { ElephantFeedPodsDeviceStatusBadge } from '../StatusBadge'
import { UpcomingFeedTime } from './UpcomingFeedTime'
import { ElephantFeedPodsDeviceStatusCardSkeleton } from './Skeleton'
import { ElephantFeedPodsScheduleButton } from './ScheduleButton'
import { ElephantFeedPodsDeviceRedirectIcon } from './RedirectIcon'
// icons
import { ChevronDown } from 'tabler-icons-react'

export type ElephantFeedPodsDeviceStatusCardProps = {
  id: IElephantFeedPodsDevice['id']
  withFeedPodsLabel?: boolean
}

export const ElephantFeedPodsDeviceStatusCard: FC<ElephantFeedPodsDeviceStatusCardProps> = ({
  id,
  withFeedPodsLabel = false,
}) => {
  const { data, startPolling } = useQuery(GET_ELEPHANT_FEED_PODS_STATUS_BY_DEVICE_ID_QUERY, {
    variables: { id },
  })
  startPolling(60 * 1000)

  if (data) {
    const { getElephantFeedPodsStatusByDeviceId: device } = data
    if (device) {
      return (
        <Card withBorder radius="md">
          <Flex justify="space-between">
            <Flex direction="column" h="full" justify="space-between" gap="md">
              <Box>
                {withFeedPodsLabel && (
                  <Text size="xs" weight={600} color="dimmed" transform="uppercase">
                    Elephant Feedpods
                  </Text>
                )}
                <Group spacing={8}>
                  <ElephantFeedPodsDeviceStatusBadge
                    status={device.status}
                    updated={device.updated}
                  />
                  <Text size="xl" weight={500} color="default">
                    {device.name}
                  </Text>
                </Group>

                <Box h="2rem">
                  <UpcomingFeedTime id={device.id} />
                </Box>
              </Box>

              <Flex gap={3}>
                <ElephantFeedPodsDeviceRedirectIcon id={id} type="Schedule" />
                <ElephantFeedPodsDeviceRedirectIcon id={id} type="Recent_Feeds" />
              </Flex>
            </Flex>
            <Stack spacing="sm">
              <ElephantFeedPodsScheduleButton id={id} name={device.name} />

              <ResponsiveDrawerMenu
                drawerTitle={`${device.name} Menu`}
                TargetComponent={
                  <Button variant="subtle" rightIcon={<ChevronDown />}>
                    More Actions
                  </Button>
                }
                links={[
                  { type: 'title', label: 'Feed' },
                  {
                    label: 'Activate',
                    iconName: 'Meat',
                    redirectType: 'elephantFeedPodsActivation',
                    additionalProps: { id },
                  },
                  { type: 'title', label: 'View' },
                  {
                    label: 'Scheduled Feeds',
                    iconName: 'Calendar',
                    redirectType: 'href',
                    href: `/dashboard/elephant-feedpods/${id}/schedule`,
                  },
                  {
                    label: 'Past Feeds',
                    iconName: 'History',
                    redirectType: 'href',
                    href: `/dashboard/elephant-feedpods/${id}/recent_feeds`,
                  },
                ]}
              />
            </Stack>
          </Flex>
        </Card>
      )
    }
  }

  return <ElephantFeedPodsDeviceStatusCardSkeleton />
}
