// packages
import { FC, ReactNode, useEffect, useState } from 'react'
// mantine
import { Box, ScrollArea } from '@mantine/core'
import { useLocalStorage, useViewportSize } from '@mantine/hooks'
// localStorage
import { leftSideBarLocalStorageProps } from 'localStorage'
// Components
import { AppLayoutHeader } from './Header'
import { AppLeftSideBar } from './LeftSideBar'
// hooks
import { useScreenIsLargerThan } from 'hooks'
// styles
import useStyles from './styles'

type AppLayoutProps = { withoutPadding?: boolean; children: ReactNode }

export const AppLayout: FC<AppLayoutProps> = ({ withoutPadding = false, children }) => {
  const [expanded] = useLocalStorage<boolean>(leftSideBarLocalStorageProps)

  // constants
  const headerHeight = 60
  const [leftSideBarWidth, setLeftSideBarWidth] = useState(expanded ? 240 : 60)

  const { height: h, width: w } = useViewportSize()
  const [height, setHeight] = useState<string | number>('100vh')
  const [contentWidth, setContentWidth] = useState<string | number>(
    `calc(100vw - ${leftSideBarWidth})`,
  )

  const { classes } = useStyles()
  const mobile = !useScreenIsLargerThan('md')

  useEffect(() => {
    setHeight(h - headerHeight)
  }, [h])

  useEffect(() => {
    setLeftSideBarWidth(mobile ? 240 : expanded ? 240 : 60)
  }, [mobile, expanded])

  useEffect(() => {
    mobile ? setContentWidth(w) : setContentWidth(w - leftSideBarWidth)
  }, [mobile, w, leftSideBarWidth])

  return (
    <>
      <AppLayoutHeader height={headerHeight} leftSideBarWidth={leftSideBarWidth} />

      <div className={classes.body} style={{ maxHeight: height, height }}>
        <AppLeftSideBar width={leftSideBarWidth} height={height} />

        <ScrollArea
          type="scroll"
          scrollHideDelay={200}
          scrollbarSize={3}
          offsetScrollbars
          style={{ height }}
        >
          <Box
            className={classes.wrapper}
            sx={(theme) => ({
              width: contentWidth,
              padding: withoutPadding ? 0 : `${theme.spacing.lg} 30px`,
            })}
          >
            {children}
          </Box>
        </ScrollArea>
      </div>
    </>
  )
}
