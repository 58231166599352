// mantine
import { createStyles } from '@mantine/core'

export default createStyles((theme) => ({
  hintDateTimeOption: {
    backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[3] : theme.colors.gray[7],
  },
  dateTimeOption: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[2] : theme.colors.gray[8],
    },
  },
}))
