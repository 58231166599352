// packages
import { FC } from 'react'
// mantine
import { px, useMantineTheme } from '@mantine/core'
// Components
import { ComponentContainer } from 'Containers'

type DashboardGridProps = {
  _id: string
  row: string
  params?: object
}

export const DashboardGrid: FC<DashboardGridProps> = ({ _id, row, params }) => {
  const theme = useMantineTheme()

  const getHeightFromRow = (row: string) => {
    const values = row.split(' / ')
    const diff = parseInt(values[1]) - parseInt(values[0])
    const height = diff * theme.other.gridTemplateRowHeight + (diff - 1) * px(theme.spacing.md)
    return height
  }

  return <ComponentContainer _id={_id} height={getHeightFromRow(row)} params={params} />
}
