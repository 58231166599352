// packages
import { FC } from 'react'
import { useQuery } from '@apollo/client'
// gql
import { GET_SOURCE_QUERY } from 'gql'

export type SourceContainerProps = {
  _id: string
}

export const SourceContainer: FC<SourceContainerProps> = ({ _id }) => {
  const { data, loading, error } = useQuery(GET_SOURCE_QUERY, { variables: { _id } })

  console.log(data)
  if (data) if (data.getSource) return <textarea></textarea>

  return <div>Source {_id}</div>
}
