// packages
import { gql } from '@apollo/client'

export const GET_VIDEO_PLAYER_COMPONENT_QUERY = gql`
  query GetVideoPlayerComponent($_id: ObjectId!) {
    getVideoPlayerComponent(_id: $_id) {
      _id
      props
      data {
        _id
      }
    }
  }
`
