// packages
import dayjs from 'dayjs'
import { useState } from 'react'
import { useQuery } from '@apollo/client'
// mantine
import { Box, Card, Center, Flex, Loader, Text } from '@mantine/core'
import { Carousel } from '@mantine/carousel'
// gql
import { GET_CSB_RECENT_FOOTAGES_QUERY } from 'gql'
// Component
import { CSBFootageCarouselSlide } from './Slide'
// hooks
import { useIsMobile } from 'hooks'
import { CSBFootageDownloadLink } from './DownloadLink'

export const CSBFootageCarousel = () => {
  const isMobile = useIsMobile()

  return (
    <Card withBorder radius="md" p={0} h={isMobile ? '100%' : 300}>
      {!isMobile && (
        <Text size="lg" weight={500} p="md">
          Last 10 Footages
        </Text>
      )}
      <Footages />
    </Card>
  )
}

const Footages = () => {
  const isMobile = useIsMobile()
  const [index, setIndex] = useState(0)

  const { data } = useQuery(GET_CSB_RECENT_FOOTAGES_QUERY, {
    variables: { limit: 10 },
  })

  if (data)
    if (data.getCSBRecentFootages) {
      return (
        <Flex gap="sm" direction={{ base: 'column', md: 'row' }}>
          <Carousel
            withIndicators
            dragFree
            slideGap={0}
            align="start"
            h={240}
            w={isMobile ? '100%' : 240 * 1.25}
            onSlideChange={(i) => setIndex(i)}
          >
            {data.getCSBRecentFootages.map(({ filename }: { filename: string }, i: number) => (
              <CSBFootageCarouselSlide filename={filename} key={i} />
            ))}
          </Carousel>
          <Box p="md">
            {isMobile && (
              <Text size="lg" weight={500}>
                Last 10 Footages
              </Text>
            )}

            <Text mb="sm">
              Footage created at{' '}
              {dayjs(data.getCSBRecentFootages[index].createdAt)
                .add(-8, 'h')
                .format('YYYY-MM-DD hh:mm:ss A')}
            </Text>
            <CSBFootageDownloadLink filename={data.getCSBRecentFootages[index].filename} />
          </Box>
        </Flex>
      )
    }

  return (
    <Center h={200}>
      <Loader />
    </Center>
  )
}
