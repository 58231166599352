// packages
import { FC } from 'react'
import axios from 'axios'
import { useMutation } from '@apollo/client'
// mantine
import { Group, Modal, rem, useMantineTheme, Text } from '@mantine/core'
import { Dropzone } from '@mantine/dropzone'
// gql
import { GENERATE_CSB_PRESIGNED_URL } from 'gql'
// icons
import { FileMusic, Upload, X } from 'tabler-icons-react'

type ModalCSBAudioUploadContainerProps = {
  _id: string
  opened: boolean
  onClose: () => void
  centered?: boolean
  fullScreen: boolean
}

export const ModalCSBAudioUploadContainer: FC<ModalCSBAudioUploadContainerProps> = ({
  opened,
  onClose,
  centered = true,
  fullScreen,
}) => {
  const theme = useMantineTheme()

  const [action, { loading, error }] = useMutation(GENERATE_CSB_PRESIGNED_URL)

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Upload new Soundtrack"
      centered={centered}
      fullScreen={fullScreen}
    >
      <Dropzone
        onDrop={async (f) => {
          console.log('accepted files', f)
          const options = {
            headers: { 'Content-Type': f[0].type },
          }
          const { data } = await action({
            variables: {
              filename: f[0].name,
              contentType: f[0].type,
            },
          })
          const { generateCSBPresignedURL: signedUrl } = data
          await axios.put(signedUrl, f[0], options)

          // TODO: add audio file to options in select menu
        }}
        onReject={(files) => console.log('rejected files', files)}
        maxSize={10 * 1024 ** 2}
        accept={{ 'audio/*': [] }}
        multiple={false}
      >
        <Group
          position="center"
          spacing="xl"
          style={{ minHeight: rem(120), pointerEvents: 'none' }}
        >
          <Dropzone.Accept>
            <Upload
              size="3.2rem"
              strokeWidth={1.5}
              color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
            />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <X
              size="3.2rem"
              strokeWidth={1.5}
              color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
            />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <FileMusic size="3.2rem" strokeWidth={1.5} />
          </Dropzone.Idle>
          <div>
            <Text size="xl" inline>
              Drag audio file here or click to select file
            </Text>
            <Text size="sm" color="dimmed" inline mt={7}>
              The file should not exceed 10mb.
            </Text>
          </div>
        </Group>
      </Dropzone>
    </Modal>
  )
}
