// packages
import { gql } from '@apollo/client'

export const GET_RECENT_ACTIVATIONS_COMPONENT_QUERY = gql`
  query GetRecentActivationsComponent($_id: ObjectId!) {
    getRecentActivationsComponent(_id: $_id) {
      _id
      sourceId
      props
    }
  }
`
