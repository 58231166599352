// packages
import { FC } from 'react'
import { useQuery } from '@apollo/client'
// mantine
import { Skeleton } from '@mantine/core'
// gql
import { GET_RECENT_ACTIVATIONS_COMPONENT_QUERY } from 'gql'
// Components
import { ActivationsCard } from 'Components'
// Containers
import { ActivationsDataLayer } from './DataLayer'
// typing
import { ActivationsContainerProps } from './type'

export const RecentActivationsContainer: FC<ActivationsContainerProps> = ({
  _id,
  height,
  params,
}) => {
  const { data, loading, error } = useQuery(GET_RECENT_ACTIVATIONS_COMPONENT_QUERY, {
    variables: { _id },
  })

  if (data)
    if (data.getRecentActivationsComponent) {
      const { sourceId } = data.getRecentActivationsComponent
      // get props here
      // const { sourceId, props } = data.getRecentActivationsComponent
      const props = [{ key: 'emlock', state: 0, message: 'test' }]

      return (
        <ActivationsDataLayer
          Component={ActivationsCard}
          ComponentProps={{ title: 'Recent Activations' }}
          sourceId={sourceId}
          height={height}
          params={params}
          props={props}
        />
      )
    }

  return <Skeleton radius="sm" style={{ height }} />
}
