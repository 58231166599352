// packages
import dayjs, { Dayjs } from 'dayjs'
import { FC, useEffect, useState } from 'react'
// mantine
import { Box, Flex, SimpleGrid } from '@mantine/core'
// types
import { CalendarProps } from '../types'
// Components
import { CalendarMonthTitle } from './Title'
import { CalendarMonthControls } from './Controls'
import { CalendarMonthDayTitle, CalendarMonthDayTitleProps } from './DayTitle'
import { CalendarMonthDayGrid } from './DayGrid'

export type CalendarMonthViewProps = {
  setDayView: () => void
} & Pick<CalendarProps, 'date' | 'setDate' | 'events'>

export const CalendarMonthView: FC<CalendarMonthViewProps> = ({
  date,
  setDate,
  events,
  setDayView,
}) => {
  const [dates, setDates] = useState<Dayjs[]>([])
  useEffect(() => {
    const start = date.startOf('M').startOf('w')
    const end = date.endOf('M').endOf('w')

    let d = start.clone()
    const range = []
    while (d.isBefore(end)) {
      range.push(d.clone())
      d = d.add(1, 'd').clone()
    }

    setDates(range)
  }, [date])

  return (
    <Box>
      <Flex justify="space-between" align="baseline" p="xs">
        <CalendarMonthTitle date={date} />
        <CalendarMonthControls date={date} setDate={setDate} />
      </Flex>
      <SimpleGrid cols={7} spacing={0}>
        {Array(7)
          .fill(0)
          .map((_, i) => (
            <CalendarMonthDayTitle day={i as CalendarMonthDayTitleProps['day']} key={i} />
          ))}
        {dates.map((d, i) => (
          <CalendarMonthDayGrid
            date={d}
            key={i}
            inMonth={d.isSame(date, 'M')}
            events={events.filter((event) => dayjs(event.start).isSame(d, 'day'))}
            gotoDate={() => {
              setDate(d)
              setDayView()
            }}
          />
        ))}
      </SimpleGrid>
    </Box>
  )
}
