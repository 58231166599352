// mantine
import { Box, Group, Text } from '@mantine/core'
// Components
import { CSBLast24HoursMetric } from './Metric'
// gql
import {
  GET_CSB_LAST_24_HOURS_FEED,
  GET_CSB_LAST_24_HOURS_FOOTAGE,
  GET_CSB_LAST_24_HOURS_JAM,
} from 'gql'

export const CSBLast24Hours = () => {
  return (
    <Box mt="md">
      <Text mb={1} size="sm">
        In Last 24 hours
      </Text>
      <Group>
        <CSBLast24HoursMetric
          query={GET_CSB_LAST_24_HOURS_FEED}
          field="getCSBLast24HoursFeed"
          label="Feeds"
        />
        <CSBLast24HoursMetric
          query={GET_CSB_LAST_24_HOURS_JAM}
          field="getCSBLast24HoursJam"
          label="Jams"
        />
        <CSBLast24HoursMetric
          query={GET_CSB_LAST_24_HOURS_FOOTAGE}
          field="getCSBLast24HoursFootage"
          label="Footages"
        />
      </Group>
    </Box>
  )
}
