// packages
import { FC } from 'react'
import { useQuery } from '@apollo/client'
// mantine
import { Button, Center, Loader } from '@mantine/core'
// gql
import { GET_BUTTON_WITH_MENU_QUERY } from 'gql'
// Containers
import { ButtonWithMenuMenuContainer } from './Menu'
import { ComponentContainer } from '../Container'

export type ButtonWithMenuContainerProps = {
  _id: string
  height?: number
}

export const ButtonWithMenuContainer: FC<ButtonWithMenuContainerProps> = ({ _id, height }) => {
  const { data, loading, error } = useQuery(GET_BUTTON_WITH_MENU_QUERY, { variables: { _id } })
  if (data)
    if (data.getButtonWithMenu) {
      const { _id, props, main, menu } = data.getButtonWithMenu
      const { _id: mainId } = main

      const ButtonLoader: FC = () => {
        return <Button loading loaderPosition="center" style={{ width: 80 }} />
      }

      return (
        <Button.Group {...props}>
          <ComponentContainer _id={mainId} Loader={<ButtonLoader />} />
          <ButtonWithMenuMenuContainer menu={menu} />
        </Button.Group>
      )
    }
  return (
    <Center style={{ height }}>
      <Loader size="sm" />
    </Center>
  )
}
