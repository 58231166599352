// pacakges
import { FC } from 'react'
import dayjs from 'dayjs'
import { useQuery } from '@apollo/client'
// mantine
import { Skeleton, Table } from '@mantine/core'
// gql
import { GET_ELEPHANT_FEED_PODS_RECENT_FEEDS_QUERY } from 'gql'

export type ElephantFeedPodsRecentFeedsTableProps = {
  id: string
}

export const ElephantFeedPodsRecentFeedsTable: FC<ElephantFeedPodsRecentFeedsTableProps> = ({
  id,
}) => {
  const { data, loading, error } = useQuery(GET_ELEPHANT_FEED_PODS_RECENT_FEEDS_QUERY, {
    variables: { id },
  })

  const Rows = ({ data }: { data: any }) => {
    if (data)
      if (data.getElephantFeedPodsRecentFeeds) {
        return data.getElephantFeedPodsRecentFeeds.map((item: any, i: number) => (
          <tr key={i}>
            <td>{item.type}</td>
            <td>{item.description}</td>
            <td>{dayjs(item.createdAt).format('MMM D YYYY, hh:mma')}</td>
          </tr>
        ))
      }
  }

  const SkeletonRow = () => {
    return (
      <tr>
        {[40, 60, 200].map((w, i) => (
          <td key={i}>
            <Skeleton h="1rem" w={w} radius="sm" />
          </td>
        ))}
      </tr>
    )
  }

  const SkeletonRows = ({ num }: { num: number }) => {
    return (
      <>
        {Array(num)
          .fill(0)
          .map((_, i) => (
            <SkeletonRow key={i} />
          ))}
      </>
    )
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>Events</th>
          <th>Description</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>{data ? <Rows data={data} /> : <SkeletonRows num={20} />}</tbody>
    </Table>
  )
}
