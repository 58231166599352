// packages
import { FC } from 'react'
import { useQuery } from '@apollo/client'
// mantine
import { Center, Loader } from '@mantine/core'
import { Carousel } from '@mantine/carousel'
// Components
import { DynamicReactPlayer } from 'Components'
// gql
import { GET_CSB_BUCKET_QUERY } from 'gql'
import { useIsMobile } from 'hooks'

type CSBFootageCarouselSlideProps = {
  filename: string
}

export const CSBFootageCarouselSlide: FC<CSBFootageCarouselSlideProps> = ({ filename }) => {
  return (
    <Carousel.Slide>
      <CSBVideoFootage filename={filename} />
    </Carousel.Slide>
  )
}

export const CSBVideoFootage: FC<CSBFootageCarouselSlideProps> = ({ filename }) => {
  const isMobile = useIsMobile()
  const { data } = useQuery(GET_CSB_BUCKET_QUERY, {
    variables: { filename },
  })

  if (data)
    if (data.getCSBBucket) {
      return (
        <DynamicReactPlayer
          url={data.getCSBBucket}
          controls
          height={240}
          width={isMobile ? '100%' : 240 * 1.25}
        />
      )
    }

  return (
    <Center h={200} w="100%">
      <Loader />
    </Center>
  )
}
