// packages
import dayjs from 'dayjs'
import { FC, useState } from 'react'
import Link from 'next/link'
// mantine
import { Anchor, Box, Breadcrumbs, Flex, ScrollArea, Stack, Text } from '@mantine/core'
import { DatePicker } from '@mantine/dates'
// Components
import { ElephantFeedPodsDeviceStatusCard } from '../Dashboard/DeviceStatusCard'
import { ScheduleDatePicker } from 'Components'
import { ElephantFeedPodsScheduleByDate } from './Date'
import { ElephantFeedPodsBreadcrumbDeviceName } from '../BreadcrumbDeviceName'
// hooks
import { useIsMobile } from 'hooks'

export type ElephantFeedPodsScheduleContainerProps = {
  id: string
}

export const ElephantFeedPodsScheduleContainer: FC<ElephantFeedPodsScheduleContainerProps> = ({
  id,
}) => {
  const [start, setStart] = useState(dayjs().toDate())
  const isMobile = useIsMobile()

  return (
    <Stack>
      <Box>
        <Breadcrumbs>
          <Anchor
            size="xs"
            color="dimmed"
            weight={600}
            transform="uppercase"
            underline={false}
            component={Link}
            href="/dashboard/elephant-feedpods"
          >
            Elephant Feedpods
          </Anchor>

          <ElephantFeedPodsBreadcrumbDeviceName id={id} type="Schedule" />
        </Breadcrumbs>
        <Text size="lg" weight={500} color="default">
          Schedule
        </Text>
      </Box>
      <ElephantFeedPodsDeviceStatusCard id={id} />
      <Flex direction={{ base: 'column', md: 'row' }}>
        {!isMobile && (
          <Box w={300}>
            <DatePicker
              value={start}
              //@ts-expect-error: date null error
              onChange={setStart}
              excludeDate={(date) => dayjs().add(-1, 'd').isAfter(date)}
            />
          </Box>
        )}
        <Box style={{ flex: 1 }}>
          <ScheduleDatePicker date={start} setDate={setStart} />
          <ScrollArea type="auto" scrollHideDelay={200} scrollbarSize={3} offsetScrollbars h={240}>
            <ElephantFeedPodsScheduleByDate id={id} date={start} />
          </ScrollArea>
        </Box>
      </Flex>
    </Stack>
  )
}
