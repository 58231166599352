// packages
import { gql } from '@apollo/client'

export const GET_ELEPHANT_FEED_PODS_STATUS_BY_DEVICE_ID_QUERY = gql`
  query GetElephantFeedPodsStatusByDeviceId($id: String!) {
    getElephantFeedPodsStatusByDeviceId(id: $id) {
      id
      name
      status
      updated
    }
  }
`
