// packages
import { FC } from 'react'
// mantine
import { Text } from '@mantine/core'
// types
import { CalendarProps } from '../types'

export type CalendarMonthTitleProps = Pick<CalendarProps, 'date'>

export const CalendarMonthTitle: FC<CalendarMonthTitleProps> = ({ date }) => {
  return (
    <Text weight={500} size="lg">
      {date.format('MMMM YYYY')}
    </Text>
  )
}
