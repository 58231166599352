// packages
import Confetti from 'react-confetti'
import Link from 'next/link'
// mantine
import { Avatar, Badge, Box, Card, Flex, List, Text, ThemeIcon } from '@mantine/core'
// styles
import useStyles from './styles'
// icons
import { Notes } from 'tabler-icons-react'

export const CSBUpdates = () => {
  const { classes } = useStyles()

  return (
    <>
      <Text size="xl" mb="md">
        Updates
      </Text>
      <Card withBorder radius="md">
        <Card.Section>
          <Card radius={0} h={180} className={classes.cardHeader}>
            <Confetti />
          </Card>
        </Card.Section>

        <Flex justify="space-between" mt="md" mb="sm">
          <Box>
            <Box>
              <Badge>CSB</Badge>
              <Badge>Update</Badge>
            </Box>
            <Text size="xl">Announcing Version 0.2</Text>
          </Box>

          <Flex direction="column" align="flex-end">
            <Box>
              <Avatar radius="xl">ZP</Avatar>
            </Box>
            <Text size="sm" color="dimmed" ta="right">
              23rd March 2023
            </Text>
          </Flex>
        </Flex>

        <Box mr="xl">
          <Text>Hello! 🤗</Text>
          <Text>On April 7th, we will be updating the CSB Dashboard.</Text>
          <Text>
            In this update, we are making improvements to the user interface based on your feedback
            so it will be easier to use.
          </Text>

          <Box mt="sm">
            <Text weight={600}>Features</Text>
            <List>
              <List.Item>Upload sound clips from dashboard to CSB devices.</List.Item>
            </List>
          </Box>

          <Box mt="sm">
            <Text weight={600}>UI/UX</Text>
            <List>
              <List.Item>
                CSB Device status and key information will be shown on first view.
              </List.Item>
              <List.Item>Remaining number of feeds will appear with device status.</List.Item>
              <List.Item>
                {"Realtime status of device's locking mechanism will be shown."}
              </List.Item>
              <List.Item>
                Truncate the results for CSB motion sensor camera videos and activation logs.
              </List.Item>
              <List.Item>Make Actions Card Component into a table for easier scrolling.</List.Item>
              <List.Item>
                Move temperature recordings out of main page to focus only on the chiller
                recordings.
              </List.Item>
              <List.Item>Increase font sizes for overall readability of the dashboard.</List.Item>
            </List>
          </Box>

          <Text mt="md">
            From the feedback we have gotten, we are happy that our solution has been helpful for
            your team so far! 🎉
          </Text>
          <Text>
            {
              "There are still many features that we're working on for future updates, such as ability to see timings for meat dispensing and preset different alarms/signals."
            }
          </Text>
          <Text>Do look forward to seeing these new features in our next version update.</Text>

          <Text mt="sm">On behalf of the UpCode team, thank you for your feedback! 🙏</Text>

          <Text mt="sm">
            Want to help us make the CSB Dashboard better? Send us your feedback using the link
            below.
          </Text>
        </Box>

        <Card withBorder mt="lg" component={Link} href="https://forms.gle/p71TkRQH12Xhmj1YA">
          <Flex justify="space-between" align="center">
            <Box>
              <Text>Google Form</Text>
              <Text>Give us your thoughts on the CSB Dashboard here!</Text>
            </Box>
            <Box>
              <ThemeIcon variant="outline" size={60} color="gray">
                <Notes />
              </ThemeIcon>
            </Box>
          </Flex>
        </Card>
      </Card>
    </>
  )
}
