// packages
import { gql } from '@apollo/client'

export const GET_ELEPHANT_FEED_PODS_RECENT_FEEDS_QUERY = gql`
  query GetElephantFeedPodsRecentFeeds($id: String!) {
    getElephantFeedPodsRecentFeeds(id: $id) {
      type
      description
      createdAt
    }
  }
`
