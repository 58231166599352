// packages
import { FC } from 'react'
// mantine
import { Card } from '@mantine/core'

export type CSBAudioUploadContainerProps = {
  _id: string
  height?: number
  params?: object
}

export const CSBAudioUploadContainer: FC<CSBAudioUploadContainerProps> = ({
  _id,
  height,
  params,
}) => {
  return (
    <Card withBorder style={{ height }}>
      asd
    </Card>
  )
}
