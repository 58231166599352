import { gql } from '@apollo/client'

/**
 * gql query body to get all dashboards
 *
 * @category GQL
 */
export const GET_ALL_DASHBOARDS_QUERY = gql`
  query GetAllDashboards {
    getAllDashboards {
      _id
      name
      slug
      icon
    }
  }
`
