// packages
import { FC } from 'react'
import { useMutation } from '@apollo/client'
// mantine
import { Button, Group, Stack } from '@mantine/core'
import { useForm } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
// gql
import { ACTIVATE_SOURCE_MUTATION } from 'gql'
// Components
import { InputWrapper, Title } from 'Components'

export type FormContentContainerProps = {
  title: string
  inputs: any[]
  initialValues: Record<string, unknown>
  sourceId: string
  buttonLabel: string
  successNotification: any
  errorNotification: any
  props: any
  onSubmitCallback?: () => void
  isModal?: boolean
}

export const FormContentContainer: FC<FormContentContainerProps> = ({
  title,
  inputs,
  initialValues,
  sourceId,
  buttonLabel,
  successNotification,
  errorNotification,
  props,
  onSubmitCallback,
  isModal,
}) => {
  // TODO: need to handle validation here too
  const form = useForm({ initialValues })

  const [activate, { loading }] = useMutation(ACTIVATE_SOURCE_MUTATION)

  const submit = async (values: Record<string, unknown>) => {
    const { errors } = await activate({
      variables: {
        sourceId,
        data: {
          params: values,
        },
      },
    })

    const { props: sProps } = successNotification
    const { props: eProps } = errorNotification
    showNotification(
      errors ? { ...eProps, ...errorNotification } : { ...sProps, ...successNotification },
    )

    if (onSubmitCallback) onSubmitCallback()
  }

  return (
    <form onSubmit={form.onSubmit((values) => submit(values))} {...props}>
      <Stack>
        {!isModal && <Title>{title}</Title>}
        {inputs.map((input, i) => {
          const { fieldName, props } = input
          return (
            <InputWrapper
              key={i}
              type={input.type}
              form={form}
              fieldName={fieldName}
              props={props}
            />
          )
        })}
        <Group position="right" mt="md">
          <Button type="submit" loading={loading}>
            {buttonLabel}
          </Button>
        </Group>
      </Stack>
    </form>
  )
}
