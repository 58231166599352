// packages
import { useMutation } from '@apollo/client'
import { FC, useState } from 'react'
// mantine
import { Button, Group, Modal, Select, Stack } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { useDisclosure } from '@mantine/hooks'
import { TimeInput } from '@mantine/dates'
// Components
import { ElephantFeedPodsScheduleDayCheckboxes } from './Days'
import {
  GET_ELEPHANT_FEED_PODS_DEVICE_UPCOMING_FEED_QUERY,
  GET_ELEPHANT_FEED_PODS_SCHEDULE,
  SCHEDULE_ELEPHANT_FEED_PODS_MUTATION,
} from 'gql'

export type ElephantFeedPodsScheduleButtonProps = {
  id: string
  name: string
}

export const ElephantFeedPodsScheduleButton: FC<ElephantFeedPodsScheduleButtonProps> = ({
  id,
  name,
}) => {
  const [opened, { open, close }] = useDisclosure(false)
  const [days, setDays] = useState<number[]>([1, 2, 3, 4, 5, 6, 7])
  const [time, setTime] = useState<string>('00:00')
  const [operationType, setOperationType] = useState<string | null>(null)

  const [schedule, { loading }] = useMutation(SCHEDULE_ELEPHANT_FEED_PODS_MUTATION, {
    refetchQueries: [
      GET_ELEPHANT_FEED_PODS_SCHEDULE,
      GET_ELEPHANT_FEED_PODS_DEVICE_UPCOMING_FEED_QUERY,
    ],
  })
  const submit = async () => {
    const { errors } = await schedule({
      variables: { id, days, time, operationType },
    })

    if (errors) {
      notifications.show({
        title: 'Error',
        message: 'Unexpected error while trying to create a new schedule. Please try again later.',
        withCloseButton: true,
        color: 'red',
        autoClose: false,
      })
    } else {
      close()

      notifications.show({
        title: 'Success',
        message: `You have created a new feed schedule for ${time}`,
        withCloseButton: true,
        color: 'green',
      })
    }
  }

  return (
    <>
      <Modal opened={opened} onClose={close} centered title={`Schedule a Feed for ${name}`}>
        <Stack pt="sm">
          <TimeInput
            label="Time"
            value={time}
            onChange={({ currentTarget }) => setTime(currentTarget.value)}
            required
          />
          <Select
            withinPortal
            label="Select amount to feed"
            value={operationType}
            onChange={setOperationType}
            required
            placeholder="Feed type"
            data={[
              { value: '1', label: 'Low' },
              { value: '2', label: 'Medium' },
              { value: '3', label: 'High' },
            ]}
          />
          <ElephantFeedPodsScheduleDayCheckboxes days={days} setDays={setDays} />
          <Group position="right">
            <Button onClick={submit} loading={loading}>
              Create Schedule
            </Button>
          </Group>
        </Stack>
      </Modal>
      <Button onClick={open}>Schedule Feed </Button>
    </>
  )
}
