// packages
import { FC, useState } from 'react'
import dayjs from 'dayjs'
// mantine
import { Box, Slider } from '@mantine/core'

export type TimeSliderProps = {
  start: Date
  end: Date
  marks: any[]
}

export const TimeSlider: FC<TimeSliderProps> = ({ start, end, marks }) => {
  const [value, setValue] = useState(0)

  const seconds = dayjs(end).diff(dayjs(start), 's')
  const min = 0
  const max = seconds

  return (
    <Slider
      size="sm"
      value={value}
      color="gray"
      min={min}
      max={max}
      marks={marks.map((mark) => ({
        ...mark,
        label: (
          <Box onClick={() => setValue(mark.value)} style={{ cursor: 'pointer' }}>
            {mark.label}
          </Box>
        ),
      }))}
      labelAlwaysOn={false}
      label={(value) => dayjs(start).add(value, 's').toISOString()}
      styles={(theme) => ({
        bar: {
          backgroundColor:
            theme.colorScheme === 'light' ? theme.colors.gray[2] : theme.colors.dark[4],
        },
        track: { cursor: 'default' },
        thumb: {
          cursor: 'default',
          borderColor:
            theme.colorScheme === 'light' ? theme.colors.orange[5] : theme.colors.orange[7],
        },
        mark: {
          borderColor: theme.colorScheme === 'light' ? theme.colors.gray[6] : theme.colors.gray[8],
        },
        markLabel: {
          marginTop: `8px`,
        },
      })}
    />
  )
}
