// packages
import { FC, forwardRef, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
// mantine
import { Avatar, Button, Group, Select, Text } from '@mantine/core'
// gql
import {
  ADD_USER_TO_PULSE_TARGETS_MUTATION,
  AUTOSUGGEST_TARGET_USERS_OF_PULSE_QUERY,
  GET_TARGET_USERS_OF_PULSE_QUERY,
} from 'gql'

export type PulsesAddUserModalProps = {
  _id: string
  back: () => void
}

export const PulsesAddUserModal: FC<PulsesAddUserModalProps> = ({ _id, back }) => {
  const [getAutoSuggest, { loading }] = useLazyQuery(AUTOSUGGEST_TARGET_USERS_OF_PULSE_QUERY, {
    fetchPolicy: 'network-only',
  })
  const [addUser] = useMutation(ADD_USER_TO_PULSE_TARGETS_MUTATION)

  const [value, setValue] = useState<string | null>(null)
  const [searchValue, setSearchValue] = useState('')
  const [names, setNames] = useState<{ value: string; label: string }[]>([])
  const add = async () => {
    const { data } = await addUser({
      variables: {
        _id,
        userId: value,
      },
      refetchQueries: [GET_TARGET_USERS_OF_PULSE_QUERY],
    })
    back()
  }

  const onSearchChange = async (value: string) => {
    setSearchValue(value)
    if (value.length < 3) {
      setNames([])
      return
    }

    const { data } = await getAutoSuggest({
      variables: { _id, search: value },
    })

    if (data)
      if (data.autosuggestTargetUsersOfPulse) {
        setNames(
          data.autosuggestTargetUsersOfPulse.map((d: any) => ({ value: d._id, label: d.name })),
        )
      }
  }

  return (
    <>
      <Select
        value={value}
        onChange={setValue}
        label="Add user"
        searchable
        data={names}
        nothingFound={
          loading
            ? 'Loading...'
            : searchValue.length < 3
            ? 'Enter at least 3 characters'
            : 'No user found'
        }
        withinPortal
        itemComponent={SelectItem}
        searchValue={searchValue}
        onSearchChange={onSearchChange}
      />
      <Group mt="sm" position="apart">
        <Button variant="subtle" onClick={back}>
          Back
        </Button>
        <Button onClick={add}>Add</Button>
      </Group>
    </>
  )
}

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(({ label, ...others }: ItemProps, ref) => (
  <div ref={ref} {...others}>
    <Group noWrap>
      <Avatar radius="xl">{label.charAt(0)}</Avatar>

      <div>
        <Text size="sm">{label}</Text>
        <Text size="xs" opacity={0.65}>
          Member
        </Text>
      </div>
    </Group>
  </div>
))

SelectItem.displayName = 'SelectItem'
