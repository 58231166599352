// packages
import { FC, useState } from 'react'
// mantine
import { Button, Menu } from '@mantine/core'
// Containers
import { ComponentContainer, ModalContainer } from 'Containers'
// icons
import { ChevronDown } from 'tabler-icons-react'

type Menu = {
  _id: string
}

export type ButtonWithMenuMenuContainerProps = {
  menu: Menu[]
}

export const ButtonWithMenuMenuContainer: FC<ButtonWithMenuMenuContainerProps> = ({ menu }) => {
  const [modalId, setModalId] = useState<string | undefined>(undefined)
  const [opened, setOpened] = useState(false)

  const onModalClick = (modalId: string) => {
    setModalId(modalId)
    setOpened(true)
  }

  return (
    <>
      {modalId && <ModalContainer _id={modalId} opened={opened} onClose={() => setOpened(false)} />}
      <Menu>
        <Menu.Target>
          <Button px={3}>
            <ChevronDown size={14} />
          </Button>
        </Menu.Target>

        <Menu.Dropdown>
          {menu.map(({ _id }) => (
            <ComponentContainer key={_id} _id={_id} additionalProps={{ onModalClick }} />
          ))}
        </Menu.Dropdown>
      </Menu>
    </>
  )
}
