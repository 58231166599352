// packages
import { FC } from 'react'
import { useQuery } from '@apollo/client'
// mantine
import { Menu } from '@mantine/core'
// gql
import { GET_MENU_ITEM_MODAL_QUERY } from 'gql'

export type MenuItemModalContainerProps = {
  _id: string
  onModalClick: any
}

export const MenuItemModalContainer: FC<MenuItemModalContainerProps> = ({ _id, onModalClick }) => {
  const { data, loading, error } = useQuery(GET_MENU_ITEM_MODAL_QUERY, { variables: { _id } })

  if (data)
    if (data.getMenuItemModal) {
      const {
        modal: { _id: modalId },
        label,
      } = data.getMenuItemModal

      return <Menu.Item onClick={() => onModalClick(modalId)}>{label}</Menu.Item>
    }

  // TODO: how should we handle the loading state here?
  return <></>
}
