// packages
import { FC, useState } from 'react'
// mantine
import { ColorInput, ColorInputProps } from '@mantine/core'
// props
import { BaseInputProps } from './baseInputProps'

export type ColorSwatchInputWrapperComponentProps = {
  /**
   * Custom mapping of values which the user can choose to pass in.
   *
   * Key is the hexadecimal/rgb/color code format that will be used to pass into the swatch.
   *
   * Value is what we would like the form to submit, for example: `red`.
   */
  mapping: Record<string, string>

  /**
   * @alpha
   *
   * Whether the right text section should appear, with the name of the color in the input field.
   *
   * TODO: implement this
   */
  showRightTextSection?: boolean
} & ColorInputProps

export type ColorSwatchInputWrapperProps = {
  props: ColorSwatchInputWrapperComponentProps
} & BaseInputProps

/**
 * A wrapper for the ColorInput field
 *
 * @see [Mantine ColorInput](https://mantine.dev/core/color-input/)
 */
export const ColorSwatchInputWrapper: FC<ColorSwatchInputWrapperProps> = ({
  form,
  fieldName,
  props,
}) => {
  const { mapping, ...rest } = props
  const initialValue = Object.keys(mapping).find((key) => mapping[key] === form.values[fieldName])
  const [value, setValue] = useState(initialValue)
  const swatches = Object.keys(mapping)

  const setFormValue = (colorCode: string) => {
    form.setValues({ [fieldName]: mapping[colorCode] })
    setValue(colorCode)
  }

  return (
    <ColorInput
      styles={{
        label: { marginBottom: 5 },
      }}
      closeOnColorSwatchClick
      value={value}
      onChange={setFormValue}
      {...rest}
      swatches={swatches}
    />
  )
}
