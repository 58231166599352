// mantine
import { createStyles } from '@mantine/core'

/**
 * Styling props for {@link Title} Component
 */
export default createStyles((theme) => ({
  title: {
    color: theme.colorScheme === 'light' ? '#001737' : theme.colors.gray[2],
    letterSpacing: -0.5,
    lineHeight: 1.25,
  },
}))
