import { isBrowser } from 'shared'

const NEXT_PUBLIC_AUTH_TOKEN_NAME = process.env.NEXT_PUBLIC_AUTH_TOKEN_NAME
if (!NEXT_PUBLIC_AUTH_TOKEN_NAME) throw new Error('NEXT_PUBLIC_AUTH_TOKEN_NAME is undefined.')

export const getAuthToken = (): string => {
  const defaultToken = ''

  if (isBrowser()) {
    const token = localStorage.getItem(NEXT_PUBLIC_AUTH_TOKEN_NAME!)
    if (!token) {
      return defaultToken
    }
    return token
  }

  return defaultToken
}

export const setAuthToken = (token: string) =>
  isBrowser() && localStorage.setItem(NEXT_PUBLIC_AUTH_TOKEN_NAME!, token)

export const removeAuthToken = () =>
  isBrowser() && localStorage.removeItem(NEXT_PUBLIC_AUTH_TOKEN_NAME!)
