// packages
import { FC } from 'react'
// mantine
import { Button, ButtonGroupProps, Menu } from '@mantine/core'
// icons
import { ChevronDown } from 'tabler-icons-react'

/**
 * Props for each menu item
 */
export type RightMenuOption = {
  /** label for the menu item */
  label: string

  /** action triggered by clicking on menu item */
  onClick: () => void
}

/**
 * Props for ButtonWithMenuComponent
 */
export type ButtonWithMenuProps = {
  /** label text in the left hand side button */
  mainLabel: string

  /** function to activate for main button */
  mainOnClick: () => void

  /**
   * flag to turn button into loading state
   *
   * @defaultValue - false
   */
  loading?: boolean

  /** options for right hand side menu dropdown */
  menu: (RightMenuOption | string)[]

  componentProps?: ButtonGroupProps
}

/**
 * Component for button with menu icon on the left
 *
 * TODO: tie both interaction action together, such that they will
 * both react when clicked upon
 */
export const ButtonWithMenu: FC<ButtonWithMenuProps> = ({
  mainLabel,
  mainOnClick,
  menu,
  loading = false,
  componentProps,
}) => {
  return (
    <Button.Group {...componentProps}>
      <Button loading={loading} onClick={mainOnClick}>
        {mainLabel}
      </Button>
      <Menu>
        <Menu.Target>
          <Button px={3} disabled={loading}>
            <ChevronDown size={14} />
          </Button>
        </Menu.Target>

        <Menu.Dropdown>
          {menu.map((item, i) => {
            if (typeof item === 'string') {
              return (
                <>
                  {i !== 0 && <Menu.Divider />}
                  <Menu.Label key={i}>{item}</Menu.Label>
                </>
              )
            } else {
              return (
                <Menu.Item key={i} onClick={item.onClick}>
                  {item.label}
                </Menu.Item>
              )
            }
          })}
        </Menu.Dropdown>
      </Menu>
    </Button.Group>
  )
}
