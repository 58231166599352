// packages
import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
// mantine
import { Badge, Box, Group, Skeleton, Text } from '@mantine/core'
import { useInterval } from '@mantine/hooks'
// gql
import { GET_CSB_CHILLER_TEMPERATURE_QUERY } from 'gql'

export const Temperature = () => {
  return (
    <Box>
      <Text size="sm" weight={500} tt="uppercase" color="default">
        Temperature
      </Text>
      <Reading />
      <Text size="xs" color="dimmed">
        Temperature reading of the chiller compartment.
      </Text>
    </Box>
  )
}

const Reading = () => {
  const [at, setAt] = useState(new Date().toISOString())

  const { data } = useQuery(GET_CSB_CHILLER_TEMPERATURE_QUERY, {
    variables: { at },
  })

  const interval = useInterval(() => setAt(new Date().toISOString()), 1000 * 60)
  useEffect(() => {
    interval.start()
    return interval.stop
  }, [interval])

  if (data) {
    const { getCSBChillerTemperature: temp } = data
    if (temp !== undefined) {
      return (
        <Text size={32} weight={400} style={{ fontFamily: `'Rubik', sans-serif` }}>
          {temp ? `${temp}°C` : '-'}&nbsp;
          <TempBadge temp={temp} />
        </Text>
      )
    }
  }

  return (
    <Group align="flex-end" spacing="sm" mt={5} mb={10}>
      <Skeleton h={34.59} w={60} />
      <Skeleton h={14} w={45} radius="md" />
    </Group>
  )
}

const TempBadge = ({ temp }: { temp: number | null }) => {
  if (!temp) return <Badge color="gray">No readings</Badge>
  if (temp > 6) return <Badge color="red">over 6°C</Badge>
  return <Badge color="green">Ok</Badge>
}
