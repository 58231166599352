// packages
import { FC } from 'react'
import { useRouter } from 'next/router'
import { useMutation } from '@apollo/client'
import Link from 'next/link'
// mantine
import {
  TextInput,
  PasswordInput,
  Checkbox,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
  LoadingOverlay,
} from '@mantine/core'
import { showNotification } from '@mantine/notifications'
// lib
import { initializeApollo } from 'lib'
// gql
import { LOGIN_MUTATION } from 'gql'
// localStorage
import { setAuthToken } from 'localStorage'
// hooks
import { useLoginForm } from './useLoginForm'
// icons
import { Check } from 'tabler-icons-react'

export type LoginFormProps = {
  redirect?: string
}

export const LoginForm: FC<LoginFormProps> = ({ redirect = '/' }) => {
  const client = initializeApollo()
  const router = useRouter()

  const form = useLoginForm()
  // const [errorMsg, setErrorMsg] = useState<string | null>(null)

  const [action, { loading, error }] = useMutation(LOGIN_MUTATION)

  const handleSubmit = async () => {
    const { email, password } = form.values
    const params = { email, password }

    const { data } = await action({ variables: { params } })

    if (error) {
      // TODO: add error message here for login failure
      // alert(JSON.stringify(error.message, null, 2))
      // setError(errors[0].message)
    } else {
      setAuthToken(data.login.token)
      showNotification({
        color: 'teal',
        icon: <Check size={18} />,
        autoClose: 4000,
        title: 'Welcome!',
        message: 'Logged in successfully.',
      })

      await client.resetStore()
      await router.push(redirect)
    }
  }

  return (
    <Container size={420} my={40}>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <LoadingOverlay visible={loading} />

        <Title align="center" sx={(_) => ({ fontWeight: 600 })}>
          Welcome back!
        </Title>

        <Text color="dimmed" size="sm" align="center" mt={5}>
          Do not have an account yet?{' '}
          <Anchor<'a'>
            href="#"
            size="sm"
            onClick={(event) => event.preventDefault()}
            underline={false}
          >
            Create account
          </Anchor>
        </Text>

        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          <TextInput
            label="Email"
            placeholder="@mandai.com"
            required
            {...form.getInputProps('email')}
          />
          <PasswordInput
            label="Password"
            placeholder="Your password"
            required
            mt="md"
            {...form.getInputProps('password')}
          />
          <Group position="apart" mt="md">
            <Checkbox label="Remember me" />
            <Anchor size="sm" href="/forget-password" underline={false} component={Link}>
              Forgot password?
            </Anchor>
          </Group>

          {error && (
            <Text color="red" size="sm" align="center" mt={5}>
              {JSON.stringify(error.graphQLErrors, null, 2)}
            </Text>
          )}

          <Button fullWidth mt="xl" type="submit">
            Sign in
          </Button>
        </Paper>
      </form>
    </Container>
  )
}
