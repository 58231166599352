// packages
// mantine
import { useForm } from '@mantine/form'
// interface
// components

export const useLoginForm = () => {
  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },

    validate: {
      // email: (value: string) => (/^\S+@\S+$/.test(value) ? 'Invalid email' : null),
      email: (value) => (/^\S+@mandai.com/.test(value) ? null : 'Invalid email'),
    },
  })

  return form
}
