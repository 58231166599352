import { gql } from '@apollo/client'

export const GET_CSB_TIGER_RECOGNITION_VIDEOS = gql`
  query GetCSBTigerRecognitionVideos($start: DateTime!, $end: DateTime!) {
    getCSBTigerRecognitionVideos(start: $start, end: $end) {
      _id
      key
      recordedAt
    }
  }
`
