// packages
import { FC } from 'react'
import { useQuery } from '@apollo/client'
// mantine
import { Skeleton, Text } from '@mantine/core'
import { useLocalStorage } from '@mantine/hooks'
// gql
import { GET_CURRENT_USER_QUERY } from 'gql'
// localStorage
import { leftSideBarLocalStorageProps } from 'localStorage'

export type LeftSideBarContentNameProps = {}

export const LeftSideBarContentName: FC<LeftSideBarContentNameProps> = () => {
  const [expanded] = useLocalStorage<boolean>(leftSideBarLocalStorageProps)

  const { data, error } = useQuery(GET_CURRENT_USER_QUERY)

  if (data) {
    if (data.getCurrentUser) {
      return (
        <div>
          {expanded && (
            <Text weight={500} size="sm">
              {data.getCurrentUser.name}
            </Text>
          )}
        </div>
      )
    }
  }
  return expanded ? <Skeleton radius="sm" height={6} width={70} /> : <></>
}
