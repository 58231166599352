// packages
import { MantineThemeOverride } from '@mantine/core'

/**
 * a mantine override object variable is used to override the default mantine theme styling
 *
 * this sent to {@link https://mantine.dev/theming/mantine-provider/ | mantine theme provider} in `_app.ts` of the `NextJS` app
 *
 * @category Application
 */
export const theme: MantineThemeOverride = {
  fontFamily: `IBM Plex Sans, sans-serif`,
  other: {
    gridTemplateRowHeight: 60,
  },
}
