// packages
import { FC } from 'react'
// mantine
import { Box, Button, Flex, Text } from '@mantine/core'
// types
import { CalendarProps } from '../types'
// Components
import { CalendarDayControls } from './Controls'
// icons
import { ChevronLeft } from 'tabler-icons-react'

export type CalendarDayViewProps = {
  setMonthView: () => void
} & Pick<CalendarProps, 'date' | 'setDate' | 'events'>

export const CalendarDayView: FC<CalendarDayViewProps> = ({
  date,
  setDate,
  events,
  setMonthView,
}) => {
  return (
    <Box>
      <Flex justify="space-between" align="center" p="xs" pl={0}>
        <Button
          variant="subtle"
          color="gray"
          pl={0}
          leftIcon={<ChevronLeft size={17} />}
          onClick={() => {
            setMonthView()
            setDate(date)
          }}
        >
          {date.format('MMMM')}
        </Button>
        <Text weight={500} size="lg" align="center">
          {date.format('dddd, MMMM D, YYYY')}
        </Text>
        <CalendarDayControls date={date} setDate={setDate} />
      </Flex>
      {events.length}
    </Box>
  )
}
