// mantine
import { createStyles } from '@mantine/core'

export default createStyles((theme) => ({
  title: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
}))
