// packages
import { gql } from '@apollo/client'

export const GET_PULSES_QUERY = gql`
  query GetPulses($ids: [ObjectId!]!) {
    getPulses(_ids: $ids) {
      _id
      name
      status
    }
  }
`
