// packages
import { FC } from 'react'
import { useQuery } from '@apollo/client'
// mantine
import { Center, Loader } from '@mantine/core'
// gql
import { GET_ACTIONS_QUERY } from 'gql'
// Components
import { ActionsListItem } from './Item'

export type ActionsListProps = {
  _ids: string[]
}

export const ActivateActionsList: FC<ActionsListProps> = ({ _ids }) => {
  const { data, loading, error } = useQuery(GET_ACTIONS_QUERY, {
    variables: { ids: _ids },
  })

  if (data)
    if (data.getActions) {
      return (
        <>
          {data.getActions.map(
            ({ _id, name, steps }: { _id: string; name: string; steps: any[] }) => (
              <ActionsListItem key={_id} _id={_id} name={name} steps={steps} />
            ),
          )}
        </>
      )
    }

  return (
    <Center style={{ height: '100%' }}>
      <Loader />
    </Center>
  )
}
