// packages
import { FC, ReactNode } from 'react'
// mantine
import { useMantineColorScheme, useMantineTheme, Text } from '@mantine/core'

export type ActivationsUpcomingBodyTextProps = {
  username: string
  requestBody: any
}

export const ActivationsUpcomingBodyText: FC<ActivationsUpcomingBodyTextProps> = ({
  username,
  requestBody,
}) => {
  const { params } = requestBody
  const { iottype, device_id } = params
  const theme = useMantineTheme()
  const { colorScheme } = useMantineColorScheme()

  const B = ({ children }: { children: ReactNode }) => (
    <Text
      component="span"
      weight={500}
      color={colorScheme === 'light' ? theme.colors.gray[9] : theme.colors.gray[2]}
    >
      {children}
    </Text>
  )

  switch (iottype) {
    case 'feed': {
      return (
        <>
          <B>Feed</B> scheduled by <B>{username}</B>.
        </>
      )
    }
    default: {
      return <></>
      {
        /* return <>{JSON.stringify(requestBody)}</> */
      }
    }
  }
}
