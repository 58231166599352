// packages
import { FC, Dispatch, SetStateAction } from 'react'
// mantine
import { Group, Progress, Text } from '@mantine/core'

// type Data = {
//   PiMetricReadingConvtd: number
//   mItemUnit: string
//   mItemAccptRange: string
// }

type ProgressBarProps = {
  name: string
  min: number
  max: number
  value: number
  unit: string
  rangeType: string // TODO: turn this into an enum
  color: string
  setMetric: Dispatch<SetStateAction<string>>
}

export const ProgressBar: FC<ProgressBarProps> = ({
  name,
  min,
  max,
  value,
  unit,
  rangeType,
  color,
  setMetric,
}) => {
  // const { PiMetricReadingConvtd: value, mItemUnit: unit, mItemAccptRange: range } = data

  // let min: number
  // let max: number

  // if (range.startsWith('<=')) {
  //   max = parseFloat(range.replace('<=', ''))
  //   min = 0
  // } else {
  //   max = 10
  //   min = parseFloat(range.replace('>=', ''))
  // }

  const progress = ((value - min) / max) * 100

  const range = rangeType == '>=' ? `${rangeType} ${min}` : `${rangeType} ${max}`

  return (
    <div style={{ cursor: 'pointer' }} onClick={() => setMetric(name)}>
      <Group position="apart">
        <Text
          weight={600}
          size={10}
          color="#1b2e4b"
          transform="uppercase"
          style={{ letterSpacing: 0.5 }}
        >
          {name}
        </Text>
        <Text size="xs" color="dimmed">
          {range}
        </Text>
      </Group>
      <div style={{ marginBottom: 3 }}>
        <Text
          component="span"
          color="#001737"
          size={17.5}
          weight={400}
          style={{ fontFamily: `"Rubik", sans-serif` }}
        >
          {value}
        </Text>
        &nbsp;
        <Text component="span" color="#001737" size={12} weight={400}>
          {unit}
        </Text>
      </div>
      <Progress size="sm" value={progress} color={color} animate style={{ opacity: 0.5 }} />
    </div>
  )
}
