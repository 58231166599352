// packages
import { gql } from '@apollo/client'

export const GET_ACTIONS_LIST_QUERY = gql`
  query GetActionsList($_id: ObjectId!) {
    getActionsList(_id: $_id) {
      _id
      actions {
        _id
      }
    }
  }
`
