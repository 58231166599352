// packages
import { gql } from '@apollo/client'

export const GET_FORM_QUERY = gql`
  query GetForm($_id: ObjectId!) {
    getForm(_id: $_id) {
      _id
      title
      props
      source {
        _id
      }
      inputs {
        _id
        fieldName
        props
        type
      }
      initialValues
      buttonLabel
      successNotification {
        _id
        color
        icon
        loading
        title
        message
        props
      }
      errorNotification {
        _id
        color
        icon
        loading
        title
        message
        props
      }
    }
  }
`
