// packages
import { FC } from 'react'
// mantine
import { Paper, Text } from '@mantine/core'

export type CalendarMonthDayTitleProps = {
  day: 0 | 1 | 2 | 3 | 4 | 5 | 6
}

export const CalendarMonthDayTitle: FC<CalendarMonthDayTitleProps> = ({ day }) => {
  const mapping = {
    0: 'Sun',
    1: 'Mon',
    2: 'Tue',
    3: 'Wed',
    4: 'Thu',
    5: 'Fri',
    6: 'Sat',
  }

  const title = mapping[day]

  if (!title) throw new Error(`Day is undefined for: ${day}`)

  return (
    <Paper withBorder py={5} radius={0}>
      <Text align="center" weight={500} size="sm" transform="uppercase">
        {title}
      </Text>
    </Paper>
  )
}
