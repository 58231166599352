import { gql } from '@apollo/client'

export const SCHEDULE_ELEPHANT_FEED_PODS_MUTATION = gql`
  mutation ScheduleElephantFeedPods(
    $id: String!
    $time: String!
    $days: [Int!]!
    $operationType: String!
  ) {
    scheduleElephantFeedPods(id: $id, time: $time, days: $days, operationType: $operationType)
  }
`
