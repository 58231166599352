// packages
import { FC } from 'react'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
// mantine
import { Button } from '@mantine/core'
import { useHotkeys } from '@mantine/hooks'
// Components
import { CalendarProps } from '../types'
// icons
import { ChevronLeft, ChevronRight } from 'tabler-icons-react'

export type CalendarMonthControlsProps = Pick<CalendarProps, 'date' | 'setDate'>

export const CalendarMonthControls: FC<CalendarMonthControlsProps> = ({ date, setDate }) => {
  dayjs.extend(isToday)

  const prevMonth = () => setDate((date) => date.add(-1, 'M'))
  const nextMonth = () => setDate((date) => date.add(1, 'M'))

  useHotkeys([
    ['ArrowLeft', prevMonth],
    ['ArrowRight', nextMonth],
  ])

  return (
    <Button.Group>
      <Button variant="default" size="xs" onClick={prevMonth}>
        <ChevronLeft size={16} />
      </Button>
      <Button
        variant="default"
        size="xs"
        onClick={() => setDate(dayjs())}
        disabled={date.isToday()}
      >
        Today
      </Button>
      <Button variant="default" size="xs" onClick={nextMonth}>
        <ChevronRight size={16} />
      </Button>
    </Button.Group>
  )
}
