// packages
import { FC } from 'react'
import { useQuery } from '@apollo/client'
import dayjs, { Dayjs } from 'dayjs'
import isTomorrow from 'dayjs/plugin/isTomorrow'
// mantine
import { Skeleton, Stack, Text } from '@mantine/core'
// gql
import { GET_ELEPHANT_FEED_PODS_DEVICE_UPCOMING_FEED_QUERY } from 'gql'

dayjs.extend(isTomorrow)

export type UpcomingFeedTimeProps = {
  id: string
}

export const UpcomingFeedTime: FC<UpcomingFeedTimeProps> = ({ id }) => {
  const { data, loading, error } = useQuery(GET_ELEPHANT_FEED_PODS_DEVICE_UPCOMING_FEED_QUERY, {
    variables: { id },
  })

  const ScheduledTiming = ({ time }: { time: Dayjs }) => {
    const now = dayjs()
    if (now.isSame(time, 'd')) {
      return <>Today, {dayjs(time).format('h:mm A')}</>
    }

    if (time.isTomorrow()) {
      return <>Tomorrow, {dayjs(time).format('h:mm A')}</>
    }

    return <>{dayjs(time).format('MMM D, h:mm A')}</>
  }

  if (loading) return <Skeleton h="1rem" w={120} />

  if (data)
    if (data.getElephantFeedPodsDeviceUpcomingFeed) {
      return (
        <Stack spacing={1}>
          <Text size="sm" weight={500} color="dimmed">
            Next Scheduled Feed
          </Text>
          <Text size="sm">
            <ScheduledTiming
              time={dayjs(data.getElephantFeedPodsDeviceUpcomingFeed).add(-8, 'h')}
            />
          </Text>
        </Stack>
      )
    }

  return (
    <Text size="sm" weight={500} color="dimmed">
      No Scheduled Feed
    </Text>
  )
}
