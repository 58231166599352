// packages
import { gql } from '@apollo/client'

export const GET_CSB_RECENT_FOOTAGES_QUERY = gql`
  query GetCSBRecentFootages($limit: Int!) {
    getCSBRecentFootages(limit: $limit) {
      filename
      createdAt
    }
  }
`
