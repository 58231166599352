// packages
import { useQuery } from '@apollo/client'
import { FC } from 'react'
// mantine
import { Center, Loader } from '@mantine/core'
// gql
import { GET_FORM_QUERY } from 'gql'
// Components
import { FormContentContainer } from './Content'

/**
 * Props for FormContainer
 */
type FormContainerProps = {
  /** form id */
  _id: string

  /**
   * Callbacks which can be triggered after form is submitted
   *
   * @example
   * If form is in a modal and needs to close the modal after submission, we can pass in the modal closing code here.
   */
  onSubmitCallback?: () => void

  /**
   * Flag to show if form is inside a modal.
   *
   * @defaultValue: false
   */
  isModal?: boolean
}

/**
 * A Form Container that is built using data from backend.
 *
 * The query used here is {@link GET_FORM_QUERY}.
 *
 */
export const FormContainer: FC<FormContainerProps> = ({
  _id,
  onSubmitCallback,
  isModal = false,
}) => {
  const { data, loading, error } = useQuery(GET_FORM_QUERY, {
    variables: {
      _id,
    },
  })

  if (loading)
    return (
      <Center style={{ height: 100 }}>
        <Loader />
      </Center>
    )

  if (data)
    if (data.getForm) {
      const { getForm: form } = data
      const {
        title,
        inputs,
        initialValues,
        source,
        buttonLabel,
        successNotification,
        errorNotification,
        props,
      } = form

      return (
        <FormContentContainer
          title={title}
          inputs={inputs}
          initialValues={initialValues}
          sourceId={source._id}
          buttonLabel={buttonLabel}
          successNotification={successNotification}
          errorNotification={errorNotification}
          props={props}
          onSubmitCallback={onSubmitCallback}
          isModal={isModal}
        />
      )
    }

  if (error) return <div>error</div>

  return <div>error</div>
}
