// packages
import { FC, useState } from 'react'
import { useQuery } from '@apollo/client'
// gql
import { GET_CSB_TIGER_RECOGNITION_VIDEOS, GET_DATA_QUERY } from 'gql'

const treatData = (data: any) => {
  if (!data) return null
  return data.getCSBTigerRecognitionVideos || null
}

export type VideoPlayerDataLayerProps = {
  Component: FC<any>
  dataId: string
  intervalPeriod: number
  height?: number
  props: any
  params?: object
}

export const VideoPlayerDataLayer: FC<VideoPlayerDataLayerProps> = ({
  Component,
  dataId,
  intervalPeriod,
  height,
  props,
  params,
}) => {
  const [data, setData] = useState(null)

  const { loading } = useQuery(GET_CSB_TIGER_RECOGNITION_VIDEOS, {
    variables: {
      start: '2023-03-01',
      end: '2023-05-01',
    },
    onCompleted: (result) => {
      setData(result)
    },
  })

  return <Component data={treatData(data)} loading={loading} props={props} height={height} />
}
