// packages
import { FC } from 'react'
import Link from 'next/link'
import dayjs from 'dayjs'
// mantine
import { Anchor, Box, Flex, Group, Skeleton, Text } from '@mantine/core'
// icons
import { FileDownload } from 'tabler-icons-react'

type SparkContentHeaderProps = {
  componentId: string
  props: any
  data: any
  loading: boolean
  isCurrent: boolean
  value: string
  dateStr: string
}

export const SparkContentHeader: FC<SparkContentHeaderProps> = ({
  componentId,
  props,
  data,
  loading,
  isCurrent,
  value,
  dateStr,
}) => {
  const { title, xKey, yKey, subText, units } = props

  const Title = ({ title, componentId }: { title: string; componentId: string }) => {
    return (
      <Anchor
        underline={false}
        color="default"
        weight="bold"
        size="sm"
        href={`/components/${componentId}`}
        component={Link}
      >
        {title}
      </Anchor>
    )
  }

  const SkeletonHeader = ({ loading }: { loading: boolean }) => {
    return (
      <>
        <Title title={title} componentId={componentId} />
        {loading && (
          <>
            <Skeleton height={28} width={60} radius="sm" />
            <Skeleton height={8} width="70%" radius="xl" mt={8} />
          </>
        )}
      </>
    )
  }

  if (loading) return <SkeletonHeader loading={loading} />

  if (data) {
    const current = data[data.length - 1]

    if (!current) return <SkeletonHeader loading={loading} />

    const download = () => {
      const headers = [`${xKey},${yKey}`]
      const rows = data.reduce((result: any, d: any) => {
        result.push([d[xKey], d[yKey]].join(','))
        return result
      }, [])
      const content = [...headers, ...rows].join('\n')

      // Create a blob with the data we want to download as a file
      const blob = new Blob([content], {
        type: 'text/csv',
      })
      // Create an anchor element and dispatch a click event on it
      // to trigger a download
      const a = document.createElement('a')
      a.download = 'data.csv'
      a.href = window.URL.createObjectURL(blob)
      const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      })
      a.dispatchEvent(clickEvt)
      a.remove()
    }

    return (
      <>
        <Flex justify="space-between">
          <Title title={title} componentId={componentId} />
          <FileDownload
            size="16"
            strokeWidth={1}
            onClick={() => download()}
            style={{ cursor: 'pointer' }}
          />
        </Flex>
        <Box p={0}>
          <Box p={0}>
            <Group spacing={5} align="baseline">
              <Text
                size={28}
                weight={400}
                style={{ fontFamily: `"Rubik", sans-serif`, whiteSpace: 'nowrap' }}
              >
                {isCurrent ? current[yKey] : value} {units}
              </Text>
              <Text size="xs" color="dimmed">
                {dayjs(isCurrent ? data[data.length - 1][xKey] : dateStr)
                  .add(-8, 'h')
                  .format('D MMM, h:mm:ssa')}
              </Text>
            </Group>
          </Box>
          <Box p={0}>
            <Text color="dimmed" size="xs">
              {subText}
            </Text>
          </Box>
        </Box>
      </>
    )
  }

  return <SkeletonHeader loading={loading} />
}
