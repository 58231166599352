// packages
import { FC } from 'react'
import dayjs from 'dayjs'
// mantine
import { Badge, Loader } from '@mantine/core'

export type DeviceStatusBadgeProps = {}

export const DeviceStatusBadge: FC<DeviceStatusBadgeProps> = () => {
  const getStatus = () => {
    const hour = dayjs().get('h')

    if (hour < 8 || hour >= 18) return 'inactive'
    return 'active'
  }
  const status = getStatus()

  const colors: Record<typeof status, string> = {
    active: 'green',
    inactive: 'yellow',
    // error: 'red',
    // loading: 'gray',
  }

  const messages: Record<typeof status, string> = {
    active: 'On',
    inactive: 'Power saving mode',
  }

  const color = colors[status]

  if (!color) return <Badge color="red">{messages[status]}</Badge>

  // if (status === 'loading') return <Loader size="xs" color="gray" />

  return <Badge color={color}>{messages[status]}</Badge>
}
