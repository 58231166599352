// packages
import { useEffect, useRef, useState } from 'react'
// mantine
import { Card, Flex, Grid, Text, ThemeIcon, Timeline } from '@mantine/core'
import { ActivityHeartbeat, Check, ClockPause } from 'tabler-icons-react'

export const AnimalWelfareTigerTrainingProgram = () => {
  const videoRef = useRef(null)
  const [step, setStep] = useState(0)
  const [progress, setProgress] = useState(0)

  const handleProgress = (e: any) => {
    setProgress(e.target.currentTime)
  }

  useEffect(() => {
    if (progress > 10) {
      setStep(2)
    } else if (progress > 5) {
      setStep(1)
    } else {
      setStep(0)
    }
  }, [progress])

  return (
    <Card withBorder radius="sm">
      <Text size="sm" weight={700}>
        Training Program
      </Text>
      <Grid>
        <Grid.Col md={12} lg={4}>
          <Text my="md" weight={500}>
            Objective: Reduce tiger pacing
          </Text>
          <Timeline active={progress > 0 ? step : -1}>
            <Timeline.Item
              title={
                <Flex align="center" gap={5}>
                  <Text color={progress > 0 ? 'default' : 'dimmed'}>Cue</Text>
                  <ThemeIcon
                    color={progress > 0 ? 'green' : 'gray'}
                    variant={progress > 0 ? 'filled' : 'light'}
                    size="xs"
                    radius="xl"
                  >
                    {progress > 0 ? <Check /> : <ActivityHeartbeat />}
                  </ThemeIcon>
                </Flex>
              }
              bullet={progress > 0 ? <Check size="0.8rem" /> : <></>}
            >
              <Text color={progress > 0 ? 'default' : 'dimmed'} size="sm">
                Activate blinking red light and play alarm.mp3
              </Text>
              <Text size="xs" mt={4}>
                5 seconds
              </Text>
            </Timeline.Item>
            <Timeline.Item
              title={
                <Flex align="center" gap={5}>
                  <Text color={step > 0 ? 'default' : 'dimmed'}>Bridge</Text>
                  <ThemeIcon
                    color={step > 0 ? 'green' : 'gray'}
                    variant={step > 0 ? 'filled' : 'light'}
                    size="xs"
                    radius="xl"
                  >
                    {step > 0 ? <Check /> : <ActivityHeartbeat />}
                  </ThemeIcon>
                </Flex>
              }
              bullet={step >= 1 ? <Check size="0.8rem" /> : <></>}
            >
              <Text color={step >= 1 ? 'default' : 'dimmed'} size="sm">
                Activate green light and play ding.mp3
              </Text>
              <Text size="xs" mt={4}>
                5 seconds
              </Text>
            </Timeline.Item>
            <Timeline.Item
              title={
                <Flex align="center" gap={5}>
                  <Text color={step > 1 ? 'default' : 'dimmed'}>Reward</Text>
                  <ThemeIcon
                    color={step > 1 ? 'green' : 'gray'}
                    variant={step > 1 ? 'filled' : 'light'}
                    size="xs"
                    radius="xl"
                  >
                    {step > 1 ? <Check /> : <ActivityHeartbeat />}
                  </ThemeIcon>
                </Flex>
              }
              bullet={step >= 2 ? <Check size="0.8rem" /> : <></>}
            >
              <Text color={step >= 2 ? 'default' : 'dimmed'} size="sm">
                Release feed reward
              </Text>
              <Text size="xs" mt={4}>
                End
              </Text>
            </Timeline.Item>
          </Timeline>
        </Grid.Col>
        <Grid.Col md={12} lg={8}>
          <video
            ref={videoRef}
            onTimeUpdate={handleProgress}
            src={
              'https://munin-app.s3.ap-southeast-1.amazonaws.com/1.%20habitat%20csb%20demo.mp4?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEIv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaDmFwLXNvdXRoZWFzdC0xIkYwRAIgOEPmv9dPGcquhacxyLzM3B3GNg%2BCjkzvkThGVi96%2FGsCIFLBVC2LCnNYgMWc3IVUF2UBUj%2FIHWxgGyQxqEL4kbKAKu0CCIT%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQBRoMMDkxNTEzODk4MzcwIgxaI2ZzBNN6U7IX8O8qwQK2Jn5Ps7WpQjU0WAVdsOA7s6jjqAHoXdljqJRlANB3AlJO%2BEm0Cm2%2B7pFaM2EIm8s78f9bncsKfeacYj6%2F4pDygn9AqU2Ds%2FhK1UTpKRmkfZC35yDmtVdH7lV1hDJghenxbtLg8dxZdQjG1oR8MNbE2Jwz23E9mkrMFh603ous4CbWsYYQtc6JxzUn24XLutcewQGYeszeBzcVt4pybj7dPTMzt6Yf8eEU7nFwDahkbnRABSSXbse8zf%2BtfmVVopc2TJt9gGA2eiodxIgMj6mJqLEUKfPVtbv4IhnTIXvAjmyAl4KKJVA7caOTHsdDNSrOth98PXa4t5UHxow04KfXZIOG8T7C9Yb7eUcoUnKEcXnlPSXO%2BzjNOkM2btrArutkjWEdoEsh4YLIp7QjkGQOcduqX28f%2F06USBBwwg7S4rww%2FpKarwY6tAII2QKWIKZ1syadh75baNmDonIHzSto1EZ8%2B6bABCZnZ8EmpIorZg649yvMeMRJaDev7OeITMmMhqYwdDsmbzPjEf0rBDSMMfYhg0nn4NOCEon1l7zvEaICmMuzHURNRgYz%2BjGvos%2F5zXa9kNtlgvAjXMoSEvWjIyEC%2Faap3Q8gP4kHhP8OFujg01VmhGa7ph4Gpkte22h785Wv4obRJCLUZvBVS4%2BEqtQ97Ry%2FhNykO%2F5Pu58S9NJcjhmLeXWIZ0rjNpL74bOQ0tSoXNxu9%2BP6BqVOlTW6XC7MNo0zhWCgNRDnUfss6olrUZo7%2BaWTUvDo61E5I2WmmEMF%2FjcXZ%2B6tc0MvKDSQot7HjzlsnhoveBJIA15cZm93JCB0ZFyuPdLJNqH3paKE3WUFJ7i%2F0hX2s8xI8Q%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240305T025632Z&X-Amz-SignedHeaders=host&X-Amz-Expires=43200&X-Amz-Credential=ASIARKTVHIWBCO2HU5NZ%2F20240305%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Signature=cb920dca655c7f0b4dbf119883bd17e901c56c3632666860b8f29de4f3892dfb'
            }
            controls
            height={450}
            width="100%"
          />
        </Grid.Col>
      </Grid>
    </Card>
  )
}
