// packages
import { gql } from '@apollo/client'

export const GET_RECENT_ACTIVATIONS_QUERY = gql`
  query GetRecentActivations($sourceId: ObjectId!, $limit: Int) {
    getRecentActivations(sourceId: $sourceId, limit: $limit) {
      _id
      status
      requestBody
      createdAt
      scheduledAt
      user {
        _id
        name
      }
    }
  }
`
