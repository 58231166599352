import { Global, MantineTheme } from '@mantine/core'

/**
 * returns a {@link https://mantine.dev/styles/global-styles/ | Global Component} which is used in the MantineProvider in `_app.ts` to override styles.
 *
 * This function gives us access the the `theme` object after react render.
 * i.e. gives us access to things like light/dark mode, height/width hooks etc.
 *
 * @returns a {@link https://mantine.dev/styles/global-styles/ | Mantine Global component}
 *
 * @category Components
 */
export const AppStyles = () => {
  return (
    <Global
      styles={(_: MantineTheme) => ({
        body: {},
      })}
    />
  )
}
