// packages
import { useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import { FC } from 'react'
// mantine
import { Box, Flex, Skeleton, Stack } from '@mantine/core'
// gql
import { GET_ELEPHANT_FEED_PODS_SCHEDULE } from 'gql'
// Components
import { ElephantFeedPodsScheduleCancellation } from './Cancel'

export type ElephantFeedPodsScheduleByDateProps = {
  id: string
  date: Date
}

export const ElephantFeedPodsScheduleByDate: FC<ElephantFeedPodsScheduleByDateProps> = ({
  id,
  date,
}) => {
  const { data, loading, error } = useQuery(GET_ELEPHANT_FEED_PODS_SCHEDULE, {
    variables: { id, date },
  })
  if (data)
    if (data.getElephantFeedPodsSchedule.length != 0) {
      return (
        <>
          {data.getElephantFeedPodsSchedule.map((schedule: any, i: number) => (
            <Flex key={i} gap="md">
              <Box>{dayjs(schedule).add(-8, 'h').format('HH:mm')}</Box>
              <ElephantFeedPodsScheduleCancellation
                id={id}
                time={dayjs(schedule).add(-8, 'h').format('HH:mm')}
              />
            </Flex>
          ))}
        </>
      )
    } else {
      return <Box>No Scheduled Feed</Box>
    }

  return (
    <Stack spacing="sm">
      {Array(6)
        .fill(0)
        .map((_, i) => (
          <Skeleton h="1rem" w={180} key={i} />
        ))}
    </Stack>
  )
}
