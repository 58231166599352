// packages
import dayjs from 'dayjs'
// mantine
import { Button, Center, Group, Stack } from '@mantine/core'
import { useForm } from '@mantine/form'
import { DatePicker, TimeInput } from '@mantine/dates'
// icons
import { Clock } from 'tabler-icons-react'

export const DateAndTimePicker = ({
  activate,
  setFormOpened,
  loading,
}: {
  activate: any
  setFormOpened: any
  loading: boolean
}) => {
  const form = useForm({
    initialValues: {
      date: dayjs(),
      time: dayjs().format('HH:mm'),
    },
  })

  const submit = async (values: typeof form['values']) => {
    const sourceId =
      process.env.NODE_ENV == 'production' ? '63fc4c59cf7bd8b83e7cbf4d' : '63fb901ef0660328536f0485'

    const dateStr = dayjs(values.date).format('MMMM D, YYYY')
    const timeStr = dayjs('1/1/1 ' + values.time).format('h:mm:ss A')
    const str = `${dateStr} ${timeStr}`

    const at = dayjs(str).toISOString()

    const body = {
      topic:
        process.env.NODE_ENV === 'production' ? 'cmd/activate/zoo/malayantiger/csb' : 'zp_test',
      params: { devices: ['1'], iottype: 'feed', auth: 'test_token', at },
    }

    const { data, error } = await activate({
      variables: {
        sourceId,
        data: body,
      },
    })

    if (data.activateSource) setFormOpened(false)
  }

  return (
    <form onSubmit={form.onSubmit((values) => submit(values))}>
      <Stack spacing="sm">
        <Center>
          <Stack>
            <DatePicker
              {...form.getInputProps('date')}
              size="md"
              excludeDate={(date) => dayjs().add(-1, 'd').isAfter(date)}
            />
          </Stack>
        </Center>

        <Group position="center">
          <TimeInput
            label="Time"
            w={259}
            withSeconds={true}
            {...form.getInputProps('time')}
            icon={<Clock size="1rem" strokeWidth={1.5} />}
          />
        </Group>

        <Group position="right" mt="md">
          <Button type="submit" loading={loading}>
            Schedule
          </Button>
        </Group>
      </Stack>
    </form>
  )
}
