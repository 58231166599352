// packages
import dayjs from 'dayjs'
import { FC } from 'react'
import { useRouter } from 'next/router'
// mantine
import { Anchor, Box, Flex, Stack, Text } from '@mantine/core'
// Containers
import { ComponentContainer } from 'Containers'
import { DateRangePicker } from 'Components'
import { CSBTemperatureOverview } from './TemperatureOverview'
import Link from 'next/link'

export type CSBChillerDashboardContainerProps = {
  start?: Date
  end?: Date
}

export const CSBChillerDashboardContainer: FC<CSBChillerDashboardContainerProps> = ({
  start = dayjs().startOf('d').add(8, 'h').toDate(),
  end = dayjs().add(8, 'h').toDate(),
}) => {
  const router = useRouter()

  const min = new Date('2023-03-01T00:00:00.000Z')
  const max = dayjs().startOf('d').toDate()

  const routerChange = async (start: Date, end: Date) => {
    const endOfDay = dayjs(end).endOf('d').add(8, 'h').toISOString()

    await router.push(
      {
        pathname: '/dashboard/[...slug]',
        query: {
          slug: 'carnivore-smart-box/chiller',
          start: start.toISOString(),
          end: endOfDay,
        },
      },
      `/dashboard/carnivore-smart-box/chiller?start=${start.toISOString()}&end=${endOfDay}`,
    )
  }

  return (
    <Stack>
      <Flex justify="space-between" align="center" gap="md">
        <Box>
          <Anchor
            size="xs"
            color="dimmed"
            weight={600}
            transform="uppercase"
            underline={false}
            component={Link}
            href="/dashboard/carnivore-smart-box"
          >
            Carnivore Smart Box
          </Anchor>
          <Text weight={500} size="lg">
            Chiller Temperature Analysis
          </Text>
        </Box>

        <DateRangePicker
          min={min}
          max={max}
          start={start}
          end={end}
          routerChange={routerChange}
          defaultDate={end}
        />
      </Flex>

      <CSBTemperatureOverview start={start} end={end} />

      <ComponentContainer
        _id={
          process.env.NODE_ENV === 'production'
            ? '6411770e445eacb9cfa7db11'
            : '63fb901ef0660328536f0502'
        }
        height={300}
        params={{ start, end }}
        additionalProps={{ props: { verticalTicks: 5 } }}
      />
      <ComponentContainer
        _id={
          process.env.NODE_ENV === 'production'
            ? '6411770e445eacb9cfa7db21'
            : '64116a6683bd7814146957f7'
        }
        height={300}
        params={{ start, end }}
        additionalProps={{ props: { verticalTicks: 5 } }}
      />
    </Stack>
  )
}
