// packages
import { useQuery } from '@apollo/client'
import { FC } from 'react'
// mantine
import { Button, Center, Loader } from '@mantine/core'
// gql
import { GET_BUTTON_LINK_QUERY } from 'gql'
// Component
import { ButtonLink } from 'Components'
import { PropsOf } from '@emotion/react'

export type ButtonLinkContainerProps = {
  _id: string
  props?: PropsOf<typeof Button>
  height?: number
}

export const ButtonLinkContainer: FC<ButtonLinkContainerProps> = ({ _id, props, height }) => {
  const { data, error } = useQuery(GET_BUTTON_LINK_QUERY, {
    variables: { _id },
  })

  if (data)
    if (data.getButtonLink) {
      const { label, url, newTab, props: componentProps } = data.getButtonLink

      return (
        <ButtonLink
          label={label}
          url={url}
          newTab={newTab}
          props={{ ...componentProps, ...props }}
        />
      )
    }

  return (
    <Center style={{ height }}>
      <Loader />
    </Center>
  )
}
