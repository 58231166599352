import { gql } from '@apollo/client'

export const ACCEPT_INVITATION_MUTATION = gql`
  mutation AcceptInvitation($params: InvitationInput!) {
    acceptInvitation(params: $params) {
      _id
      token
    }
  }
`
