// packages
import { FC, useState } from 'react'
// mantine
import { Switch, SwitchProps } from '@mantine/core'
// props
import { BaseInputProps } from './baseInputProps'

/**
 * Props for SwitchInput Component
 */
export type SwitchInputWrapperComponentProps = {
  /**
   * A custom mapping of mapping which the user can pass in.
   *
   * There is logic to map the checkbox selection with the custom mapping which the user wants.
   *
   * The unchecked state will map to the first value, and the checked state will map to the second value in the array.
   *
   * @defaultValue - [false, true] // will just be the basic false if unchecked, true if checked.
   *
   * @example
   * If we want the checkbox to return form value 0 for unchecked and 1 for checked, we will set mapping to [0, 1]
   */
  mapping?: [unknown, unknown]
} & SwitchProps

type SwitchInputWrapperProps = {
  props: SwitchInputWrapperComponentProps
} & BaseInputProps

/**
 * Wrapper for Switch Component
 *
 * @see [Mantine Switch](https://mantine.dev/core/switch)
 */
export const SwitchInputWrapper: FC<SwitchInputWrapperProps> = ({ form, fieldName, props }) => {
  const { mapping = [false, true] } = props
  const [selected, setSelected] = useState(form.values[fieldName] == mapping[1])

  const setFormValue = (event: any) => {
    form.setValues({ [fieldName]: event.currentTarget.checked ? mapping[1] : mapping[0] })
    setSelected(event.currentTarget.checked)
  }

  return <Switch {...props} checked={selected} onChange={setFormValue} />
}
