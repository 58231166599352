// packages
import { gql } from '@apollo/client'

export const GET_COMPONENT_QUERY = gql`
  query GetComponent($_id: ObjectId!) {
    getComponent(_id: $_id) {
      _id
      name
      variant
      creator {
        _id
      }
    }
  }
`
