// packages
import { FC, useState } from 'react'
import { useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import { useRouter } from 'next/router'
// mantine
import { Anchor, Box, Card, Flex, Paper, ScrollArea, Stack, Text, ThemeIcon } from '@mantine/core'
// gql
import { GET_CSB_FEEDS_QUERY, GET_CSB_TIGER_RECOGNITION_VIDEOS } from 'gql'
// Components
import { SingleDatePicker, TimeSlider } from 'Components'
import { CSBTigerRecognitionFootagesVideoPlayer } from './VideoPlayer'
// icons
import { Meat, Video } from 'tabler-icons-react'

export type CSBDayAnalysisContainerProps = {
  start: Date
}

export const CSBDayAnalysisContainer: FC<CSBDayAnalysisContainerProps> = ({ start }) => {
  const [end, setEnd] = useState(dayjs(start).endOf('d').add(8, 'h').toDate())
  const [id, setId] = useState('')
  const router = useRouter()

  const onChange = async (date: Date) => {
    const d = dayjs(date).add(8, 'h')

    await router.push(
      {
        pathname: '/dashboard/[...slug]',
        query: {
          slug: 'carnivore-smart-box/daily',
          start: d.toISOString(),
        },
      },
      `/dashboard/carnivore-smart-box/daily?start=${d.toISOString()}`,
    )
  }

  const {
    data: feedsData,
    loading: feedsLoading,
    error: feedsError,
  } = useQuery(GET_CSB_FEEDS_QUERY, {
    variables: { start, end },
  })

  const {
    data: videoData,
    loading: videoLoading,
    error: videoError,
  } = useQuery(GET_CSB_TIGER_RECOGNITION_VIDEOS, {
    variables: { start, end },
  })

  return (
    <Stack>
      <Paper py={3} style={{ position: 'sticky', top: -1, zIndex: 99 }}>
        <Flex justify="space-between">
          <Text size="xl" weight={500}>
            CSB Day Analysis
          </Text>
          <SingleDatePicker
            date={start}
            onChange={onChange}
            minDate={dayjs('2023-03-01').toDate()}
            maxDate={dayjs('2023-03-31').toDate()}
          />
        </Flex>
      </Paper>
      {/* <Box> */}
      {/*   {start.toISOString()} - {end.toISOString()} */}
      {/* </Box> */}
      {feedsData && feedsData.getCSBFeeds && videoData && videoData.getCSBTigerRecognitionVideos && (
        <Card withBorder radius="md">
          <Text size="lg" weight={500} mb="md">
            Overview
          </Text>
          <Flex gap="md">
            <Box>
              <Text>{feedsData.getCSBFeeds.length}</Text>
              <Text>Feed(s)</Text>
            </Box>
            <Box>
              <Text>{videoData.getCSBTigerRecognitionVideos.length}</Text>
              <Text>Footage(s)</Text>
            </Box>
          </Flex>
        </Card>
      )}
      {feedsData &&
        feedsData.getCSBFeeds &&
        videoData &&
        videoData.getCSBTigerRecognitionVideos && (
          <FeedsTimeSlider
            start={start}
            end={end}
            feeds={feedsData.getCSBFeeds}
            videos={videoData.getCSBTigerRecognitionVideos}
          />
        )}
      {videoData && videoData.getCSBTigerRecognitionVideos && (
        <Card withBorder radius="md">
          <Text size="lg" weight={500}>
            Footages
          </Text>
          <Flex justify="space-between">
            <ScrollArea h={250} pr="md">
              {videoData.getCSBTigerRecognitionVideos.map((video: any, i: number) => (
                <Box key={video._id}>
                  <Anchor onClick={() => setId(video._id)}>
                    {i + 1} - {dayjs(video.recordedAt).add(-8, 'h').format('h:mm:ss a')}
                  </Anchor>
                </Box>
              ))}
            </ScrollArea>
            {id != '' && <CSBTigerRecognitionFootagesVideoPlayer _id={id} />}
          </Flex>
        </Card>
      )}
    </Stack>
  )
}

const FeedsTimeSlider = ({
  start,
  end,
  feeds,
  videos,
}: {
  start: any
  end: any
  feeds: any[]
  videos: any[]
}) => {
  const marks = feeds.map((event) => {
    const v = dayjs(event.created).diff(dayjs(start), 's')
    return {
      value: v,
      label: (
        <ThemeIcon size="sm" variant="light" radius="xl" color="orange">
          <Meat size={16} strokeWidth={1.5} />
        </ThemeIcon>
      ),
    }
  })

  for (const video of videos) {
    const v = dayjs(video.recordedAt).diff(dayjs(start), 's')
    marks.push({
      value: v,
      label: (
        <ThemeIcon size="sm" variant="light" radius="xl" color="pink">
          <Video size={16} strokeWidth={1.5} />
        </ThemeIcon>
      ),
    })
  }

  return (
    <Card withBorder radius="md">
      <Text weight={500} size="lg" mb="md">
        Timeline
      </Text>
      <Box h={60}>
        <TimeSlider start={start} end={end} marks={marks} />
      </Box>
    </Card>
  )
}
