// package
import { gql } from '@apollo/client'

export const GET_CHART_PROGRESS_LINE_QUERY = gql`
  query getChartProgressLine($_id: ObjectId!) {
    getChartProgressLine(_id: $_id) {
      _id
      props
      dataProps
      data {
        _id
      }
      transformer {
        field
        type
      }
    }
  }
`
