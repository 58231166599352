// packages
import { FC } from 'react'
// mantine
import { ActionIcon, Popover, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
// icons
import { PlayerPause } from 'tabler-icons-react'

export type PulsesListTableRowPauseActionIconProps = {
  _id: string
}

export const PulsesListTableRowPauseActionIcon: FC<PulsesListTableRowPauseActionIconProps> = ({
  _id,
}) => {
  const [opened, { close, open }] = useDisclosure(false)

  return (
    <Popover withArrow opened={opened} shadow="xs">
      <Popover.Target>
        <ActionIcon
          size="xs"
          style={{ cursor: 'pointer' }}
          onMouseEnter={open}
          onMouseLeave={close}
        >
          <PlayerPause />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown sx={{ pointerEvents: 'none' }}>
        <Text size="xs">Pause</Text>
      </Popover.Dropdown>
    </Popover>
  )
}
