// packages
import { gql } from '@apollo/client'

export const GET_PULSES_LIST_QUERY = gql`
  query GetPulsesList($_id: ObjectId!) {
    getPulsesList(_id: $_id) {
      _id
      pulses {
        _id
      }
    }
  }
`
