import { useQuery } from '@apollo/client'
import { Box } from '@mantine/core'
import { DynamicReactPlayer } from 'Components'
import { GET_CSB_TIGER_RECOGNITION_VIDEO_URL } from 'gql'
import { useIsMobile } from 'hooks'
import { FC } from 'react'

export type CSBTigerRecognitionFootagesVideoPlayerProps = {
  _id: string
}

export const CSBTigerRecognitionFootagesVideoPlayer: FC<CSBTigerRecognitionFootagesVideoPlayerProps> =
  ({ _id }) => {
    const isMobile = useIsMobile()
    const { data, loading, error } = useQuery(GET_CSB_TIGER_RECOGNITION_VIDEO_URL, {
      variables: { _id },
    })

    if (data)
      if (data.getCSBTigerRecognitionVideoURL) {
        return (
          <Box>
            <DynamicReactPlayer
              url={data.getCSBTigerRecognitionVideoURL}
              controls
              height={240}
              width={isMobile ? '100%' : 240 * 1.25}
            />
          </Box>
        )
      }

    return <Box>No result </Box>
  }
