// packages
import { FC } from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
// mantine
import { Text } from '@mantine/core'
// Components
import { RelativeDuration } from 'Components'

dayjs.extend(relativeTime)

type ActivationsCardLastActivityIndicatorProps = {
  recentActivation?: { scheduledAt: string }
}

export const ActivationsCardLastActivityIndicator: FC<ActivationsCardLastActivityIndicatorProps> =
  ({ recentActivation }) => {
    return recentActivation ? (
      <Text size="xs" color="dimmed">
        Last activity: <RelativeDuration timestamp={recentActivation.scheduledAt} />
      </Text>
    ) : (
      <></>
    )
  }
