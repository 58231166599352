// packages
import { NextRouter } from 'next/router'
// mantine
import { SpotlightAction } from '@mantine/spotlight'
// icons
import { Camera, Dashboard, Home, Meat } from 'tabler-icons-react'

export const getSpotlightActions = (router: NextRouter): SpotlightAction[] => [
  {
    title: 'Home',
    description: 'Get to home page',
    onTrigger: () => console.log('Home'),
    icon: <Home size="1.2rem" />,
  },
  {
    title: 'Dashboard',
    description: 'Get full information about zoo status',
    onTrigger: () => console.log('Dashboard'),
    icon: <Dashboard size="1.2rem" />,
  },
  {
    title: 'Tiger',
    description: 'See the current status of the tiger',
    onTrigger: () => console.log('Dashboard'),
    icon: <Dashboard size="1.2rem" />,
  },
  {
    title: 'Motion Sensor Camera (Tiger)',
    description: 'View video footages of tiger activities',
    onTrigger: () => console.log('Dashboard'),
    icon: <Camera size="1.2rem" />,
  },
  {
    title: 'Carnivore Smart Box (CSB) Dashboard',
    description: 'View and Control Carnivore Smart Box (CSB)',
    onTrigger: () => router.push('/dashboard/carnivore-smart-box'),
    icon: <Meat size="1.2rem" />,
  },
]
