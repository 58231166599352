// mantine
import { MantineSize, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
// components

/**
 * hook to check if the current viewport is larger than specified param
 *
 * @param size a mantine size value
 * - xs
 * - sm
 * - md
 * - lg
 * - xl
 *
 * @returns true if screen is larger than specified param
 *
 * @group Hooks
 * @category Hooks
 */
export const useScreenIsLargerThan = (size: MantineSize): boolean => {
  const theme = useMantineTheme()

  return useMediaQuery(`(min-width: ${theme.breakpoints[size]})`)
}

export const useIsMobile = () => !useScreenIsLargerThan('md')
