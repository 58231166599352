// packages
import { FC } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
// mantine
import { ActionIcon, Popover, Text } from '@mantine/core'
// icons
import { Calendar, History } from 'tabler-icons-react'
import { useDisclosure } from '@mantine/hooks'

export type ElephantFeedPodsDeviceRedirectIconProps = {
  id: string
  type: 'Schedule' | 'Recent_Feeds'
}

export const ElephantFeedPodsDeviceRedirectIcon: FC<ElephantFeedPodsDeviceRedirectIconProps> = ({
  id,
  type,
}) => {
  const router = useRouter()
  const [opened, { close, open }] = useDisclosure(false)

  const schedulePath = `/dashboard/elephant-feedpods/${id}/schedule`
  const isSchedulePath = router.asPath === schedulePath

  const recentFeedsPath = `/dashboard/elephant-feedpods/${id}/recent_feeds`
  const isRecentFeedsPath = router.asPath === recentFeedsPath

  const path = type === 'Schedule' ? schedulePath : recentFeedsPath
  const isPath = type === 'Schedule' ? isSchedulePath : isRecentFeedsPath

  return (
    <Popover opened={opened} shadow="xs" withArrow withinPortal>
      <Popover.Target>
        <ActionIcon
          variant={isPath ? 'light' : 'subtle'}
          color={isPath ? 'blue' : 'gray'}
          component={Link}
          href={path}
          style={{ cursor: 'pointer' }}
          onMouseEnter={open}
          onMouseLeave={close}
        >
          {type === 'Schedule' ? <Calendar size="1.125rem" /> : <History size="1.125rem" />}
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown sx={{ pointerEvents: 'none' }}>
        <Text size="sm">{type === 'Schedule' ? 'Schedule' : 'Recent Feeds'}</Text>
      </Popover.Dropdown>
    </Popover>
  )
}
