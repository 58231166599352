import { Flex, Popover, Text } from '@mantine/core'
import { DatePicker, DatePickerProps } from '@mantine/dates'
import dayjs from 'dayjs'
import { FC, useState } from 'react'
import { ChevronDown, ChevronUp } from 'tabler-icons-react'

export type SingleDatePickerProps = {
  date: Date
  onChange: (date: Date) => void
} & DatePickerProps

export const SingleDatePicker: FC<SingleDatePickerProps> = ({ date, onChange, ...props }) => {
  const [opened, setOpened] = useState(false)

  return (
    <Popover withArrow withinPortal position="bottom" opened={opened} onChange={setOpened}>
      <Popover.Target>
        <Flex
          align="center"
          gap={3}
          onClick={() => setOpened((o) => !o)}
          style={{ cursor: 'pointer' }}
        >
          <Text>{dayjs(date).format('D MMM YYYY')}</Text>
          {opened ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
        </Flex>
      </Popover.Target>
      <Popover.Dropdown>
        <DatePicker defaultDate={date} value={date} onChange={onChange} {...props} />
      </Popover.Dropdown>
    </Popover>
  )
}
