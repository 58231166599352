// packages
import { FC } from 'react'
import { useQuery } from '@apollo/client'
// mantine
import { Skeleton } from '@mantine/core'
// gql
import { GET_CHART_SPARK_QUERY } from 'gql'
// Data Layer
import { ChartDataLayer } from '../DataLayer'
// Components
import { SparkChartCard } from 'Components'

export type ChartSparkContainerProps = {
  _id: string
  height: number
  params?: object
  props?: object
}

export const ChartSparkContainer: FC<ChartSparkContainerProps> = ({
  _id,
  height,
  params = {},
  props = {},
}) => {
  const { data, loading, error } = useQuery(GET_CHART_SPARK_QUERY, {
    variables: { _id },
  })

  if (data)
    if (data.getChartSpark) {
      const {
        data: { _id: dataId },
        props: chartProps,
      } = data.getChartSpark

      const combinedProps = { ...chartProps, ...props }

      return (
        <ChartDataLayer
          componentId={_id}
          Component={SparkChartCard}
          dataId={dataId}
          intervalPeriod={15000}
          height={height}
          props={combinedProps}
          params={params}
        />
      )
    }

  return <Skeleton radius="sm" style={{ height }} />
}
