// packages
import { Dispatch, FC, SetStateAction } from 'react'
import { useMutation } from '@apollo/client'
import dayjs from 'dayjs'
// mantine
import { DatePicker, TimeInput } from '@mantine/dates'
import { useForm } from '@mantine/form'
import { Button, Center, Group, Stack } from '@mantine/core'
// gql
import { ACTIVATE_ACTION_MUTATION } from 'gql'
// icons
import { Clock } from 'tabler-icons-react'

export type ActivateActionFormProps = {
  setOpened: Dispatch<SetStateAction<boolean>>
  _id: string
}

export const ActivateActionForm: FC<ActivateActionFormProps> = ({ _id, setOpened }) => {
  const [activate, { loading }] = useMutation(ACTIVATE_ACTION_MUTATION)

  const form = useForm({
    initialValues: {
      date: new Date(),
      time: dayjs().format('h:mm'),
    },
  })

  const submit = async (values: typeof form['values']) => {
    const dateStr = dayjs(values.date).format('MMMM D, YYYY')
    const timeStr = dayjs('1/1/1 ' + values.time).format('h:mm:ss A')
    const str = `${dateStr} ${timeStr}`

    const at = dayjs(str).toISOString()

    const { data } = await activate({ variables: { actionId: _id, at } })

    if (data.activateAction) setOpened(false)
  }

  return (
    <form onSubmit={form.onSubmit((values) => submit(values))}>
      <Stack spacing="sm">
        <Center>
          <Stack>
            <DatePicker {...form.getInputProps('date')} />
          </Stack>
        </Center>
        <Group position="center">
          <TimeInput
            label="Time"
            w={259}
            {...form.getInputProps('time')}
            icon={<Clock size="1rem" strokeWidth={1.5} />}
          />
        </Group>

        <Group position="right" mt="md">
          <Button type="submit" loading={loading}>
            Submit
          </Button>
        </Group>
      </Stack>
    </form>
  )
}
