// packages
import { FC } from 'react'
// mantine
import { ActionIcon, Flex } from '@mantine/core'
import { spotlight } from '@mantine/spotlight'
// Components
import { HeaderTitle } from './Title'
// styles
import useStyles from './styles'
// icons
import { Atom2 } from 'tabler-icons-react'

type AppLayoutHeaderProps = { height: number; leftSideBarWidth: number }

export const AppLayoutHeader: FC<AppLayoutHeaderProps> = ({ height, leftSideBarWidth }) => {
  const { classes } = useStyles()

  return (
    <Flex h={height} justify="space-between" className={classes.header}>
      <div style={{ height, width: leftSideBarWidth }}>
        <HeaderTitle />
      </div>
      <Flex align="center" justify="space-between" mr="md">
        <ActionIcon variant="transparent" onClick={() => spotlight.open()}>
          <Atom2 size="1.125rem" />
        </ActionIcon>
      </Flex>
    </Flex>
  )
}
