// packages
import { gql } from '@apollo/client'

export const UPDATE_PASSWORD_BY_RESET_TOKEN_MUTATION = gql`
  mutation UpdatePasswordByResetToken($params: UpdatePasswordByResetTokenInput!) {
    updatePasswordByResetToken(params: $params) {
      _id
      email
      token
    }
  }
`
