// packages
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
// mantine
import { Anchor, Box, Checkbox, Collapse, Flex, SimpleGrid } from '@mantine/core'

export type ElephantFeedPodsScheduleDayCheckboxesProps = {
  days: number[]
  setDays: Dispatch<SetStateAction<number[]>>
}

export const ElephantFeedPodsScheduleDayCheckboxes: FC<ElephantFeedPodsScheduleDayCheckboxesProps> =
  ({ days, setDays }) => {
    const [showDays, setShowDays] = useState(false)

    const [mon, setMon] = useState(true)
    const [tues, setTues] = useState(true)
    const [weds, setWeds] = useState(true)
    const [thurs, setThurs] = useState(true)
    const [fri, setFri] = useState(true)
    const [sat, setSat] = useState(true)
    const [sun, setSun] = useState(true)

    useEffect(() => {
      setDays(
        [mon, tues, weds, thurs, fri, sat, sun]
          .map((day, i) => (day ? i + 1 : null))
          .filter((day) => day !== null) as number[],
      )
    }, [mon, tues, weds, thurs, fri, sat, sun, setDays])

    return (
      <>
        <Box mb="sm">
          <Flex gap="sm">
            <Checkbox
              label={days.length != 7 ? 'Custom' : 'Everyday'}
              indeterminate={days.length != 7}
              checked={days.length == 7}
              onChange={({ currentTarget }) => {
                setShowDays(true)
                if (currentTarget.checked) {
                  setMon(true)
                  setTues(true)
                  setWeds(true)
                  setThurs(true)
                  setFri(true)
                  setSat(true)
                  setSun(true)
                }
              }}
            />
            <Anchor size="sm" onClick={() => setShowDays((o) => !o)}>
              {showDays ? 'Collapse' : 'Customise'}
            </Anchor>
          </Flex>
        </Box>

        <Collapse in={showDays}>
          <SimpleGrid
            cols={3}
            breakpoints={[
              { maxWidth: '48rem', cols: 2, spacing: 'sm' },
              { maxWidth: '36rem', cols: 1, spacing: 'sm' },
            ]}
          >
            <Checkbox
              label="Monday"
              checked={mon}
              onChange={(event) => setMon(event.currentTarget.checked)}
            />
            <Checkbox
              label="Tuesday"
              checked={tues}
              onChange={(event) => setTues(event.currentTarget.checked)}
            />
            <Checkbox
              label="Wednesday"
              checked={weds}
              onChange={(event) => setWeds(event.currentTarget.checked)}
            />
            <Checkbox
              label="Thursday"
              checked={thurs}
              onChange={(event) => setThurs(event.currentTarget.checked)}
            />
            <Checkbox
              label="Friday"
              checked={fri}
              onChange={(event) => setFri(event.currentTarget.checked)}
            />
            <Checkbox
              label="Saturday"
              checked={sat}
              onChange={(event) => setSat(event.currentTarget.checked)}
            />
            <Checkbox
              label="Sunday"
              checked={sun}
              onChange={(event) => setSun(event.currentTarget.checked)}
            />
          </SimpleGrid>
        </Collapse>
      </>
    )
  }
