// packages
import dayjs from 'dayjs'
// mantine
import { Box, Flex, Image, SimpleGrid, Stack, Text } from '@mantine/core'

export const AviaryMeshContainer = () => {
  return (
    <Stack>
      <Text size="xl" weight={500}>
        Aviary Mesh Surveillance Dashboard
      </Text>

      <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1, spacing: 'sm' }]}>
        <Stack>
          <SimpleGrid cols={2}>
            <Stack spacing={5}>
              <Text weight={500} size="lg">
                Zone
              </Text>
              <ActiveIndicator label="1" />
              <ActiveIndicator label="2" />
              <ActiveIndicator label="3" />
              <ActiveIndicator label="4" />
              <ActiveIndicator label="5" active={false} />
              <ActiveIndicator label="6" />
            </Stack>

            <Box>
              <Box>Last Updated at {dayjs().format('h:mma')}</Box>
              <Box>Next Update at {dayjs().add(2, 'h').format('h:mma')}</Box>
            </Box>
          </SimpleGrid>
          <SimpleGrid cols={2}>
            <Box>
              <Text>Mesh Status</Text>
              <Text color="red" size="lg" weight={500}>
                Sensor Error
              </Text>
            </Box>
            <Box>
              <Text color="dimmed" weight={500}>
                System Log
              </Text>
              <Text>Unable to detect sensor 66 from zone 5.</Text>
            </Box>
          </SimpleGrid>
        </Stack>

        <Box>
          <Image w="100%" src="/Aviary Mesh Dashboard.PNG" alt="Aviary Mesh Image" />
        </Box>
      </SimpleGrid>
    </Stack>
  )
}

export const ActiveIndicator = ({ active = true, label }: { active?: boolean; label: string }) => {
  return (
    <Flex gap="xs" align="center">
      <Box
        h={12}
        w={12}
        sx={(theme) => ({ backgroundColor: active ? theme.colors.green[6] : theme.colors.red[6] })}
      />
      <Text color="dimmed" size="sm">
        {label}
      </Text>
    </Flex>
  )
}
