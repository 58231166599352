// mantine
import { Button } from '@mantine/core'
// Components
import { ResponsiveDrawerMenu } from 'Components'
// icons
import { ChevronDown } from 'tabler-icons-react'

export const TigerAnimalWelfareActions = () => {
  return (
    <ResponsiveDrawerMenu
      drawerTitle="Tiger Actions Menu"
      TargetComponent={<Button rightIcon={<ChevronDown />}>Actions</Button>}
      links={[
        { label: 'Schedule', type: 'title' },
        {
          label: 'Feed',
          iconName: 'Meat',
          modalId: '63fc4c59cf7bd8b83e7cbf66',
          redirectType: 'modal',
        },
        { label: 'Activate', type: 'title' },
        {
          label: 'Light',
          iconName: 'Bulb',
          modalId: '63fc4c59cf7bd8b83e7cbf66',
          redirectType: 'modal',
        },
        {
          label: 'Sound',
          iconName: 'Speakerphone',
          modalId: '63fc4c59cf7bd8b83e7cbf82',
          redirectType: 'modal',
        },
        {
          label: 'Scent',
          iconName: 'Wifi',
          modalId: '63fc4c59cf7bd8b83e7cbf72',
          redirectType: 'modal',
        },
        { label: 'Settings', type: 'title' },
        {
          label: 'Lock/Unlock',
          iconName: 'LockAccess',
          modalId: '63fc4c59cf7bd8b83e7cbf8e',
          redirectType: 'modal',
        },
        {
          label: 'Reset Feed',
          iconName: 'Refresh',
          modalId: '63fd60d01c2688d4e1898f25',
          redirectType: 'modal',
        },
        { label: 'Feeder', type: 'title' },
        {
          label: 'CSB Devices',
          iconName: 'LayoutDashboard',
          redirectType: 'href',
          href: '/dashboard/carnivore-smart-box',
        },
        {
          label: 'Chiller Analysis',
          iconName: 'Temperature',
          redirectType: 'href',
          href: '/dashboard/carnivore-smart-box/chiller',
        },
      ]}
    />
  )
}
