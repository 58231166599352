// packages
import { FC } from 'react'
// mantine
import { Timeline } from '@mantine/core'
// Components
import { IconProps } from 'Components'
import { ActivationsBody } from './Body'
import { ActivationsBullet } from './Bullet'
// styles
import useStyles from './styles'

// TODO: this should pull from package
export type ActivationType = {
  _id: string
  requestBody: any
  username: string
  color: string
  iconName: IconProps['name']
  status: string
  createdAt: string
  scheduledAt: string
}

/**
 * Props for Activations
 */
export type ActivationsProps = {
  // TODO: use enum package for status
  activations: ActivationType[]
  relative?: boolean
  props?: any
}

/**
 * Shows a list of Activations using a timeline like visualisation.
 *
 * @param props - ActivationsProps
 *
 * @returns Activations - Component showing a timeline of recent activities.
 */
export const Activations: FC<ActivationsProps> = ({ activations, relative = true, props }) => {
  const { classes } = useStyles()

  return (
    <Timeline bulletSize={38} lineWidth={0.5} classNames={{ itemBody: classes.itemBody }}>
      {activations.map(
        ({ _id, requestBody, username, color, iconName, status, createdAt, scheduledAt }, i) => (
          <Timeline.Item
            key={i}
            color={color}
            active={false}
            lineActive={false}
            lineVariant="dashed"
            bullet={<ActivationsBullet status={status} iconName={iconName} color={color} />}
          >
            <ActivationsBody
              _id={_id}
              status={status}
              username={username}
              requestBody={requestBody}
              relative={relative}
              createdAt={createdAt}
              scheduledAt={scheduledAt}
            />
          </Timeline.Item>
        ),
      )}
    </Timeline>
  )
}
