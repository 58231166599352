// packages
import { useQuery } from '@apollo/client'
import { NextPage } from 'next'
import { useRouter } from 'next/router'
// _app
import { NextPageWithLayout } from 'pages/_app'
// gql
import { GET_CURRENT_USER_QUERY } from 'gql'
// localStorage
import { getAuthToken } from 'localStorage'

export function withRedirectIfAuth({
  Component,
}: {
  Component: NextPageWithLayout
  location: string
}): NextPageWithLayout {
  const WithRedirectIfAuthWrapper: NextPageWithLayout = (props) => {
    const router = useRouter()
    const { data, loading, error } = useQuery(GET_CURRENT_USER_QUERY)

    if (loading || error) return <></>

    const isAuthenticated = !!getAuthToken() || (data && data.getCurrentUser)

    if (isAuthenticated) {
      const { redirect = '/' } = router.query
      router.push({ pathname: redirect as string })
      return <></>
    } else {
      return <Component {...props} />
    }
  }

  WithRedirectIfAuthWrapper.getLayout = Component.getLayout
  return WithRedirectIfAuthWrapper
}
