// mantine
import { MantineSize, px, rem, useMantineTheme } from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'

/**
 * hook to get the current breakpoint nearest to the viewport width
 *
 * @returns current breakpoint in mantine size format:
 * - xs
 * - sm
 * - md
 * - lg
 * - xl
 *
 * @group Hooks
 * @category Hooks
 */
export const useBreakpoint = (): MantineSize => {
  // get the current viewport width
  const { width } = useViewportSize()

  // get break points from mantine theme
  const theme = useMantineTheme()
  const breakpoints = Object.keys(theme.breakpoints) as MantineSize[]

  // loop through each breakpoint which checking against the view port width
  for (const breakpoint of breakpoints) {
    const queryWidth = theme.breakpoints[breakpoint]
    const breakpointWidth = px(queryWidth.replace('em', 'rem'))
    if (breakpointWidth > width) return breakpoint
  }

  // return the last breakpoint (probably xl) if screen is larger than all breakpoints
  return breakpoints[breakpoints.length - 1]
}
