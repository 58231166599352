// packages
import { gql } from '@apollo/client'

export const GET_ACTIONS_QUERY = gql`
  query GetActions($ids: [ObjectId!]!) {
    getActions(_ids: $ids) {
      _id
      name
      steps {
        _id
        name
        delay
      }
    }
  }
`
