// packages
import { FC } from 'react'
// mantine
import { Flex } from '@mantine/core'
// Components
import { PulsesListTableRowTargetUsersActionIcon } from './TargetUsers'
import { PulsesListTableRowPauseActionIcon } from './Pause'
import { PulsesListTableRowDeleteActionIcon } from './Delete'

export type PulsesListTableRowActionsProps = {
  _id: string
}

export const PulsesListTableRowActions: FC<PulsesListTableRowActionsProps> = ({ _id }) => {
  return (
    <Flex gap="xs" align="center">
      <PulsesListTableRowTargetUsersActionIcon _id={_id} />
      <PulsesListTableRowPauseActionIcon _id={_id} />
      <PulsesListTableRowDeleteActionIcon _id={_id} />
    </Flex>
  )
}
