// packages
import { FC } from 'react'
// mantine
import { Select, SelectProps } from '@mantine/core'
// props
import { BaseInputProps } from './baseInputProps'

/**
 * Props for SelectInput
 */
export type SelectInputWrapperProps = {
  props: SelectProps
} & BaseInputProps

/**
 * Wrapper for Select Component
 *
 * @see [Mantine Select](https://mantine.dev/core/select/)
 */
export const SelectInputWrapper: FC<SelectInputWrapperProps> = ({ form, fieldName, props }) => {
  return (
    <Select
      styles={{
        label: { marginBottom: 5 },
      }}
      {...props}
      {...form.getInputProps(fieldName)}
    />
  )
}
