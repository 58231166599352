// packages
import { FC, Dispatch, SetStateAction } from 'react'
// mantine
// Components
import { ProgressBar } from './ProgressBar'

type ProgressBarsProps = {
  values: any
  setMetric: Dispatch<SetStateAction<string>>
}

export const ProgressBars: FC<ProgressBarsProps> = ({ values, setMetric }) => {
  const keys = Object.keys(values)

  return (
    <div>
      {keys.map((key, i) => {
        const value = values[key]

        return (
          <div key={i} style={{ marginBottom: i == keys.length - 1 ? 0 : 30 }}>
            <ProgressBar
              name={key}
              min={value.min}
              max={value.max}
              value={value.value}
              unit={value.unit}
              rangeType={value.rangeType}
              color={value.color}
              setMetric={setMetric}
            />
          </div>
        )
      })}
    </div>
  )
}
