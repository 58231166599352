// packages
import { FC } from 'react'
import { useQuery } from '@apollo/client'
// mantine
import { Button } from '@mantine/core'
// gql
import { GET_CSB_BUCKET_QUERY } from 'gql'

type CSBFootageDownloadLinkProps = {
  filename: string
}

export const CSBFootageDownloadLink: FC<CSBFootageDownloadLinkProps> = ({ filename }) => {
  const { data } = useQuery(GET_CSB_BUCKET_QUERY, {
    variables: { filename },
  })

  if (data)
    if (data.getCSBBucket) {
      return (
        <Button component="a" target="_blank" href={data.getCSBBucket} download>
          Download
        </Button>
      )
    }

  return <></>
}
