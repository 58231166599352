// mantine
import { Dispatch, FC, SetStateAction } from 'react'
import { Box, Divider, Text } from '@mantine/core'
import { useLocalStorage } from '@mantine/hooks'
// styles
import useStyles from './styles'
// localStorage
import { leftSideBarLocalStorageProps } from 'localStorage'
// Components
// import { LeftSideBarContentLinksList } from './List'
import { LeftSideBarContentDemoLinks } from './Demo'

export type LeftSideBarContentLinksProps = {
  setDrawerOpened: Dispatch<SetStateAction<boolean>>
}

export const LeftSideBarContentLinks: FC<LeftSideBarContentLinksProps> = ({ setDrawerOpened }) => {
  const { classes } = useStyles()
  const [expanded] = useLocalStorage<boolean>(leftSideBarLocalStorageProps)

  return (
    <>
      {expanded && (
        <Text weight={700} size={12} transform="uppercase" className={classes.header}>
          Animal Care
        </Text>
      )}
      <LeftSideBarContentDemoLinks
        links={[
          {
            slug: '/animal-welfare/tiger',
            icon: 'ChartInfographic',
            name: 'Tiger',
          },
          {
            slug: '/animal-welfare/elephant',
            icon: 'DeviceHeartMonitor',
            name: 'Elephant',
          },
        ]}
        setDrawerOpened={setDrawerOpened}
      />
      {expanded && (
        <Text weight={700} size={12} transform="uppercase" className={classes.header} mt={15}>
          Dashboard
        </Text>
      )}
      <Box mt={5}>
        <LeftSideBarContentDemoLinks
          links={[
            {
              slug: '/dashboard/carnivore-smart-box',
              icon: 'Meat',
              name: 'Carnivore Smart Box',
            },
            {
              slug: '/dashboard/elephant-feedpods',
              icon: 'TargetArrow',
              name: 'Elephant Feedpods',
            },
            {
              slug: '/dashboard/environment-loggers',
              icon: 'Temperature',
              name: 'Environment Loggers [mock]',
            },
            {
              slug: '/dashboard/aviary-mesh',
              icon: 'TopologyFullHierarchy',
              name: 'Aviary Mesh [mock]',
            },
            {
              slug: '/dashboard/elephant-monitoring',
              icon: 'DeviceHeartMonitor',
              name: 'Elephant Monitoring [mock]',
            },
          ]}
          setDrawerOpened={setDrawerOpened}
        />
        {/* <LeftSideBarContentLinksList setDrawerOpened={setDrawerOpened} /> */}
      </Box>
      {!expanded && <Divider my="xs" />}

      {expanded && (
        <Text weight={700} size={12} transform="uppercase" className={classes.header} mt={15}>
          Apps
        </Text>
      )}
      <Box mt={5}>
        <LeftSideBarContentDemoLinks
          links={[
            {
              slug: '/calendar',
              icon: 'Calendar',
              name: 'Calendar',
            },
            {
              slug: '/alerts',
              icon: 'Alarm',
              name: 'Alerts',
            },
            {
              slug: '/user-permissions',
              icon: 'Users',
              name: 'Permissions',
            },
          ]}
          setDrawerOpened={setDrawerOpened}
        />
      </Box>
    </>
  )
}
