// mantine
import { createStyles } from '@mantine/core'

/** Parameters for PositionalGridStyles */
type PositionalGridStylesParams = {
  /** number of columns to set up */
  columns: number
}

/**
 * create css class name for PositionalGrid
 *
 * @param props - Options for styling
 *
 * @returns styles - a style object
 */
export default createStyles((theme, { columns }: PositionalGridStylesParams) => {
  return {
    grid: {
      display: 'grid',
      gridTemplateColumns: `repeat(${columns}, 1fr)`,
      gridTemplateRows: theme.other.gridTemplateRowHeight,
      columnGap: theme.spacing.sm,
      rowGap: theme.spacing.sm,
    },
  }
})
