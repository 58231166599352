// packages
import { cloneElement, FC, ReactElement, useState } from 'react'
import { useRouter } from 'next/router'
import * as IconSet from 'tabler-icons-react'
// mantine
import { Drawer, Group, Menu, ScrollArea, Stack, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
// hooks
import { useIsMobile } from 'hooks'
// Containers
import { ElephantFeedPodsActivationModal, ModalContainer } from 'Containers'
import { Icon } from 'Components'

type Link = {
  label: string
  iconName?: string
  type?: LinkType
  redirectType?: RedirectType
  modalId?: string
  href?: string
  additionalProps?: object
}

type LinkType = 'title' | 'item'
type RedirectType = 'href' | 'modal' | 'elephantFeedPodsActivation'

type ResponsiveDrawerMenuProps = {
  drawerTitle: string
  TargetComponent: ReactElement<any>
  links?: Link[]
}

export const ResponsiveDrawerMenu: FC<ResponsiveDrawerMenuProps> = (props) => {
  const isMobile = useIsMobile()
  const [modalId, setModalId] = useState<string | undefined>(undefined)
  const [additionalProps, setAdditionalProps] = useState<object | undefined>(undefined)
  const [opened, setOpened] = useState(false)

  const onModalClick = (modalId: string, additionalProps?: object) => {
    setModalId(modalId)
    setAdditionalProps(additionalProps)
    setOpened(true)
  }

  const Modal = ({
    modalId,
    opened,
    onClose,
    additionalProps,
  }: {
    modalId: string
    opened: boolean
    onClose: any
    additionalProps?: object
  }) => {
    if (modalId == 'elephantFeedPodsActivation') {
      return (
        <ElephantFeedPodsActivationModal
          opened={opened}
          onClose={onClose}
          {...(additionalProps as { id: string })}
        />
      )
    } else {
      return <ModalContainer _id={modalId} opened={opened} onClose={onClose} />
    }
  }

  return (
    <>
      {modalId && (
        <Modal
          modalId={modalId}
          opened={opened}
          onClose={() => setOpened(false)}
          additionalProps={additionalProps}
        />
      )}
      {isMobile ? (
        <DrawerInterface {...props} onModalClick={onModalClick} />
      ) : (
        <MenuInterface {...props} onModalClick={onModalClick} />
      )}
    </>
  )
}

export const DrawerInterface: FC<MenuInterfaceProps> = ({
  drawerTitle,
  TargetComponent,
  links = [],
  onModalClick,
}) => {
  const router = useRouter()
  const [opened, { close, toggle }] = useDisclosure(false)
  const Component = () => cloneElement(TargetComponent, { onClick: () => toggle() })

  return (
    <>
      <Drawer
        opened={opened}
        onClose={close}
        title={drawerTitle}
        position="bottom"
        scrollAreaComponent={ScrollArea.Autosize}
      >
        <Stack>
          {links.map(({ type, redirectType, modalId, href, label, iconName, additionalProps }, i) =>
            type == 'title' ? (
              <Text color="dimmed" weight={500} size="sm">
                {label}
              </Text>
            ) : (
              <Group
                key={i}
                spacing="xs"
                onClick={() => {
                  if (redirectType == 'modal') {
                    onModalClick(modalId)
                    close()
                  } else if (redirectType == 'href') {
                    router.push(href || '')
                  } else if (redirectType == 'elephantFeedPodsActivation') {
                    onModalClick('elephantFeedPodsActivation', additionalProps)
                  }
                }}
                style={{ cursor: 'pointer' }}
              >
                <Icon name={iconName as keyof typeof IconSet} />
                {label}
              </Group>
            ),
          )}
        </Stack>
      </Drawer>
      <Component />
    </>
  )
}

type MenuInterfaceProps = {
  onModalClick: any
} & ResponsiveDrawerMenuProps

export const MenuInterface: FC<MenuInterfaceProps> = ({
  TargetComponent,
  links = [],
  onModalClick,
}) => {
  const router = useRouter()

  return (
    <>
      <Menu shadow="md" width={200} withinPortal>
        <Menu.Target>{TargetComponent}</Menu.Target>
        <Menu.Dropdown>
          {links.map(({ type, redirectType, label, iconName, modalId, href, additionalProps }, i) =>
            type == 'title' ? (
              <Menu.Label>{label}</Menu.Label>
            ) : (
              <Menu.Item
                key={i}
                icon={<Icon name={iconName as keyof typeof IconSet} size={14} />}
                onClick={() => {
                  if (redirectType === 'modal') {
                    onModalClick(modalId)
                  } else if (redirectType === 'href') {
                    router.push(href || '')
                  } else if (redirectType === 'elephantFeedPodsActivation') {
                    onModalClick('elephantFeedPodsActivation', additionalProps)
                  }
                }}
              >
                {label}
              </Menu.Item>
            ),
          )}
        </Menu.Dropdown>
      </Menu>
    </>
  )
}
