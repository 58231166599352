// packages
import { useQuery } from '@apollo/client'
import { FC, useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
// mantine
import { useInterval } from '@mantine/hooks'
// Components
import { ActivationType } from 'Components'
// gql
import { GET_RECENT_ACTIVATIONS_QUERY } from 'gql'
// methods
import { cleanActivations } from './cleanActivations'

export type ActivationsDataLayerProps = {
  Component: FC<any>
  ComponentProps?: object
  sourceId: string
  intervalPeriod?: number
  height?: number
  params?: object
  props?: object
}

export const ActivationsDataLayer: FC<ActivationsDataLayerProps> = ({
  Component,
  ComponentProps,
  sourceId,
  intervalPeriod = 1000,
  height,
  params,
  props: _props,
}) => {
  const [activations, setActivations] = useState<ActivationType[] | null>(null)
  //@ts-expect-error: object needs to be fixed here
  const { limit, ...rest } = params

  const { loading, error, refetch } = useQuery(GET_RECENT_ACTIVATIONS_QUERY, {
    variables: { sourceId, limit },
    onCompleted: (result) => {
      const activations = cleanActivations(result.getRecentActivations)

      setActivations(activations)
      if (isEmpty(rest)) interval.start()
    },
  })

  const interval = useInterval(() => {
    refetch()
  }, intervalPeriod)

  useEffect(() => {
    return interval.stop
  }, [])

  return <Component activations={activations} height={height} params={params} {...ComponentProps} />
}
