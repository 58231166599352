// packages
import { FC } from 'react'
// mantine
import { Skeleton } from '@mantine/core'
// Components
import { ActivationsUpcomingBody } from './Upcoming'
import { ActivationsRecentBody } from './Recent'

type ActivationsBodyProps = {
  _id: string
  status: string
  username: string
  requestBody: any
  relative: boolean
  createdAt: string
  scheduledAt: string
}

export const ActivationsBody: FC<ActivationsBodyProps> = ({
  _id,
  status,
  username,
  requestBody,
  relative,
  createdAt,
  scheduledAt,
}) => {
  return status === 'InProgress' ? (
    <div style={{ height: 40, paddingTop: 5 }}>
      <Skeleton height={5} radius="xl" style={{ maxWidth: 320 }} />
      <Skeleton height={5} radius="xl" mt={12} style={{ maxWidth: 80 }} />
    </div>
  ) : relative ? (
    <>
      <ActivationsRecentBody
        username={username}
        requestBody={requestBody}
        scheduledAt={scheduledAt}
      />
    </>
  ) : (
    <ActivationsUpcomingBody
      _id={_id}
      scheduledAt={scheduledAt}
      requestBody={requestBody}
      username={username}
    />
  )
}
