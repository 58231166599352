// packages
import { FC } from 'react'
// Containers
import { LoginForm } from 'Containers'

type LoginPageContainerProps = { redirect?: string }

export const LoginPageContainer: FC<LoginPageContainerProps> = ({ redirect }) => {
  return (
    <div>
      <LoginForm redirect={redirect} />
    </div>
  )
}
