// packages
import { FC, ReactNode } from 'react'
// mantine
import { Box } from '@mantine/core'

/**
 * Props for PositionalGridItem
 */
export type PositionalGridItemProps = {
  /**
   * This should take on the expression of css grid-column.
   *
   * @see [grid-column css](https://www.w3schools.com/css/css_grid_item.asp)
   */
  column: string

  /**
   * This should take on the expression of css grid-row.
   *
   * @see [grid-row css](https://www.w3schools.com/css/css_grid_item.asp)
   */
  row: string

  /**
   * Content to display within PositionalGridItem
   */
  content: ReactNode
}

/**
 * Component for an item within {@link PositionalGrid}
 *
 * @param props - options for grid item
 *
 * @returns Component - a react component
 */
export const PositionalGridItem: FC<PositionalGridItemProps> = ({ column, row, content }) => {
  return <Box style={{ gridColumn: column, gridRow: row }}>{content}</Box>
}
