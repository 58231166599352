// packages
import { FC } from 'react'
import { useQuery } from '@apollo/client'
// mantine
import { Center, Loader } from '@mantine/core'
// gql
import { GET_DASHBOARD_BY_SLUG_QUERY } from 'gql'
// Components
import { DebugPrinter, PositionalGrid } from 'Components'
// Containers
import { DashboardGrid } from './Grid'
// hooks
import { useBreakpoint } from 'hooks'

type DashboardContainerProps = {
  slug: string
  params?: object
}

export const DashboardContainer: FC<DashboardContainerProps> = ({ slug, params }) => {
  const { data, error } = useQuery(GET_DASHBOARD_BY_SLUG_QUERY, { variables: { slug } })
  const breakpoint = useBreakpoint()

  if (data) {
    const { getDashboardBySlug } = data
    if (getDashboardBySlug) {
      const { grids: response } = getDashboardBySlug

      const grids = response.map((grid: any) => {
        const row = grid.row[breakpoint]
        const column = grid.column[breakpoint]
        const { _id: componentId } = grid.component

        return {
          _id: grid._id,
          row,
          column,
          content: DashboardGrid({
            _id: componentId,
            row,
            params,
          }),
        }
      })

      return (
        <>
          <PositionalGrid grids={grids} />
          <DebugPrinter language="json" value={getDashboardBySlug} />
        </>
      )
    }
  }

  if (error) return <div>Error</div>

  return (
    <Center style={{ position: 'absolute', bottom: '50%', right: '50%' }}>
      <Loader />
    </Center>
  )
}
