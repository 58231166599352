import { gql } from '@apollo/client'

/**
 * gql query body to get a dashboard
 *
 * @category GQL
 */
export const GET_DASHBOARD_BY_SLUG_QUERY = gql`
  query GetDashboardBySlug($slug: String!) {
    getDashboardBySlug(slug: $slug) {
      _id
      name
      slug
      icon
      grids {
        _id
        column {
          xs
          sm
          md
          lg
          xl
        }
        row {
          xs
          sm
          md
          lg
          xl
        }
        component {
          _id
          variant
        }
      }
    }
  }
`
