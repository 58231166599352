// packages
import { FC, ReactNode } from 'react'
// mantine
import { Flex, RingProgress, RingProgressProps, Text } from '@mantine/core'

export type RingTargetProps = {
  percent: number
  label: string
  color: string
  rightSection: ReactNode
  ringProps?: RingProgressProps
}

export const RingTarget: FC<RingTargetProps> = ({
  percent,
  label,
  color,
  rightSection,
  ringProps,
}) => {
  return (
    <Flex>
      <RingProgress
        sections={[{ value: percent, color }]}
        label={
          <Text align="center" px="xs" size="xl" weight={500} sx={{ pointerEvents: 'none' }}>
            {percent}%
            <Text size={11} color="dimmed" transform="uppercase">
              {label}
            </Text>
          </Text>
        }
        size={140}
        thickness={11}
        {...ringProps}
      />
      <Flex direction="column" justify="space-between" py="md">
        {rightSection}
      </Flex>
    </Flex>
  )
}
