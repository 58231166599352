import { useQuery } from '@apollo/client'
import { Anchor, Skeleton } from '@mantine/core'
import { GET_ELEPHANT_FEED_PODS_STATUS_BY_DEVICE_ID_QUERY } from 'gql'
import Link from 'next/link'
import { FC } from 'react'

export type ElephantFeedPodsBreadcrumbDeviceNameProps = {
  id: string
  type: 'Schedule' | 'Recent Feeds'
}

export const ElephantFeedPodsBreadcrumbDeviceName: FC<ElephantFeedPodsBreadcrumbDeviceNameProps> =
  ({ id, type }) => {
    const { data } = useQuery(GET_ELEPHANT_FEED_PODS_STATUS_BY_DEVICE_ID_QUERY, {
      variables: { id },
    })

    if (data)
      if (data.getElephantFeedPodsStatusByDeviceId) {
        const { name } = data.getElephantFeedPodsStatusByDeviceId
        const path =
          type === 'Schedule'
            ? `/dashboard/elephant-feedpods/${id}/schedule`
            : `/dashboard/elephant-feedpods/${id}/recent_feeds`
        return (
          <Anchor
            size="xs"
            color="dimmed"
            weight={600}
            transform="uppercase"
            underline={false}
            component={Link}
            href={`/dashboard/elephant-feedpods/${id}/schedule`}
          >
            {name}
          </Anchor>
        )
      }

    return <Skeleton h="xs" w={120} />
  }
