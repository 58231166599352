// packages
import { FC } from 'react'
import { useQuery } from '@apollo/client'
// mantine
import { Loader, Text } from '@mantine/core'
// Components
import { Activations } from 'Components'
import { cleanActivations } from 'Containers'
// gql
import { GET_UPCOMING_ACTIVATIONS_QUERY } from 'gql'

export type CSBUpcomingActivationsByDateProps = {
  start: Date
  end: Date
  sourceId: string
}

export const CSBUpcomingActivationsByDate: FC<CSBUpcomingActivationsByDateProps> = ({
  start,
  end,
  sourceId,
}) => {
  const { data, loading, error } = useQuery(GET_UPCOMING_ACTIVATIONS_QUERY, {
    variables: { start, end, sourceId },
  })

  if (data) {
    if (data.getUpcomingActivations) {
      const activations = cleanActivations(data.getUpcomingActivations)

      return activations.length === 0 ? (
        <Text>No upcoming schedules.</Text>
      ) : (
        <Activations activations={activations} relative={false} />
      )
    }
  }

  return <Loader />
}
