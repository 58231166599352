// packages
import { gql } from '@apollo/client'

export const GET_MENU_ITEM_MODAL_QUERY = gql`
  query GetMenuItemModal($_id: ObjectId!) {
    getMenuItemModal(_id: $_id) {
      _id
      label
      modal {
        _id
      }
    }
  }
`
