// packages
import { FC, useEffect } from 'react'
import { DocumentNode, useQuery } from '@apollo/client'
// mantine
import { Card, Skeleton, Text } from '@mantine/core'
import { useInterval } from '@mantine/hooks'

export type CSBLast24HoursMetric = {
  query: DocumentNode
  field: string
  label: string
}

export const CSBLast24HoursMetric: FC<CSBLast24HoursMetric> = ({ query, field, label }) => {
  return (
    <Card p={0}>
      <Text weight={400} size={28} style={{ fontFamily: `'Rubik', sans-serif` }}>
        <Data query={query} field={field} />
      </Text>
      <Text weight={500} size={11} tt="uppercase" color="dimmed">
        {label}
      </Text>
    </Card>
  )
}

const Data: FC<Omit<CSBLast24HoursMetric, 'label'>> = ({ query, field }) => {
  const { data, loading, refetch } = useQuery(query)
  const interval = useInterval(() => refetch(), 1000 * 60)

  useEffect(() => {
    interval.start()
    return interval.stop
  }, [interval])

  if (data)
    if (data[field] != undefined) {
      return data[field]
    }

  if (loading) {
    return <Skeleton h={28.39} mt={5} mb={10} w={28} />
  }

  return '-'
}
