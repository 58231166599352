// packages
import { FC } from 'react'
import { useQuery } from '@apollo/client'
// mantine
import { ModalProps } from '@mantine/core'
// gql
import { GET_COMPONENT_QUERY } from 'gql'
// Container
import { ModalCSBAudioUploadContainer } from './CSBAudioUpload'
import { ModalFormContainer } from './Form'
// Component
import { DebugPrinter } from 'Components'
// hooks
import { useIsMobile } from 'hooks'

type ModalContainerProps = {
  _id: string
} & ModalProps

export const ModalContainer: FC<ModalContainerProps> = ({ _id, opened, onClose }) => {
  const { data, loading, error } = useQuery(GET_COMPONENT_QUERY, { variables: { _id } })

  const mobile = useIsMobile()

  if (loading) return <></> // For modal in loadng state, we assume that it's not open. So we return null.

  if (data) {
    const { getComponent } = data

    if (getComponent) {
      const { _id, variant } = getComponent

      type VariantType = 'CSBAudioUploadModal' | 'FormModal'

      const mapping: Record<VariantType, React.FC<any>> = {
        CSBAudioUploadModal: ModalCSBAudioUploadContainer,
        FormModal: ModalFormContainer,
      }

      const Component = mapping[variant as VariantType]
      if (!Component)
        throw new Error(`ComponentContainer is unable to render Component with variant: ${variant}`)

      return <Component _id={_id} opened={opened} onClose={onClose} centered fullScreen={mobile} />
    }
  }

  if (error)
    return (
      <div>
        Error
        <DebugPrinter language="json" value={error} />
      </div>
    ) // TODO: fix up error message
  return <div>Error</div>
}
