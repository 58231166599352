import React, { FC } from 'react'
import * as allCurves from '@visx/curve'
import { extent, max } from 'd3-array'
import { Group } from '@visx/group'
import { scaleTime, scaleLinear } from '@visx/scale'
import { MarkerArrow, MarkerCross, MarkerX, MarkerCircle, MarkerLine } from '@visx/marker'
import generateDateValue, { DateValue } from '@visx/mock-data/lib/generators/genDateValue'
import { Grid } from '@visx/grid'
import { Axis } from '@visx/axis'
import { useMantineTheme } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'

type CurveType = keyof typeof allCurves

const curveTypes = Object.keys(allCurves)
const lineCount = 1
const series = new Array(lineCount).fill(null).map((_, i) =>
  // vary each series value deterministically
  generateDateValue(7, /* seed= */ i / 72).sort(
    (a: DateValue, b: DateValue) => a.date.getTime() - b.date.getTime(),
  ),
)
const allData = series.reduce((rec, d) => rec.concat(d), [])

// data accessors
const getX = (d: DateValue) => d.date
const getY = (d: DateValue) => d.value

// scales
const xScale = scaleTime<number>({
  domain: extent(allData, getX) as [Date, Date],
})
const yScale = scaleLinear<number>({
  domain: [0, max(allData, getY) as number],
})

export type CurveProps = {
  height: number
  showControls?: boolean
}

export const AnticipatoryPacingChart: FC<CurveProps> = ({ height, showControls = true }) => {
  const theme = useMantineTheme()
  const { ref, width } = useElementSize()

  // update scale output ranges
  xScale.range([30, width])
  yScale.range([height - 50, 40])

  return (
    <div ref={ref}>
      <svg width={'100%'} height={height}>
        <rect width={width} height={height} fill="transparent" rx={14} ry={14} />
        {series.map((lineData, i) => {
          return (
            <Group key={`lines-${i}`} top={0} left={13}>
              {lineData.map((d, j) => (
                <circle
                  key={i + j}
                  r={3}
                  cx={xScale(getX(d))}
                  cy={yScale(getY(d))}
                  // stroke="rgba(33,33,33,0.5)"
                  stroke="red"
                  fill="red"
                />
              ))}
            </Group>
          )
        })}
        <Grid
          xScale={xScale}
          yScale={yScale}
          width={width}
          height={height}
          numTicksColumns={5}
          numTicksRows={5}
          top={0}
          left={-20}
        />
        <Axis
          scale={yScale}
          orientation="left"
          left={10}
          numTicks={5}
          hideZero
          tickLineProps={{ opacity: 0 }}
          tickLabelProps={() => ({
            dx: 10,
            stroke: theme.colorScheme === 'light' ? theme.colors.dark[3] : theme.colors.gray[6],
            fontSize: 11,
            textAnchor: 'start',
            fontWeight: 100,
          })}
          tickFormat={function tickFormat(d) {
            switch (d) {
              case 2000: {
                return 'Severe'
              }
              case 1500: {
                return 'Moderate'
              }
              case 1000: {
                return 'Mild'
              }
              case 500: {
                return 'Present'
              }
              default: {
                return 'Not Present'
              }
            }
          }}
        />
        <Axis
          scale={xScale}
          orientation="bottom"
          label="Time"
          top={height - 50}
          left={-20}
          labelOffset={10}
        />
      </svg>
    </div>
  )
}
