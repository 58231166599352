// packages
import { ResponsiveTimeRange } from '@nivo/calendar'
import dayjs from 'dayjs'

export const TimeRangeDisplay = ({
  parsedData,
  height,
  setFiles,
}: {
  parsedData: any[]
  height?: number
  setFiles: any
}) => {
  const from = dayjs('2023-03-01').format('YYYY-MM-DD')
  const to = dayjs('2023-04-07').format('YYYY-MM-DD')
  // const form = parsedData.length != 0 ? parsedData[0].day : dayjs().add(-3, 'M').format('YYYY-MM-DD')

  return (
    <div style={{ height, cursor: 'pointer' }}>
      <ResponsiveTimeRange
        data={parsedData}
        from={from}
        to={to}
        emptyColor="#eeeeee"
        colors={['#97e3d5', '#61cdbb', '#e8c1a0', '#f47560']}
        dayBorderWidth={2}
        dayBorderColor="#ffffff"
        margin={{ top: 30 }}
        onClick={(data: any) => setFiles(data.data)}
      />
    </div>
  )
}
