// packages
import { FC, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useQuery } from '@apollo/client'
import dayjs from 'dayjs'
// mantine
import { Box, Card, Center, Flex, Loader, Paper, Stack, Text, ThemeIcon } from '@mantine/core'
// gql
import { GET_CSB_FEEDS_QUERY, GET_CSB_TIGER_RECOGNITION_VIDEOS } from 'gql'
// Components
import { DateRangePicker, TimeSlider } from 'Components'
import { CSBFeedsAnalysis } from './FeedsAnalysis'
import { CSBTigerRecognitionFootages } from './TigerRecognitionFootages'
// icons
import { Meat, Video } from 'tabler-icons-react'

export type CSBAIDashboardContainerProps = {
  start?: Date
  end?: Date
}

export const CSBAIDashboardContainer: FC<CSBAIDashboardContainerProps> = ({
  start = new Date('2023-03-01T00:00:00.000Z'),
  end = new Date('2023-03-31T00:00:00.000Z'),
}) => {
  const router = useRouter()

  const min = new Date('2023-03-01T00:00:00.000Z')
  const max = new Date('2023-03-31T00:00:00.000Z')

  const [numOfDays, setNumOfDays] = useState(0)

  useEffect(() => {
    setNumOfDays(dayjs(end).diff(dayjs(start), 'd') + 1)
  }, [setNumOfDays, start, end])

  const routerChange = async (start: Date, end: Date) => {
    const endOfDay = dayjs(end).endOf('d').add(8, 'h').toISOString()

    await router.push(
      {
        pathname: '/dashboard/[...slug]',
        query: {
          slug: 'carnivore-smart-box/ai',
          start: start.toISOString(),
          end: endOfDay,
        },
      },
      `/dashboard/carnivore-smart-box/ai?start=${start.toISOString()}&end=${endOfDay}`,
    )
  }

  const {
    data: feedsData,
    loading: feedsLoading,
    error: feedsError,
  } = useQuery(GET_CSB_FEEDS_QUERY, {
    variables: { start, end: dayjs(end).endOf('d').add(8, 'h').toDate() },
  })
  const {
    data: videoData,
    loading: videoLoading,
    error: videoError,
  } = useQuery(GET_CSB_TIGER_RECOGNITION_VIDEOS, {
    variables: { start, end: dayjs(end).endOf('d').add(8, 'h').toDate() },
  })

  return (
    <Stack>
      <Paper py={3} style={{ position: 'sticky', top: -1, zIndex: 99 }}>
        <Flex justify="space-between">
          <Text size="xl" weight={500}>
            CSB AI Analysis
          </Text>
          <DateRangePicker
            min={min}
            max={max}
            start={start}
            end={end}
            routerChange={routerChange}
          />
        </Flex>
      </Paper>

      {feedsData && feedsData.getCSBFeeds ? (
        <CSBFeedsAnalysis feeds={feedsData.getCSBFeeds} numOfDays={numOfDays} />
      ) : (
        <Center>
          <Loader />
        </Center>
      )}

      {feedsData && feedsData.getCSBFeeds && videoData && videoData.getCSBTigerRecognitionVideos ? (
        <FeedsTimeSlider
          start={start}
          end={dayjs(end).endOf('d').add(8, 'h').toDate()}
          feeds={feedsData.getCSBFeeds}
          videos={videoData.getCSBTigerRecognitionVideos}
        />
      ) : (
        <Center>
          <Loader />
        </Center>
      )}

      {videoData && videoData.getCSBTigerRecognitionVideos && (
        <CSBTigerRecognitionFootages videos={videoData.getCSBTigerRecognitionVideos} />
      )}
    </Stack>
  )
}

const FeedsTimeSlider = ({
  start,
  end,
  feeds,
  videos,
}: {
  start: any
  end: any
  feeds: any[]
  videos: any[]
}) => {
  const marks = feeds.map((event) => {
    const v = dayjs(event.created).diff(dayjs(start), 's')
    return {
      value: v,
      label: (
        <ThemeIcon size="sm" variant="light" radius="xl" color="orange">
          <Meat size={16} strokeWidth={1.5} />
        </ThemeIcon>
      ),
    }
  })

  for (const video of videos) {
    const v = dayjs(video.recordedAt).diff(dayjs(start), 's')
    marks.push({
      value: v,
      label: (
        <ThemeIcon size="sm" variant="light" radius="xl" color="pink">
          <Video size={16} strokeWidth={1.5} />
        </ThemeIcon>
      ),
    })
  }

  return (
    <Card withBorder radius="md">
      <Text weight={500} size="lg" mb="md">
        Timeline
      </Text>
      <Box h={60}>
        <TimeSlider start={start} end={end} marks={marks} />
      </Box>
    </Card>
  )
}
