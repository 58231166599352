// packages
// mantine
import { useForm } from '@mantine/form'
// interface
// components

export const useForgetPasswordForm = () => {
  const form = useForm({
    initialValues: {
      email: '',
    },

    validate: {
      email: (value) => (/^\S+@mandai.com/.test(value) ? null : 'Invalid email'),
    },
  })

  return form
}
