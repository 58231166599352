// packages
import { useState } from 'react'
// mantine
import { Center, Divider, Flex, Loader, Modal, Text } from '@mantine/core'
// Components
import { CSBDateStack } from './Dates'
import { DateAndTimePicker } from '../DateAndTimePicker'
// styles
import useStyles from './styles'
// icons
import { Calendar } from 'tabler-icons-react'

export const SuggestedDatePicker = ({
  activate,
  opened,
  setOpened,
  loading,
}: {
  activate: any
  opened: boolean
  setOpened: any
  loading: boolean
}) => {
  const { classes } = useStyles()
  const [formOpened, setFormOpened] = useState(false)

  return (
    <>
      <Modal
        trapFocus={false}
        opened={formOpened}
        onClose={() => {
          setOpened(true)
          setFormOpened(false)
        }}
        size="auto"
        title={
          <Text size="xl" weight={500}>
            Pick date & time
          </Text>
        }
        centered
      >
        <DateAndTimePicker activate={activate} setFormOpened={setFormOpened} loading={loading} />
      </Modal>

      <Modal.Root
        trapFocus={false}
        opened={opened}
        onClose={() => setOpened(false)}
        title="Schedule feeder"
        centered
        size="sm"
      >
        <Modal.Overlay />
        <Modal.Content>
          {loading ? (
            <LoadingSection />
          ) : (
            <>
              <Modal.Header>
                <Modal.Title>
                  <Text size="xl" weight={500}>
                    Schedule feed
                  </Text>
                  <Text size="sm" color="dimmed">
                    Auto suggested time based on previous selections.
                  </Text>
                </Modal.Title>

                <Modal.CloseButton size="lg" />
              </Modal.Header>

              <Modal.Body p={0}>
                <CSBDateStack activate={activate} setOpened={setOpened} />
                <Divider />

                <Flex
                  px="lg"
                  pt="md"
                  pb="lg"
                  gap="sm"
                  onClick={() => {
                    setOpened(false)
                    setFormOpened(true)
                  }}
                  className={classes.dateTimeOption}
                >
                  <Calendar />
                  Pick date & time
                </Flex>
              </Modal.Body>
            </>
          )}
        </Modal.Content>
      </Modal.Root>
    </>
  )
}

const LoadingSection = () => {
  return (
    <Center h={293}>
      <Loader />
    </Center>
  )
}
