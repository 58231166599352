// packages
import { FC } from 'react'
import { useMutation } from '@apollo/client'
// mantine
import { Anchor, Loader } from '@mantine/core'
// gql
import { GET_TARGET_USERS_OF_PULSE_QUERY, REMOVE_USER_FROM_PULSE_TARGETS_MUTATION } from 'gql'

export type RemoveUserFromPulseTargetsAnchorProps = {
  _id: string
  userId: string
}

export const RemoveUserFromPulseTargetsAnchor: FC<RemoveUserFromPulseTargetsAnchorProps> = ({
  _id,
  userId,
}) => {
  const [remove, { loading }] = useMutation(REMOVE_USER_FROM_PULSE_TARGETS_MUTATION)

  const onClick = async () => {
    await remove({ variables: { _id, userId }, refetchQueries: [GET_TARGET_USERS_OF_PULSE_QUERY] })
  }

  if (loading) return <Loader size="xs" />

  return (
    <Anchor size="sm" onClick={onClick}>
      Remove
    </Anchor>
  )
}
