// packages
import { gql } from '@apollo/client'

// TODO: should we remove all the fields in form?
// except for _id and title
export const GET_FORM_MODAL_QUERY = gql`
  query GetFormModal($_id: ObjectId!) {
    getFormModal(_id: $_id) {
      _id
      variant
      props
      form {
        _id
        title
        source {
          _id
        }
        inputs {
          _id
          fieldName
          props
          type
        }
        initialValues
        buttonLabel
        successNotification {
          _id
          color
          icon
          loading
          title
          message
          props
        }
        errorNotification {
          _id
          color
          icon
          loading
          title
          message
          props
        }
      }
    }
  }
`
