// packages
import { FC } from 'react'
import dayjs from 'dayjs'
// types
import { CalendarProps } from './types'
// Components
import { CalendarMonthView } from './Month'
import { CalendarDayView } from './Day'

export const Calendar: FC<CalendarProps> = ({ date, setDate, events, view, setView }) => {
  switch (view) {
    case 'Month': {
      return (
        <CalendarMonthView
          date={date}
          setDate={setDate}
          events={events}
          setDayView={() => setView('Day')}
        />
      )
    }
    case 'Day': {
      return (
        <CalendarDayView
          date={date}
          setDate={setDate}
          events={events.filter((event) => dayjs(event.start).isSame(date, 'day'))}
          setMonthView={() => setView('Month')}
        />
      )
    }
    default:
      throw new Error(`Calendar view not defined for ${view}`)
  }
}
