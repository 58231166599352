// packages
import { FC } from 'react'
// matine
import { Text, TextProps, useMantineColorScheme } from '@mantine/core'

export type TextComponentProps = {
  content: string
  color: { light: string; dark: string }
  props: TextProps
}

export const TextComponent: FC<TextComponentProps> = ({ content, color, props }) => {
  const { colorScheme } = useMantineColorScheme()
  const { light, dark } = color
  return (
    <Text {...props} color={colorScheme === 'light' ? light : dark}>
      {content}
    </Text>
  )
}
