import { useMantineTheme } from '@mantine/core'
import { getRandomNormal } from '@visx/mock-data'
import { XYChart, Tooltip, Axis, Grid, AreaSeries } from '@visx/xychart'
import dayjs from 'dayjs'
import { FC } from 'react'

type HeartRateLineChartProps = { height: number }

export const HeartRateLineChart: FC<HeartRateLineChartProps> = ({ height }) => {
  const theme = useMantineTheme()
  const startDateMs = Date.now()

  const accessors = {
    xAccessor: (d: any) => d.x,
    yAccessor: (d: any) => d.y,
  }

  const rand = getRandomNormal(75, 2)
  const values = new Array(100).fill(1).map((_) => rand())
  const data = new Array(values.length)
    .fill(1)
    .map((_, idx: number) => ({
      x: dayjs(new Date(startDateMs - idx * 3600000 * 24)).format('YYYY-MM-DD'),
      y: Math.round(values[idx]),
    }))
    .reverse()

  const max = Math.max(...data.map((d: any) => d.y))
  const min = Math.min(...data.map((d: any) => d.y))

  return (
    <XYChart
      height={height}
      xScale={{ type: 'band' }}
      yScale={{
        type: 'linear',
        zero: false,
        domain: [max - 2 * (max - min), max],
        range: [height, 30],
      }}
      margin={{ top: 10, left: -3, bottom: 0, right: -3 }}
    >
      <Axis
        orientation={'left'}
        numTicks={5}
        stroke={theme.colorScheme == 'light' ? theme.colors.gray[2] : theme.colors.dark[4]}
        hideAxisLine
        left={0}
        tickLineProps={{ dx: 0, strokeWidth: 0.75 }}
        tickFormat={(formattedValue) => {
          return `${formattedValue}bpm`
        }}
        tickLabelProps={() => ({
          dx: 5,
          stroke: theme.colorScheme === 'light' ? theme.colors.dark[3] : theme.colors.gray[6],
          textAnchor: 'start',
        })}
        hideZero
      />
      <Axis
        orientation="bottom"
        numTicks={5}
        stroke={theme.colorScheme == 'light' ? theme.colors.gray[2] : theme.colors.dark[4]}
        hideAxisLine
        top={height - 23}
        tickLineProps={{ strokeWidth: 0 }}
        tickFormat={(formattedValue) => {
          return dayjs(formattedValue).add(-8, 'h').format('D MMM')
        }}
        tickLabelProps={() => ({
          dx: 0,
          dy: -10,
          stroke: theme.colorScheme === 'light' ? theme.colors.dark[3] : theme.colors.gray[6],
          strokeWidth: 0.75,
        })}
      />
      <Grid
        columns
        rows={false}
        numTicks={5}
        lineStyle={{
          strokeWidth: 0.75,
          stroke: theme.colorScheme == 'light' ? theme.colors.gray[2] : theme.colors.dark[4],
        }}
      />
      <Grid
        columns={false}
        rows
        numTicks={5}
        lineStyle={{
          strokeWidth: 0.75,
          stroke: theme.colorScheme == 'light' ? theme.colors.gray[2] : theme.colors.dark[4],
        }}
      />
      <AreaSeries
        dataKey="Heart Rate (bpm)"
        data={data}
        fillOpacity={0.1}
        fill={'red'}
        lineProps={{
          stroke: 'red',
        }}
        {...accessors}
      />
      <Tooltip
        snapTooltipToDatumX
        snapTooltipToDatumY
        showVerticalCrosshair
        showSeriesGlyphs
        renderTooltip={({ tooltipData, colorScale }) =>
          tooltipData &&
          tooltipData.nearestDatum &&
          colorScale && (
            <div>
              <div style={{ color: colorScale(tooltipData.nearestDatum.key) }}>
                {tooltipData.nearestDatum.key}
              </div>
              {accessors.xAccessor(tooltipData.nearestDatum.datum)}
              {', '}
              {accessors.yAccessor(tooltipData.nearestDatum.datum)}
            </div>
          )
        }
      />
    </XYChart>
  )
}
