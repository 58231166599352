// packages
import { FC } from 'react'
import { useMutation } from '@apollo/client'
// mantine
import { ActionIcon, Loader, Text } from '@mantine/core'
import { modals } from '@mantine/modals'
import { notifications } from '@mantine/notifications'
// gql
import {
  CANCEL_ELEPHANT_FEED_PODS_SCHEDULE,
  GET_ELEPHANT_FEED_PODS_DEVICE_UPCOMING_FEED_QUERY,
  GET_ELEPHANT_FEED_PODS_SCHEDULE,
} from 'gql'
// icons
import { Ban, Check } from 'tabler-icons-react'

export type ElephantFeedPodsScheduleCancellationProps = {
  id: string
  time: string
}

export const ElephantFeedPodsScheduleCancellation: FC<ElephantFeedPodsScheduleCancellationProps> =
  ({ id, time }) => {
    const [cancel, { loading, error }] = useMutation(CANCEL_ELEPHANT_FEED_PODS_SCHEDULE)
    const onConfirm = async () => {
      await cancel({
        variables: { id, time },
        refetchQueries: [
          GET_ELEPHANT_FEED_PODS_SCHEDULE,
          GET_ELEPHANT_FEED_PODS_DEVICE_UPCOMING_FEED_QUERY,
        ],
        onCompleted: () => {
          notifications.show({
            title: 'Success',
            message: 'Removed scheduled feed from the CSB.',
            icon: <Check size="1.1rem" />,
            color: 'green',
          })
        },
      })
    }

    const openCancellationModal = () =>
      modals.openConfirmModal({
        title: 'Cancel activation',
        centered: true,
        children: (
          <Text size="sm">
            Are you sure you want to cancel the activation scheduled for {time}?
          </Text>
        ),
        labels: { confirm: 'Yes', cancel: "No don't cancel it" },
        confirmProps: { color: 'red', loading },
        onConfirm,
      })

    if (loading) return <Loader size="sm" />

    return (
      <ActionIcon
        color="red"
        size="sm"
        radius="xl"
        variant="subtle"
        onClick={openCancellationModal}
      >
        <Ban />
      </ActionIcon>
    )
  }
