// packages
import { gql } from '@apollo/client'

export const AUTOSUGGEST_TARGET_USERS_OF_PULSE_QUERY = gql`
  query AutosuggestTargetUsersOfPulse($_id: ObjectId!, $search: String!) {
    autosuggestTargetUsersOfPulse(_id: $_id, search: $search) {
      _id
      name
    }
  }
`
